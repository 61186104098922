import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// New Code
import { BlogHomeComponent } from './blog-home/blog-home.component';
import { BlogItemComponent } from './blog-item/blog-item.component';
import { PostsByTagComponent } from './posts-by-tag/posts-by-tag.component';
const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: 'feed',
                component: BlogHomeComponent,
                data: {
                    title: 'Recent blog entries',
                    breadcrumb: ''
                }
            },
            {
                path: 'article/:slug',
                component: BlogItemComponent,
                data: {
                    title: 'Read blog post',
                    breadcrumb: ''
                }
            },
            {
                path: 'tags/:id',
                component: PostsByTagComponent,
                data: {
                    title: 'Search posts by tag',
                    breadcrumb: ''
                }
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class BlogRoutingModule { }
