import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GenericResponse } from '../_models/index';
import { API_URL } from '../app.const';

@Injectable()
export class AdminCategoryService {
    private removeCategoryImageURL = API_URL + '/admin/products/remove_category_image';
    private updateCategoryURL = API_URL + '/admin/products/edit_category';
    private markCategoryHiddenURL = API_URL + '/admin/products/mark_category_hidden';

    constructor (private http: HttpClient) {}


    removeCategoryImage (categoryID) {
	    return this.http.post<GenericResponse>(this.removeCategoryImageURL,
	        JSON.stringify({ categoryID }));
  	}

  	updateCategory (categoryID, name, position) {
	    return this.http.post<GenericResponse>(this.updateCategoryURL,
	        JSON.stringify({ categoryID, name, position }));
  	}

    markCategoryHidden (categoryID) {
        return this.http.post<GenericResponse>(this.markCategoryHiddenURL,
            JSON.stringify({ categoryID }));
    }




}
