import { Component, OnInit, OnDestroy } from '@angular/core';
import { BlogPost, PostTags } from '../../../_models/index';
import { PageService } from '../../../_services/index';
import { takeUntil } from 'rxjs/operators';
import { Subject} from 'rxjs';

@Component({
    selector: 'app-sidebar-blog',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
    posts: BlogPost[] = [];
    tags: PostTags[] = [];
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    constructor (
        private pageService: PageService) { }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit () {
        this.getRecentPosts();
        this.getAllTags();
    }

    getRecentPosts() {
        this.pageService.getXBlogPosts(10).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(x => this.posts = x);
    }

    getAllTags() {
        this.pageService.getBlogTags().pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(x => this.tags = x);
    }

}
