<!-- about section Start-->
<section class="">
<div class="container">
<ngb-carousel #carousel interval="4000" [pauseOnHover]="pauseOnHover" (slide)="onSlide($event)">
                  <ng-template ngbSlide *ngFor="let img of carosuelImages; index as i">
                    <div class="carousel-caption">
                      <h3 class="carouseltext">{{img.description}}</h3>
                    </div>
                    <a href="{{img.url}}" target="_blank" rel="nofollow noopener noreferrer">
                      <div class="picsum-img-wrapper">
                        <img [src]="img.url" class="carousel-img-cust" alt="{{img.description}}">
                      </div>
                    </a>
                  </ng-template>
                </ngb-carousel>
</div>
</section>

<section class="gym format">
    <div class="container">

        <div class="row">
            <div class="col-md-8 col-sm-12 offset-md-2">
            
            </div>
        </div>
        <div class="row">
            <div class="col-md-8 offset-md-2">
                <div class="center-text">
                    <div class="text-center">
                        <div class="format-head-text">
                            <h3 class="about-font-header gradient-text">About Us</h3>
                        </div>           

                        <div class="format-sub-text mb-0">

                            <p class="p-light about-para">
                                We are a family run independent flooring distributor supplying flooring retailers and contractors in the South. Originally started by Tom McHale in Havant Hampshire in 1969, we are now run by the second and third generations of the original family.
                                <br /> <br />
                                Being (a true) independent flooring wholesaler means we can set our prices very keenly against the big groups and pride ourselves on keeping big stocks of our best sellers. Another advantage of dealing with an independent wholesaler is that we are closer to our customers and know most of them personally. Service is a total focus to us and we’ve been rewarded with customer loyalty that is a major factor in our success.
                                <br /> <br />
                                We operate from a purpose built warehouse with state-of-the-art facilities. We have a high speed cutting machine linked into our computer system which give us accuracy of 99.9%. We are the only wholesaler that re-wraps our vinyls to avoid any surface damage. We also have a fantastic online order portal, as well as fully equipped sales representatives, they carry stock of flooring essentials, as well as sample books.
                                <br /> <br />
                                Alongside carpet & vinyls we also stock a wide range of underlay, LVT, screeds and adhesives, we stock everything your flooring business needs, in a range of qualities and prices.
                                <br /> <br />
                                We have our own delivery fleet and all of drivers are fully employed, we do not use agency staff as our drivers are a key part of our business and our link to the customers and many appreciate the continuity of having a regular delivery driver (who knows exactly how you like your deliveries organised). We’ve got a great team – both out on the road and back at base. 2019 marked 50 years of trading at Solent Wholesale and we have our sights firmly set on the next 50 years.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- about section end-->

<section class="event testimonial set-relative bg about-page">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title title2 title-inner">
                    <div class="main-title">
                        <h2 class="font-primary borders text-center text-uppercase m-b-0">
                            <span>testimonial 2</span>
                        </h2>
                    </div>
                </div>
            </div>
            <div class="col-md-10 offset-md-1 margin-b">
                <owl-carousel-o [options]="aboutTestimonialCarouselOptions" class="testimonial">
                        <ng-template  *ngFor="let review of aboutTestimonial" carouselSlide >
                            <div class="p-t-30">
                            <div class="col-lg-10 offset-lg-1 col-12">
                                <div class="row">
                                    <div class="col-xl-3 col-sm-4">
                                        <div class="testimonial-msg set-relative">
                                          
                                            <div class="msg-box">
                                                <div class="center-content">
                                                    <img src="assets/images/event/testimonial/message.png" alt=""
                                                        class="img-fluid set-abs">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-9 col-sm-8">
                                        <div class="quotes set-relative m-b-30">
                                            <img src="assets/images/event/testimonial/i1.png" alt=""
                                                class="img-fluid set-abs left-quote">
                                            <div class="quote-text">
                                                <h5>"{{review.text}}"</h5>
                                            </div>
                                            <img src="assets/images/event/testimonial/i2.png" alt=""
                                                class="img-fluid set-abs right-quote">
                                        </div>
                                        <div class="rating align-items-center">
                                            <div class="stars">
                                                <ul>
                                                    <li>
                                                        <i class="fa fa-star yellow" aria-hidden="true"></i>
                                                        <i class="fa fa-star yellow" aria-hidden="true"></i>
                                                        <i class="fa fa-star yellow" aria-hidden="true"></i>
                                                        <i class="fa fa-star yellow" aria-hidden="true"></i>
                                                        <i class="fa fa-star yellow" aria-hidden="true"></i>
                                                    </li>
                                                </ul>
                                            </div>
                                            <h5 class="name">Solent Customer<span> {{review.location}}</span></h5>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>

<section class="event speaker set-relative" id="speaker">
    <div class="container">
        
        <div class="row">
            <div class="col-12">
                <img class="img-map" 
                    src="https://swc-website.s3-eu-west-1.amazonaws.com/static_images/back_page.png">
            </div>
        </div>
    </div>
</section>

<section class="resume counter bg-pink">
    <div class="container">
        <div class="row">
            <div class="col-xl-3 col-6 counter-container">
                <div class="counters">
                    <fa-icon  class="counter-img white" size="3x" [icon]="faShoppingCart"></fa-icon>
                    <div class="counter-text">
                        <h3 class="count-text counts white">150+</h3>
                        <h5 class="count-desc white">Stock Ranges</h5>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-6 counter-container">
                <div class="counters">
                    <fa-icon  class="counter-img white" size="3x" [icon]="faTruck"></fa-icon>
                    <div class="counter-text">
                        <h3 class="count-text counts white" >12</h3>
                        <h5 class="count-desc white">Delivery Vehicles</h5>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-6 counter-container">
                <div class="counters">
                    <fa-icon  class="counter-img white" size="3x" [icon]="faBusinessTime"></fa-icon>
                    <div class="counter-text">
                        <h3 class="count-text counts white">53</h3>
                        <h5 class="count-desc white">Years Trading</h5>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-6 counter-container">
                <div class="counters">
                    <fa-icon  class="counter-img white" size="3x" [icon]="faTrophy"></fa-icon>
                    <div class="counter-text">
                        <h3 class="count-text counts white">100%</h3>
                        <h5 class="count-desc white">Customer Satisfaction</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>




