<ng-template #addCategoryModal let-c="close" let-d="dismiss">
        <div class="modal-header">
          <h4 class="modal-title">Add Category</h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div *ngIf="formError">
	            <div class="alert alert-danger" role="alert">
	                    <h1>{{formError}}</h1>
	            </div>
			</div> 
            <form class="form">
                    <div class="form-group" [formGroup]="categoryForm">
                    <label for="name">Name</label>
                    <div class="input-group">
                    <input class="form-control" placeholder="Category Name"
                        id="name"
                        type="text"
                        formControlName="name">
                    </div>

                    <label for="position">Position</label>
                    <div class="input-group">
                    <input class="form-control" placeholder="Position"
                        id="position"
                        type="text"
                        formControlName="position">
                    </div>

                    <app-single-image-upload formControlName="image" [progress]="progress"></app-single-image-upload>


                    <br />

                    <button class="btn btn-success" [disabled]="categoryForm?.invalid" 
                    (click)="saveNewCategory()">Save Category</button>
                        

                    
                    </div>
            </form>

        <div class="modal-footer">
          <button type="button" class="btn btn-outline-dark" (click)="c('Close click')">Close</button>
        </div>
    </div>
</ng-template>

<ng-template #editCategoryModal let-c="close" let-d="dismiss">
        <div class="modal-header">
          <h4 class="modal-title">Edit Category</h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div *ngIf="formError">
                <div class="alert alert-danger" role="alert">
                        <h1>{{formError}}</h1>
                </div>
            </div> 
            <form class="form">
                    <div class="form-group" [formGroup]="categoryForm">
                    <label for="name">Name</label>
                    <div class="input-group">
                    <input class="form-control" placeholder="Category Name"
                        id="name"
                        type="text"
                        formControlName="name">
                    </div>

                    <label for="position">Position</label>
                    <div class="input-group">
                    <input class="form-control" placeholder="Position"
                        id="position"
                        type="text"
                        formControlName="position">
                    </div>

                    <div *ngIf="categoryQ[0].awsURL !== null">
                        <br />
                        <button class="btn btn-danger"
                        (click)="removeImage(categoryQ[0].id)">Remove Current Image</button>
                    </div>

                    <div *ngIf="categoryQ[0].awsURL === null">
                        <!-- if no image exists, give option to add -->
                        <app-single-image-upload formControlName="image" [progress]="progress"></app-single-image-upload>
                        <button class="btn btn-danger"
                            (click)="updateCategoryImage(categoryQ[0].id)">Update Image</button>
                    </div>

                    <br />

                    <button class="btn btn-success" (click)="updateCategory(categoryQ[0].id)">
                        Update Category
                    </button>
                        

                    
                    </div>
            </form>

        <div class="modal-footer">
          <button type="button" class="btn btn-outline-dark" (click)="c('Close click')">Close</button>
        </div>
    </div>
</ng-template>


<section class="event team-sec speaker set-relative" id="speaker">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-sm-12 speker-container">

                <div class="table-responsive">
    				<table class="table table-striped">
    				  <thead>
    				  <tr>
    				    <th scope="col"># (Edit)</th>
    				    <th scope="col">Name </th>
    				    <th scope="col">Position</th>
    				    <th scope="col">Disable</th>
    				  </tr>
    				  </thead>
    				  <tbody>

    				  <tr *ngFor="let category of categories; index as i">
    				    <th scope="row" (click)="editCategory(category.id)">{{ category.id }}</th>

    				    <td>{{ category.name}}</td>
    				    <td>{{ category.position| number }}</td>
    				    <td *ngIf="category.hidden === true;" (click)="markCategoryHidden(category.id)">Enable</td>
                        <td *ngIf="category.hidden === false;" (click)="markCategoryHidden(category.id)">Disable</td>
    				  </tr>
    				  </tbody>
    				</table>

    				<button class="btn btn-danger" (click)="addCategory()">
                		Add Category
                	</button>

                </div>

            </div>

          
        </div>
    </div>
</section>
