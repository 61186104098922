import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';

@Injectable()
export class JwtHttpInterceptor implements HttpInterceptor {
    constructor (@Inject(PLATFORM_ID) private platformId: any) {}
    intercept (request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let token = null;
        if (isPlatformBrowser(this.platformId)) {
            token = localStorage.getItem('rawJWT');
        } else {
            token = '123';
        }
        let clone: HttpRequest<any>;
        if (token) {
            clone = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        } else {
            clone = request.clone({
                setHeaders: {
                    Accept: 'application/json'
                }
            });
        }
        return next.handle(clone);
    }
}
