import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { takeUntil, take } from 'rxjs/operators';
import { filter, map, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Manufacturer } from '../../../_models/index';
import { PageService, AdminManufacturerService } from '../../../_services/index';
import { pipe } from 'rxjs';
import { HttpClient, HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { API_URL } from '../../../app.const';

export function uploadProgress<T> (cb: ( progress: number ) => void ) {
    return tap(( event: HttpEvent<T> ) => {
        if ( event.type === HttpEventType.UploadProgress ) {
            cb(Math.round((100 * event.loaded) / event.total));
        }
    });
}

export function markAllAsDirty (form: UntypedFormGroup ) {
    for ( const control of Object.keys(form.controls) ) {
        form.controls[control].markAsDirty();
    }
}

export function toFormData<T> (formValue: T ) {
    const formData = new FormData();
    for ( const key of Object.keys(formValue) ) {
        const value = formValue[key];
        formData.append(key, value);
    }

    return formData;
}

export function toResponseBody<T> () {
    return pipe(
        filter(( event: HttpEvent<T> ) => event.type === HttpEventType.Response),
        map(( res: HttpResponse<T> ) => res.body)
    );
}


@Component({
    selector: 'app-manufacturers',
    templateUrl: './manufacturers.component.html',
    styleUrls: ['./manufacturers.component.scss']
})
export class ManufacturersComponent implements OnInit, OnDestroy {
    manufacturers: Manufacturer[] = [];
    manufacturerQ = null;
    progress = 0;
    success = false;
    formError = null;
    manForm = new UntypedFormGroup({
        id: new UntypedFormControl(null),
        name: new UntypedFormControl(null, Validators.required),
        image: new UntypedFormControl(null, Validators.required)
    });

    private modalRefService: NgbModalRef;
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    @ViewChild('addManModal', { static: true }) private addManModal;
    @ViewChild('editManModal', { static: true }) private editManModal;

    constructor(
        private pageService: PageService,
        private adminManufacturerService: AdminManufacturerService,
        private modalService: NgbModal,
        private http: HttpClient) { }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.getManufacturers();
    }

    getManufacturers() {
        this.pageService.getManufacturers(true).pipe(
            take(1),
            takeUntil(this.ngUnsubscribe))
            .subscribe(x => this.manufacturers = x);
    }

    addManufacturer() {
        // open modal
        this.manForm.reset();
        this.modalRefService = this.modalService.open(this.addManModal);
    }

    saveNewManufacturer() {
        const uploadURL = API_URL + '/admin/products/add_manufacturer';

        this.success = false;
        if ( !this.manForm.valid ) {
            markAllAsDirty(this.manForm);
            return;
        }

        this.http.post(uploadURL, toFormData(this.manForm.value), {
            reportProgress: true,
            observe: 'events',
        }).pipe(
            takeUntil(this.ngUnsubscribe),
            uploadProgress(progress => (this.progress = progress)),
            toResponseBody()
        ).subscribe(res => {
            this.evalAPIResponse(res);
        });
    }

    evalAPIResponse (response) {
        if (response.status === true) {
            this.progress = 0;
            this.success = true;
            this.manForm.reset();
            this.formError = null;
            this.modalService.dismissAll();
            this.getManufacturers();
        } else {
            this.formError = response.message;
        }

    }

    editManufacturer(id) {
        this.manufacturerQ = this.manufacturers.filter(x => x.id === id);
        this.manForm.controls.id.setValue(this.manufacturerQ[0].id);
        this.manForm.controls.name.setValue(this.manufacturerQ[0].name);
        // open modal
        this.modalRefService = this.modalService.open(this.editManModal);
    }

    removeImage(id) {
        // We need to remove the image, send request to API
        this.adminManufacturerService.removeManufacturerImage(id).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(response => this.evalAPIResponse(response));
    }

    markManShowcase(id) {
        this.adminManufacturerService.markManufacturerShowcase(id).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(response => this.evalAPIResponse(response));
    }

    updateManufacturer (id) {
        const name = this.manForm.controls.name.value;

        this.adminManufacturerService.updateManufacturer(id, name).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(response => this.evalAPIResponse(response));

    }

    updateManImage(id) {
        // Assign a new image to a category
        const uploadURL = API_URL + '/admin/products/assign_man_image';
        this.success = false;
        if ( !this.manForm.valid ) {
            markAllAsDirty(this.manForm);
            return;
        }

        this.http.post(uploadURL, toFormData(this.manForm.value), {
            reportProgress: true,
            observe: 'events',
        }).pipe(
            takeUntil(this.ngUnsubscribe),
            uploadProgress(progress => (this.progress = progress)),
            toResponseBody()
        ).subscribe(res => {
            this.evalAPIResponse(res);
        });

    }

}
