import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { filter, map, tap } from 'rxjs/operators';
import { Subject, pipe } from 'rxjs';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { API_URL } from '../../../../app.const';
import { ViewProduct } from '../../../../_models/index';
import { AdminProductService } from '../../../../_services/index';

export function uploadProgress<T> (cb: ( progress: number ) => void ) {
    return tap(( event: HttpEvent<T> ) => {
        if ( event.type === HttpEventType.UploadProgress ) {
            cb(Math.round((100 * event.loaded) / event.total));
        }
    });
}

export function markAllAsDirty (form: UntypedFormGroup ) {
    for ( const control of Object.keys(form.controls) ) {
        form.controls[control].markAsDirty();
    }
}

export function toFormData<T> (formValue: T ) {
    const formData = new FormData();
    for ( const key of Object.keys(formValue) ) {
        const value = formValue[key];
        formData.append(key, value);
    }

    return formData;
}

export function toResponseBody<T> () {
    return pipe(
        filter(( event: HttpEvent<T> ) => event.type === HttpEventType.Response),
        map(( res: HttpResponse<T> ) => res.body)
    );
}

@Component({
    selector: 'app-main-image',
    templateUrl: './main-image.component.html',
    styleUrls: ['./main-image.component.scss']
})
export class MainImageComponent implements OnInit, OnDestroy {
    productID = null;
    product: ViewProduct = null;
    progress = 0;
    success = false;
    formError = null;
    productTest = false;

    imageForm = new UntypedFormGroup({
        id: new UntypedFormControl(null, Validators.required),
        image: new UntypedFormControl(null, Validators.required)
    });

    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: UntypedFormBuilder,
        private http: HttpClient,
        private adminProductService: AdminProductService) {
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.productID = this.route.snapshot.paramMap.get('id');
        this.getProductDetails(this.productID);
    }

    evalAPIResponse (response) {
        if (response.status === true) {
            this.progress = 0;
            this.success = true;
            this.imageForm.reset();
            this.formError = null;
            this.router.navigate(['/admin/extras/', this.productID]);
        } else {
            this.formError = response.message;
        }

    }

    setProduct(product) {
        this.product = product;
        this.imageForm.get(['id']).setValue(product.id);
        this.productTest = true;
    }
    getProductDetails(productID) {
        // We need to retreive the product from the API
        this.adminProductService.getProductExtras(productID).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(product => this.setProduct(product));

    }

    saveNewImage () {
        const uploadURL = API_URL + '/admin/products/extras/add_main_image';

        this.success = false;
        if ( !this.imageForm.valid ) {
            markAllAsDirty(this.imageForm);
            return;
        }

        this.http.post(uploadURL, toFormData(this.imageForm.value), {
            reportProgress: true,
            observe: 'events',
        }).pipe(
            takeUntil(this.ngUnsubscribe),
            uploadProgress(progress => (this.progress = progress)),
            toResponseBody()
        ).subscribe(res => {
            this.evalAPIResponse(res);
        });
    }

    removeMainImage() {
        this.adminProductService.removeMainImage(this.productID).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(response => this.evalAPIResponse(response));
    }



}
