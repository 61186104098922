import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Third party imports
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
//import { AgmCoreModule } from '@agm/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxLoadingModule } from 'ngx-loading';
// Local imports
import { LayoutsModule } from '../layouts/layouts.module';
import { PagesRoutingModule } from './pages-routing.module';
import { SharedModule } from '../shared/shared.module';
import { PagesComponent } from './pages.component';
import { PaymentsComponent } from './payments/payments.component';
import { ContactComponent } from './contact/contact.component';
import { NewCustomerComponent } from './newcustomer/newcustomer.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { AftercareComponent } from './aftercare/aftercare.component';
import { CustomPagesComponent } from './custom-pages/custom-pages.component';
// OLD CODE
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { EcomRegisterComponent } from './ecom-register/ecom-register.component';
import { PaymentstatusComponent } from './payments/paymentstatus/paymentstatus.component';
import { CalculatorsComponent } from './calculators/calculators.component';
import { SolentSelectionComponent } from './solent-selection/solent-selection.component';


@NgModule({
    declarations: [
      	PagesComponent,
      	PaymentsComponent,
      	ContactComponent,
      	NewCustomerComponent,
      	AftercareComponent,
      	PageNotFoundComponent,
      	AboutUsComponent,
        SolentSelectionComponent,
      	CustomPagesComponent,
      	EcomRegisterComponent,
      	PaymentstatusComponent,
      	CalculatorsComponent,
    ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        PagesRoutingModule,
        LayoutsModule,
        SharedModule,
        CarouselModule,
        NgbModule,
        FontAwesomeModule,
        NgxLoadingModule,
        GoogleMapsModule,
    ],
})
export class PagesModule { }
