
<section class="gym format">
    <div class="container">

        <div class="row">
            <div class="col-md-8 offset-md-2">
                        <div class="format-head-text">
                            <h3 class="about-font-header gradient-text">Product After Care</h3>
                        </div>           

                        <div class="format-sub-text mb-0">
                            <div *ngIf="selectedPage">
                                <div *ngIf="selectedPage.published === true" >
                                    <div [innerHTML]="selectedPage.content">

                                    </div>
                                </div>

                            </div>


                        </div>
            </div>
        </div>
    </div>
</section>


