import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Third party imports
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ExpressComponent } from './express.component';
import { ExpressLoginComponent } from './login/login.component';
import { LayoutsModule } from '../layouts/layouts.module';
import { ExpressRoutingModule } from './express-routing.module';
import { SharedModule } from '../shared/shared.module';
import { BasketComponent } from './basket/basket.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { NgxLoadingModule } from 'ngx-loading';
// Local imports
import { AuthGuardExpress } from './../_guards/index';
import { SolentExpressService } from './../_services/index';
import { MyAccountComponent } from './my-account/my-account.component';
import { OrderHistoryComponent } from './my-account/order-history/order-history.component';
import { SidebarExpressComponent } from './my-account/sidebar/sidebar.component';
import { SafeResourceUrlPipe } from './../_pipes/safeURL';
import { SanitizeHtmlPipe } from './../_pipes/safehtml';
@NgModule({
    declarations: [
        ExpressLoginComponent,
        ExpressComponent,
        BasketComponent,
        CheckoutComponent,
        MyAccountComponent,
        OrderHistoryComponent,
        SidebarExpressComponent,
        SafeResourceUrlPipe,
        SanitizeHtmlPipe
    ],
    imports: [
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        CommonModule,
        ExpressRoutingModule,
        LayoutsModule,
        SharedModule,
        NgbModule,
        FontAwesomeModule,
        CommonModule,
        NgxLoadingModule,
    ],
    providers: [
        AuthGuardExpress,
        SolentExpressService
    ]
})
export class ExpressModule { }
