<ng-template #addCTModal let-c="close" let-d="dismiss">
        <div class="modal-header">
          <h4 class="modal-title">Add Colour Type</h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div *ngIf="formError">
	            <div class="alert alert-danger" role="alert">
	                    <h1>{{formError}}</h1>
	            </div>
			</div> 
            <form class="form">
                    <div class="form-group" [formGroup]="colourTypeForm">
                    <label for="name">Name</label>
                    <div class="input-group">
                    <input class="form-control" placeholder="Name"
                        id="name"
                        type="text"
                        formControlName="name">
                    </div>
                    <p *ngIf="colourTypeForm.get(['name']).hasError('required')" 
		                style="color:red;">You must add a value</p>
		            <br />

		            <label for="name">Hex Value</label>
                    <div class="input-group">
                    <input class="form-control" placeholder="Hex Value"
                        id="hexValue"
                        [(colorPicker)]="color" [style.background]="selectedColour" 
                        (colorPickerChange)="updateColour(color=$event)"
                        [cpPosition]="'bottom'" [cpOutputFormat]="'hex'"
                        formControlName="hexValue"
                        autocomplete="off">

                    </div>
                    <p *ngIf="colourTypeForm.get(['hexValue']).hasError('required')" 
		                style="color:red;">You must add a hex value</p>
		            <br />

                    <label for="cat_select">Category</label>
		            <select class="form-control"
		                    formControlName="categoryID">
		            <option disabled selected value> --Select-- </option>
		            <option *ngFor="let cat of categories" [ngValue]="cat.id" >{{cat.name}}</option>
		            </select>
		            <p *ngIf="colourTypeForm.get(['categoryID']).hasError('required')" 
		                style="color:red;">You must select a category</p>
		            <br />

                    <button class="btn btn-success" [disabled]="manForm?.invalid" 
                    (click)="saveNewCT()">Save</button>

                    
                    </div>
            </form>

        <div class="modal-footer">
          <button type="button" class="btn btn-outline-dark" (click)="c('Close click')">Close</button>
        </div>
    </div>
</ng-template>


<section class="event team-sec speaker set-relative" id="speaker">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-sm-12 speker-container">
                <div class="table-responsive">
                    <button class="btn btn-danger" (click)="addCT()">
                        Add new
                    </button>
    				<table class="table table-striped">
    				  <thead>
    				  <tr>
    				    <th scope="col"># (Edit)</th>
    				    <th scope="col">Name</th>
    				    <th scope="col">Demo</th>
    				    <th scope="col">Category</th>
    				  </tr>
    				  </thead>
    				  <tbody>

    				  <tr *ngFor="let colour of colourTypes; index as i">
    				    <th scope="row" (click)="editCT(colour.id)">{{ colour.id }}</th>

    				    <td>{{ colour.name}}</td>
    				    <td><fa-icon size="lg" [icon]="faCircle" 
    				    	[styles]="{'stroke': colour.hexValue, 'color': colour.hexValue}"></fa-icon>  </td>
    				    <td>{{ colour.categoryName}}</td>
 
    				  </tr>
    				  </tbody>
    				</table>

    				

                    <br /><br />

                </div>

            </div>

          
        </div>
    </div>
</section>
