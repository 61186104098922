import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// New Code
import { AuthGuardExpress } from './../_guards/index';
import { ExpressLoginComponent } from './login/login.component';
import { BasketComponent } from './basket/basket.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { OrderHistoryComponent } from './my-account/order-history/order-history.component';
const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: 'login',
                component: ExpressLoginComponent,
                data: {
                    title: 'Solent Express Login',
                    breadcrumb: ''
                }
            },
            {
                path: 'basket',
                component: BasketComponent,
                canActivate: [AuthGuardExpress],
                data: {
                    title: 'Solent Express Basket',
                    breadcrumb: ''
                }
            },
            {
                path: 'checkout',
                component: CheckoutComponent,
                canActivate: [AuthGuardExpress],
                data: {
                    title: 'Solent Express Checkout',
                    breadcrumb: ''
                }
            },
            {
                path: 'my-account',
                component: MyAccountComponent,
                canActivate: [AuthGuardExpress],
                data: {
                    title: 'Solent Express - My Account',
                    breadcrumb: ''
                }
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ExpressRoutingModule { }
