import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class AuthGuardExpress  {

    private jwtHelper: JwtHelperService = new JwtHelperService();
    constructor(
        private router: Router,
        @Inject(PLATFORM_ID) private platformId: any) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (isPlatformBrowser(this.platformId)) {
            if (localStorage.getItem('cmsToken')) {
                // logged in so we can proceed to test that ecomUserToken JWT token
                // has OrderEntry permission
                const token = localStorage.getItem('cmsToken');
                const tokenDecode = this.jwtHelper.decodeToken(token);

                if (tokenDecode.roles.includes('express')) {
                    return true;
                }
                if (tokenDecode.roles.includes('swc_website_staff')) {
                    return true;
                }
                if (tokenDecode.roles.includes('swc_website_admin')) {
                    return true;
                }
                if (tokenDecode.roles.includes('family')) {
                    return true;
                }
                // else the user does not have correct permission, return false and to root site
                // index
                this.router.navigate(['/']);
                return false;
            }
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/express/login', { returnUrl: state.url }]);
        return false;
    }
}
