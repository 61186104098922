import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GenericResponse, ProductAttribute } from '../_models/index';
import { API_URL } from '../app.const';

@Injectable()
export class AdminAttributeService {
    private getAttributesURL = API_URL + '/admin/products/list_all_attributes';
    private markAttributeDisabledURL = API_URL + '/admin/products/mark_attribute_disabled';
    private addAttributeURL = API_URL + '/admin/products/add_attribute';
    private editAttributeURL = API_URL + '/admin/products/edit_attribute';


    constructor (private http: HttpClient) {}

    getAttributes() {
        return this.http.get<ProductAttribute[]>(this.getAttributesURL);
    }

    saveNewAttribute(name) {
        return this.http.post<GenericResponse>(this.addAttributeURL,
            JSON.stringify({ name }));
    }

    editAttribute(id, name) {
        return this.http.post<GenericResponse>(this.editAttributeURL,
            JSON.stringify({ id, name }));
    }

    markAttributeDisabled(id) {
        return this.http.post<GenericResponse>(this.markAttributeDisabledURL,
            JSON.stringify({ id }));
    }



}
