import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Third party imports
import { CarouselModule } from 'ngx-owl-carousel-o';

import 'mousetrap';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// Local Imports
import { SharedModule } from '../shared/shared.module';
import { LayoutsRoutingModule } from './layouts-routing.module';
import { ECommerceComponent } from './e-commerce/e-commerce.component';
import { EcommerceBannerSliderComponent } from './e-commerce/ecommerce-banner-slider/ecommerce-banner-slider.component';
import { ProductCategorySliderComponent } from './e-commerce/product-categories-slider/product-categories-slider.component';
import { EcommerceParallaxBannerComponent } from './e-commerce/ecommerce-parallax-banner/ecommerce-parallax-banner.component';
import { EcommerceProductTabComponent } from './e-commerce/ecommerce-product-tab/ecommerce-product-tab.component';
import { EcommerceLinksTabComponent } from './e-commerce/ecommerce-links-tab/ecommerce-links-tab.component';
import { EcommerceRecentStoryComponent } from './e-commerce/ecommerce-recent-story/ecommerce-recent-story.component';

@NgModule({
    declarations: [
        ECommerceComponent,
        EcommerceBannerSliderComponent,
        ProductCategorySliderComponent,
        EcommerceParallaxBannerComponent,
        EcommerceProductTabComponent,
        EcommerceLinksTabComponent,
        EcommerceRecentStoryComponent,
    ],

    imports: [
        CommonModule,
        LayoutsRoutingModule,
        CarouselModule,
        NgbModule,
        SharedModule,
        FormsModule,
        FontAwesomeModule,
        ReactiveFormsModule,
    ],

    exports: [
    ],

    providers: []
})

export class LayoutsModule { }
