<app-header></app-header>
<!-- breadcrumb section start -->
<section class="breadcrumb-section-main inner-2 breadcrumb-section-sm">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="breadcrumb-contain ">
                        <div *ngIf="product">
                            <h2 class="breadcrumb-txt">{{product.name}}</h2>
                        </div>
                        <div>
                            <ul>
                                
                                <li *ngIf="product"><a class="white" href="javascript:void()">
                                    {{product.briefDescription}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
<!--breadcrumb section end -->
<!-- section start -->
<ng-template #continueShoppingModal let-c="close" class="modal-dialog-centered" let-d="dismiss">
        <div class="modal-header">
          <h4 class="modal-title">Item added to basket</h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">


        <div class="text-center">
            <a [routerLink]="['/express/basket']">
              <button type="button" class="btn btn-default primary-btn success back-white" >
                Checkout
               </button>
            </a>

           <button type="button" class="btn btn-default primary-btn danger" 
                  (click)="c('Close click')">Continue Shopping
            </button>

            <div *ngIf="relatedProducts?.length">
                <h4 class="grey">You may also like:</h4>
                <table class="table">
              <thead>
              <tr>
                <th scope="col">Image</th>
                <th scope="col">Product</th>
              </tr>
              </thead>
              <tbody>

              <tr *ngFor="let product of relatedProducts">
               
                <td><a [routerLink]="['/products/view', product.slug]">
                    <img [src]="product.mainImageAWSURL" class="card-img-top autofit modalimage">
                    </a>
                </td>
                <td> <h4><a [routerLink]="['/products/view', product.slug]">{{product.name }}</a></h4>
                      <br />
                      {{product.briefDescription}}
                </td>
                
              </tr>
              </tbody>
            </table>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #addToCartModal let-c="close" class="modal-dialog-centered" let-d="dismiss">
        <div class="modal-header">
          <h4 class="modal-title">Add To Cart</h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">


        <form class="form">
            <div class="form-group" [formGroup]="purchaseForm">
                <h6 class="product-title">Product</h6>
                <select class="form-control"
                        formControlName="stockref">
                <option disabled selected value> --Select-- </option>
                <option *ngFor="let stockref of selectedImage.stockrefs" 
                        [ngValue]="stockref.id" >{{stockref.freeText}}</option>
                </select>

                <div *ngIf="selectedStockref">
                <br />
                <h6 class="product-title">Quantity Required</h6><br />
                <div class="input-group">
                        <input *ngIf="selectedStockref[0].width <= 1" type="text" maxlength="3"
                            class="form-control fiftypercent" placeholder="Amount required?"
                            id="qty" inputmode="numeric" pattern="[0-9]*"   digitOnly
                            formControlName="qty">

                        <input *ngIf="selectedStockref[0].width > 1.01" type="text" maxlength="5"
                            class="form-control fiftypercent" placeholder="Amount required?"
                            id="qty" inputmode="numeric" digitOnly decimal="true"
                            formControlName="qty">

                        <button class="btn btn-success"
                        (click)="addToCart()">Add</button>
                </div>
                </div>
            </div>
        </form>

        <div *ngIf="selectedStockref" class="table-responsive">
            <table class="table">
              <thead>
              <tr>
                <th scope="col">Pack Price</th>
                <th scope="col">M2 Price</th>
                <th *ngIf="selectedStockref[0].width > 1.01" scope="col">Width</th>
                <th *ngIf="selectedStockref[0].width <= 1" scope="col">Pack Size</th>
              </tr>
              </thead>
              <tbody>

              <tr>
               
                <td *ngIf="stockrefPriceQ">£ {{stockrefPriceQ.price }}</td>
                <td *ngIf="stockrefPriceQ">
                    £ {{calculateSqmPrice(stockrefPriceQ.price, selectedStockref[0].packSize)}}
                </td>
                <td *ngIf="selectedStockref[0].width > 1.01">{{selectedStockref[0].width}} m</td>
                <td *ngIf="selectedStockref[0].width <= 1">{{selectedStockref[0].packSize}} m2</td>
                
              </tr>
              </tbody>
            </table>

            <br /><br />

        </div>


    </div>
</ng-template>


<div *ngIf="product" class="agency">
    <section>
        <div class="collection-wrapper">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-xs-12">
                        <div class="container-fluid">
                        <div class="row">
                          <div *ngIf="product.clearance" class="ribbon ribbon-top-right"><span>Limited Stock</span></div>
                            <div class="col-lg-6">
                                    <h3 class="grey">{{product.name}}</h3>

                                    <img class="img-product-detail"
                                        [src]="imageCheck(product.mainImageAWSURL)" width="100%">

                                    <div class="border-product">
                                        <div [innerHTML]="product.wysiwyg"></div>
                                    </div>

                            </div>
                            <div class="col-lg-6 rtl-text">
                                <div class="product-right">
                                    

                                    


                                    <div *ngIf="expressCustomer === true;">
                                    <div *ngIf="expressAvailable" class="border-product">
                                        <h6 class="product-title">Select a product</h6>
                                        <form class="form">
                                            <div class="form-group" [formGroup]="purchaseForm">
                                                <select class="form-control"
                                                        formControlName="stockref">
                                                <option disabled selected value> --Select-- </option>
                                                <option *ngFor="let stockref of expressReferences" 
                                                        [ngValue]="stockref.id" >{{stockref.freeText}}</option>
                                                </select>

                                                <div *ngIf="selectedStockref">
                                                <h6 class="product-title">Packs Required</h6>
                                                <div class="input-group">
                                                    <input *ngIf="selectedStockref[0].width <= 1" type="text" maxlength="3"
                                                        class="form-control fiftypercent" placeholder="Amount required?"
                                                        id="qty" inputmode="numeric" pattern="[0-9]*"   digitOnly
                                                        formControlName="qty">

                                                    <input *ngIf="selectedStockref[0].width > 1.01" type="text" maxlength="5"
                                                        class="form-control fiftypercent" placeholder="Amount required?"
                                                        id="qty" inputmode="numeric" digitOnly decimal="true"
                                                        formControlName="qty">

                                                        <button class="btn btn-success"
                                                        (click)="addToCart()">Add</button>
                                                </div>
                                                </div>
                                            </div>
                                        </form>
                                        <div *ngIf="selectedStockref" class="table-responsive">
                                            <table class="table">
                                              <thead>
                                              <tr>
                                                <th scope="col">Unit Price</th>
                                                <th scope="col">M2 Price</th>
                                                <th *ngIf="selectedStockref[0].width > 1.01" scope="col">Width</th>
                                                <th *ngIf="selectedStockref[0].width <= 1" scope="col">Pack Size</th>
                                              </tr>
                                              </thead>
                                              <tbody>

                                              <tr>
                                               
                                                <td *ngIf="stockrefPriceQ">£ {{stockrefPriceQ.price }}</td>
                                                <td *ngIf="stockrefPriceQ">
                                                    £ {{calculateSqmPrice(stockrefPriceQ.price, selectedStockref[0].packSize)}}
                                                </td>
                                                <td *ngIf="selectedStockref[0].width > 1.01">{{selectedStockref[0].width}} m</td>
                                                <td *ngIf="selectedStockref[0].width <= 1">{{selectedStockref[0].packSize}} m2</td>
                                                
                                              </tr>
                                              </tbody>
                                            </table>


                                        </div>
                                    </div>

                                        <div *ngIf="expressAvailable === false" class="border-product text-center">
                                            <h6 class="product-title">Solent Express</h6>

                                            <a class="btn btn-default primary-btn">
                                            No Express Service Available</a>
                                            
                                        </div>

                                    </div>

                                    <div *ngIf="expressCustomer === false;">
                                    <div class="border-product text-center">
                                        <h6 class="product-title">SOLENT EXPRESS</h6>

                                       <a [routerLink]="['/express/login']" class="btn btn-default primary-btn info">
                                        Login To See Prices</a> <br />
                                        <span *ngIf="expressAvailable === true" class="redtext">NATIONWIDE NEXT DAY SERVICE AVAILABLE</span>
                                                                               
                                    </div>
                                    </div>

                                    <div class="border-product">
                                            <div class="table-responsive">
                                            <table class="table">
                                              <thead>
                                              <tr>
                                                <th scope="col">Attributes</th>
                                                <th scope="col"></th>
                                              </tr>
                                              </thead>
                                              <tbody>
                                               <tr>                                               
                                                <td>Manufacturer</td>
                                                <td>{{ product.manufacturerName }}</td>
                                              </tr>

                                              <tr *ngFor="let attr of product.attributes">
                                               
                                                <td>{{ attr.attributeName}}</td>
                                                <td>{{ attr.attributeValue}}</td>
                                                
                                                
                                              </tr>
                                              <tr *ngIf="expressCustomer === true;">
                                                <td>Sample</td>
                                                <td *ngIf="sampleRequest === false;"> <button (click)="requestSample()" class="btn btn-danger button-border" >
                                                    Reqeust sample
                                                    </button>
                                                </td>
                                                <td *ngIf="sampleRequest === true;"> <button class="btn btn-success button-border" >
                                                    Sample Requested
                                                    </button>
                                                </td>
                                                
                                                
                                              </tr>
                                              </tbody>
                                            </table>

                                        </div>
                                    </div>

                                    <div *ngIf="product.documents.length" class="border-product">
                                            <h6 class="product-title">Downloads</h6>
                                            <a *ngFor="let doc of product.documents" href="{{doc.awsURL}}">
                                            <button class="btn btn-success button-border" >
                                                    <fa-icon class="" [icon]="faFile"></fa-icon> {{doc.name}} <br />
                                            </button>
                                            </a>
                                    </div>


                                    
                                    <div class="border-product">
                                        <h6 class="product-title">Share This Page</h6>
                                        <share-buttons [theme]="'material-dark'"
                                                 [include]="['whatsapp', 'email', 'sms', 'copy', 'facebook', 'pinterest', 'twitter', 'linkedin' ]"
                                                 [show]="8"
                                                 [url]="currentURL">
                                        </share-buttons>                  
                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-12 col-xs-12">
                            <section class="portfolio-section titles masonray-sec zoom-gallery">
                            <div class="filter-section">
                                <div class="container">

                                    <div class="card" *ngIf="product.youtube">
                                      <iframe height="296" [src]="product.youtube | safe" frameborder="0" 
                                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                                      allowfullscreen></iframe>

                                      <div class="card-body">
                                        <h4 class="card-title grey">Product Video</h4>
                                        <button class="btn btn-danger">YouTube</button>
                                      </div>
                                  </div>

                                    <div class="card" *ngFor="let image of product.colours; let i=index">
                                      <a (click)="open(i)">
                                      <img  [src]="image.awsURL" class="card-img-top autofit">
                                      </a>
                                      <div class="card-body">
                                        <h4 class="card-title grey">{{testSTR(image)}}</h4>

                                        <div *ngIf="expressCustomer === true;">
                                        <button *ngIf="testExpress(image) === true;" class="btn btn-success"
                                            (click)="openCartModal(image)">Add To Cart</button>
                                        </div>

                                        <div *ngIf="expressCustomer === false;">
                                        <a [routerLink]="['/express/login']" class="btn btn-default primary-btn info">
                                        Login For Pricing</a>
                                        </div>

                                      </div>
                                  </div>
                            </div>
                          </div>
                          </section>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-xs-12">
                            <section class="portfolio-section titles masonray-sec zoom-gallery">
                            <div class="filter-section">
                                <div class="container">

                                    <h3 *ngIf="relatedProducts?.length" class="grey">Related Products</h3>


                                    <div class="card" *ngFor="let product of relatedProducts; let i=index">
                                      <a [routerLink]="['/products/view', product.slug]">
                                      <img  [src]="product.mainImageAWSURL" class="card-img-top autofit">
                                      </a>
                                      <div class="card-body">
                                        <h4 class="card-title grey">{{product.name}}</h4>                                 
                                        <p class="card-text">{{product.briefDescription}}</p>
                                      </div>
                                  </div>
                            </div>
                          </div>
                          </section>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-xs-12">
                            <p>We cannot guarantee an exact match in colour and finish, we will not accept any complaints on goods that appear different to the images shown on our website. Samples are available on request and we advise all customers to check goods against a physical sample before ordering

                            </p>
                        </div>
                    </div>




                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
<!-- Section ends -->
<app-footer></app-footer>
