import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ECommerceComponent } from './layouts/e-commerce/e-commerce.component';
import { PagesComponent } from './pages/pages.component';
import { ExpressComponent } from './express/express.component';
import { BlogComponent } from './blog/blog.component';
import { AdminComponent } from './admin/admin.component';
import { ProductComponent } from './products/product.component';
import { AuthGuard } from './_guards/index';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
export const routes: Routes = [
    {
        path: '',
        component: ECommerceComponent
    },
    {
        path: 'blog',
        component: BlogComponent,
        loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule),
        data: {
            breadcrumb: 'Pages'
        }
    },
    {
        path: 'express',
        component: ExpressComponent,
        loadChildren: () => import('./express/express.module').then(m => m.ExpressModule),
        data: {
            breadcrumb: 'Express'
        }
    },
    {
        path: 'page',
        component: PagesComponent,
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
        data: {
            breadcrumb: 'Pages'
        }
    },
    {
        path: 'products',
        component: ProductComponent,
        loadChildren: () => import('./products/product.module').then(m => m.ProductModule),
        data: {
            breadcrumb: 'Products'
        }
    },
    {
        path: 'admin',
        canActivate: [AuthGuard],
        component: AdminComponent,
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
        data: {
            breadcrumb: 'Admin'
        }
    },
    {
        path: '**',
        component: PageNotFoundComponent,
        data: {
            breadcrumb: 'ERROR - 404'
        }
    }


];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
