import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import {Store} from '@ngrx/store';
import { CartItem, CartTotal, CustomerAddress, DeliveryQuote, StockCheck } from '../../_models/';
import { AuthenticationService, SolentExpressService } from '../../_services/';
import { Observable } from 'rxjs';
import * as fromRoot from '../../_ngrx/index';
import * as myActions from '../../_ngrx/actions';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormControl, UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject} from 'rxjs';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-basket',
    templateUrl: './basket.component.html',
    styleUrls: ['./basket.component.scss']
})
export class BasketComponent implements OnInit, OnDestroy {
    @ViewChild('postcodeModal', { static: true }) private postcodeModal;
    faTimes = faTimes;
    faCheck = faCheck;
    totalExVat = 0;
    totalVat = 0;
    delivery = 0;
    deliveryPremium = 0;
    fullTotal = 0;
    deliveryChecked = false;
    freeDeliverySpend = 0;
    deliveryError = null;
    newDeliveryQuote: DeliveryQuote = null;
    faPlus = faPlus;
    faMinus = faMinus;
    defaultSiteContact;
    premiumUpgrade = false;
    customerAddress: CustomerAddress = null;
    stockCheck: StockCheck[] = null;
    orderLines: number;
    lines$: Observable<number> = this.store.select(fromRoot.selectOrderLines);
    basket: CartItem[];
    basket$: Observable<CartItem[]> = this.store.select(fromRoot.selectBasket);
    postcodeForm = new UntypedFormGroup({
        postcode: new UntypedFormControl(null, [Validators.required]),
        nextDay: new UntypedFormControl(false),
        amDrop: new UntypedFormControl(false),
    });
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    private modalRefService: NgbModalRef;
    constructor(
        private authService: AuthenticationService,
        private expressService: SolentExpressService,
        private modalService: NgbModal,
        private store: Store<fromRoot.State>,
        private fb: UntypedFormBuilder) { }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit(): void {
        this.basket$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(basket => this.orderBasket(basket));
        this.lines$.subscribe(lines => this.orderLines = lines);
        this.modalService.dismissAll();
        this.getCustomerAddress();
        // Subscribe to form changes, reset address if postcode is changed
        this.postcodeForm.get('postcode').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(newPostCode => {
            this.updatePostCode(newPostCode);
        });
    }

    updatePostCode(newPostCode) {
        let siteContact = null;
        if (this.customerAddress !== null) {
            siteContact = this.customerAddress.siteContactNo;
        }
        const newAddress: CustomerAddress = {
            orderdelivL1: '',
            orderdelivL2: '',
            orderdelivL3: '',
            orderdelivL4: '',
            orderdelivL5: newPostCode,
            siteContactNo: siteContact,
        };
        this.store.dispatch(myActions.updateAddress ({customerAddress: newAddress}));
    }

    orderBasket(basket: CartItem[]) {
        this.basket = JSON.parse(JSON.stringify(basket));
        this.basket.sort((a, b) => a.stockref.localeCompare(b.stockref));
        this.deliveryChecked = false;
        this.delivery = 0;
        this.deliveryPremium = 0;
        this.freeDeliverySpend = 0;
        this.deliveryError = null;
        this.calculateTotals(null, false, false);
        this.checkStock();
    }

    checkStock() {
        const orderLines = [];
        for (const item of this.basket) {
            const lineObj = {lineno: item.lineno, stockref: item.stockref, qty: item.qtyLength};
            orderLines.push(lineObj);
        }
        // Now we have an array of all our order lines, we send to the API to check stock
        /*
            create aray of objects like...
            only show amount stock is status is false
            [{'lineno': 1. stockref': un023, 'orderstatus': true/false, 'amountStock': 200}]
        */
        this.expressService.checkStock(orderLines).subscribe(
            x => this.stockCheck = x
        );
    }

    checkStockObj(item) {
        // check array of stock checks for reference
        if (this.stockCheck !== null ) {
            const stockCheckSelection = this.stockCheck.filter(x => x.lineno === item.lineno);
            if (stockCheckSelection[0] !== undefined) {
                return stockCheckSelection[0].checkStatus;
            }
        }
    }

    checkStockStatus(item) {
        if (this.stockCheck !== null ) {
            const stockCheckSelection = this.stockCheck.filter(x => x.lineno === item.lineno);
            let stockPieces = '';
            for (const piece of stockCheckSelection[0].amountStock) {
                stockPieces += piece.toString() + ', ';
            }
            return stockPieces.substring(0, stockPieces.length - 2);
        }
    }

    getCustomerAddress() {
        /* We need to fetch the standard customer address from the API
           and populate state with the result
        */
        this.expressService.getDefaultAddress(this.authService.returnCustomerNo()).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(address => {
                this.updateStateAddress(address);
            });
    }

    updateStateAddress(address: CustomerAddress) {
        // First change the form value
        this.postcodeForm.controls.postcode.setValue(address.orderdelivL5);
        this.customerAddress = address;
        // Now store address in state
        this.store.dispatch(myActions.updateAddress ({customerAddress: address}));
    }

    calculateTotals(deliveryQuote: DeliveryQuote, premiumStatus, deliveryCheckTest) {
        let deliveryConsignments = [];
        if (deliveryQuote === null) {
            this.delivery = 0;
            this.deliveryPremium = null;
        } else {
            this.delivery = deliveryQuote.deliveryPrice;
            deliveryConsignments = deliveryQuote.consignments;
        }
        let newPremiumStatus = false;
        if (premiumStatus === true) {
            newPremiumStatus = true;
        }
        this.totalExVat = 0;
        this.totalVat = 0;
        this.fullTotal = 0;
        for (const item of this.basket) {
            const priceCalc = Number(item.qtyLength) * Number(item.qtyWidth) * Number(item.unitPrice);
            this.totalExVat += priceCalc;
            this.totalExVat.toFixed(2);
        }

        const vatCalc = Number(this.totalExVat) + Number(this.delivery);
        this.totalVat = vatCalc * 0.2;
        this.fullTotal = this.totalVat + this.delivery + this.totalExVat;

        const newCartTotal: CartTotal = {
            totalExVat: this.totalExVat,
            delivery: this.delivery,
            vat: this.totalVat,
            total: this.fullTotal,
            premium : newPremiumStatus,
            deliveryCheck: deliveryCheckTest,
            consignments: deliveryConsignments,
        };
        this.store.dispatch(myActions.updateCartTotals ({cartTotal: newCartTotal}));


    }

    getDeliveryQuote() {
        this.modalRefService = this.modalService.open(this.postcodeModal, {centered: true});
    }

    calculateViaAPI() {
        /* Send all the data to the backend to perform the heavy lifting
        */
        this.expressService.getDeliveryQuote(
            this.basket,
            this.totalExVat,
            this.postcodeForm.controls.postcode.value,
            this.authService.returnCustomerNo(),
            this.postcodeForm.controls.nextDay.value,
            this.postcodeForm.controls.amDrop.value,
        ).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(delivery => {
                this.setDeliveryPrice(delivery);
            });
    }

    setDeliveryPrice(deliveryQuote: DeliveryQuote) {
        this.newDeliveryQuote = deliveryQuote;
        this.modalService.dismissAll();

        this.delivery = deliveryQuote.deliveryPrice;
        this.deliveryPremium = deliveryQuote.premiumUpgrade;
        this.deliveryError = deliveryQuote.error;
        if (this.deliveryError === null) {
            this.deliveryChecked = true;
            this.freeDeliverySpend = deliveryQuote.extraSpendForFree;
        }
        this.calculateTotals(deliveryQuote, false, true);
    }

    calculateLineSize(item: CartItem) {
        const calc = item.qtyLength * item.qtyWidth * item.packSize;
        return calc.toFixed(2);

    }

    calculateLineValue(item: CartItem) {
        const calc = item.qtyLength * item.qtyWidth * item.unitPrice;
        return calc.toFixed(2);
    }

    removeCartItem(item: CartItem) {
        this.store.dispatch(myActions.removeFromCart ({cartItem: item}));
    }

    oneLessQty(item) {
        const newCartItem: CartItem = JSON.parse(JSON.stringify(item));
        if (item.qtyWidth > 1) {
            // we are dealing with a cut length
            if ( newCartItem.qtyLength >= 1.10) {
                // we can remove 10cm
                const newQty = Number(newCartItem.qtyLength) - 0.10;
                // Round the values up to ensure they are clean
                newCartItem.qtyLength = Math.round(newQty * 10) / 10;
            }
        } else {
            // We are dealing with a non linear item
            if ( newCartItem.qtyLength >= 2) {
                newCartItem.qtyLength = Number(newCartItem.qtyLength) - 1;
            }
        }

        // because we copy the original, its safe to delete and re-add
        // even if no changes are made
        this.store.dispatch(myActions.removeFromCart ({cartItem: item}));
        newCartItem.lineno = this.orderLines + 1;
        this.store.dispatch(myActions.addToCart ({cartItem: newCartItem}));

    }

    oneMoreQty(item) {
        const newCartItem: CartItem = JSON.parse(JSON.stringify(item));
        if (item.qtyWidth === 1) {
            newCartItem.qtyLength = Number(newCartItem.qtyLength) + 1;
        } else {
            const newQty = Number(newCartItem.qtyLength) + 0.10;
            // Round the values up to ensure they are clean
            newCartItem.qtyLength = Math.round(newQty * 10) / 10;
        }
        // this.store.dispatch(myActions.updateCart ({cartItem: item}));
        this.store.dispatch(myActions.removeFromCart ({cartItem: item}));
        newCartItem.lineno = this.orderLines + 1;
        this.store.dispatch(myActions.addToCart ({cartItem: newCartItem}));
    }

    upgradeDelivery(upgradePrice) {
        this.premiumUpgrade = true;
        const newDeliveryPrice = Number(this.delivery) + Number(upgradePrice);
        this.newDeliveryQuote.deliveryPrice = newDeliveryPrice;
        this.calculateTotals(this.newDeliveryQuote, true, true);
    }

    proceedCheckout() {
        // navigate user to checkout
    }

}
