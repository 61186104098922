import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GenericResponse, ProductAttribute } from '../_models/index';
import { API_URL } from '../app.const';

@Injectable()
export class AdminBlogService {
    private getAttributesURL = API_URL + '/admin/products/list_all_attributes';
    private publishBlogPostURL = API_URL + '/admin/blog/publish_post';
    private removeBlogImageURL = API_URL + '/admin/blog/remove_blog_image';

    constructor (private http: HttpClient) {}

    getAttributes() {
        return this.http.get<ProductAttribute[]>(this.getAttributesURL);
    }

    publishPost(postID) {
        return this.http.post<GenericResponse>(this.publishBlogPostURL,
            JSON.stringify({ postID }));
    }

    removeBlogImage(postID) {
        return this.http.post<GenericResponse>(this.removeBlogImageURL,
            JSON.stringify({ postID }));
    }


}
