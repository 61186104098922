<app-header></app-header>
<!-- breadcrumb section start -->
<section class="breadcrumb-section-main inner-2 breadcrumb-section-sm">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="breadcrumb-contain ">
                        <div>
                            <h2 class="breadcrumb-txt">Products</h2>
                        </div>
                        <div>
                            <ul>
                                <li><a class="white" href="javascript:void()">Search Results</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</section>

<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

<!-- section start -->
<section class="section-b-space">
  <div class="collection-wrapper">
    <div class="container">
      <div class="row">
        <div class="collection-content col-sm-9">
          <div class="page-main-content">
            <div class="container-fluid">
              <div class="row">
                <div class="col-sm-12">
                  <div class="collection-product-wrapper">
                    <!-- Filter Block Products -->
                    <div class="product-top-filter">
                      <div class="container-fluid p-0">
                        <div class="row">
                          <div class="col-xl-12">
                            <div class="filter-main-btn">
                                <span class="filter-btn btn btn-default primary-btn radius-0" (click)="toggleFilter()">
                                <i class="fa fa-filter" aria-hidden="true"></i>Filter Page
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <div class="product-filter-content">
                              <div class="search-count" *ngIf="filterProducts?.length">
                                <h5>Showing Products 1-{{filterProducts.length}}</h5>
                              </div>

                              <!--
                              <div class="product-page-filter">
                                <select (change)="onChangeSorting($event.target.value)">
                                  <option value="asc">Sorting items</option>
                                  <option value="asc">Ascending Order</option>
                                  <option value="desc">Descending Order</option>
                                  <option value="a-z">Alphabetically, A-Z</option>
                                  <option value="z-a">Alphabetically, Z-A</option>
                                  <option value="low">Price, low to high</option>
                                  <option value="high">Price, high to low</option>
                                </select>
                              </div>
                            -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- End Block Products -->
                    <!-- Display Products -->
                    <div class="product-wrapper-grid" [class.list-view]="false" >
                      <div class="container-fluid">
                        <div class="row">
                          <div [class.col-xl-3]="true" 
                               [class.col-md-6]="true" 
                               class="col-grid-box" 
                               *ngFor="let product of filterProducts">
                            <div class="product-box">
                              
                                <div class="img-wrapper">
                                    <div class="front">
                                      <a [routerLink]="['/products/view', product.slug]">
                                        <img alt="" class="img-fluid img-product-results" 
                                             routerLinkActive="active" [src]="imageCheck(product.mainImageAWSURL)">
                                      </a>
                                    </div>

                                  </div>
                                  <div class="product-detail">
                                    <a [routerLink]="['/products/view', product.slug]" class="notext">
                                      <h6 class="product-title">{{product.name}}</h6>
                                    </a>
                                    <span *ngIf="product.clearance === true;" class="discon"> * Limited Stock * <br /></span>
                                    <span>                                                                         {{product.briefDescription}} </span> <br />
                                    
                                  </div>


                            </div>


                          </div>

                          <div class="col-sm-12 text-center section-b-space mt-5 no-found" *ngIf="!filterProducts?.length">
                            <img src="assets/images/empty-search.jpg" class="img-fluid mb-4">
                            <h3>Sorry! There are no products in this category</h3>
                            <p>Please check another category and try again.</p>
                            <a [routerLink]="'/products/categories'" class="btn btn-default primary-btn ra-dius-0 m-t-10">
                            continue browsing</a>
                          </div>

                        </div>
                      </div>



                      <!-- pagination Start -->
                          <div  class="pagination_sec">
                              <div class="content_detail__pagination cdp">
                                <h4>Pages</h4>
                                <ul class="pagination">
                                  <li *ngFor="let page of pages" (click)="navToPage(page)" [ngClass]="{'active': activePagCheck(page)}" 
                                       class="page-item"><a class="page-link">{{page}}</a></li>
                                </ul>
                                  
                              </div>
                          </div>
                          <!-- pagination End -->
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
         <!-- Collection Sidebar -->
         <a  class="overlay-sidebar" [class.overlay-sidebar-open]="productsService.filterBar" (click)="closeOverlay()"></a>
         <div class="collection-filter"  [class.filterBar]="productsService.filterBar">
          <div class="coll_sidebar_sticky">

            <div *ngIf="manufacturers" class="collection-filter-block">
              <h3 class="collapse-block-title">Filter Results</h3>
              <!-- start tags -->
              <div *ngIf="manufacturers.length">


                <div class="collection-collapse-block pt-0 open">
                  <h3 class="collapse-block-title">Supplier</h3>
                  <div class="collection-collapse-block-content">
                    <div class="collection-brand-filter">
                      <div class="" *ngFor="let man of manufacturers">
                        
                        <div *ngIf="checkedMan(man.id) !== true">
                        <fa-icon size="lg" [icon]="faCircle" (click)="selectMan(man)"
                          class="whiteFA"></fa-icon> 
                          {{man.name}}
                        </div>

                        <div *ngIf="checkedMan(man.id) === true">
                        <fa-icon size="lg" [icon]="faCheckCircle" (click)="selectMan(man)"
                          class="blackFA"></fa-icon> 
                          {{man.name}}
                        </div>


                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end tags -->

              <!-- Colours -->
                <div *ngIf="allColours" class="collection-collapse-block open">
                
                <div *ngIf="allColours.length"  class="collection-collapse-block-content">
                  <h3 class="collapse-block-title">Colours</h3>
                    <div class="color-selector">
                      <div *ngFor="let colour of allColours">
                        <div *ngIf="checkedColour(colour.id) !== true">
                        <fa-icon size="lg" [icon]="faCircle" (click)="selectColour(colour)"
                          [styles]="{'stroke': colour.colourTypeHexValue, 'color': colour.colourTypeHexValue}"></fa-icon> 
                        {{colour.colourTypeName}}  
                      </div>

                      <div *ngIf="checkedColour(colour.id) === true">
                        <fa-icon size="lg" [icon]="faCheckCircle" (click)="selectColour(colour)"
                          [styles]="{'stroke': colour.colourTypeHexValue, 'color': colour.colourTypeHexValue}"></fa-icon> 
                        {{colour.colourTypeName}}  
                      </div>

                        </div>
     
                    </div>
                </div>
                </div>

              <!-- end colours -->
            </div>
          </div>
        </div>
      </div>
          <!-- End Collection Sidebar -->
      </div><!-- end row -->
    </div>
  </div>
</section>
<!-- section End -->
<app-footer></app-footer>
