import { createSelector } from '@ngrx/store';
import { CartItem, CustomerAddress, CartTotal } from '../_models/index';


export interface State {
    items: CartItem[];
    deliveryAddress: CustomerAddress;
    totals: CartTotal;
}

/* basketState is how we define state in app.module.ts so
   we prefix it here
*/
export const selectAllItems = (state: any) => state.basketState;

export const selectBasket = createSelector(
    selectAllItems,
    (state) => state.items
);

export const selectTotals = createSelector(
    selectAllItems,
    (state) => state.totals
);

export const selectDelivery = createSelector(
    selectAllItems,
    (state) => state.deliveryAddress
);

export const selectOrderLines = createSelector(
    selectAllItems,
    (state) => state.lines
);

