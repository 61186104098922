<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<!-- section start -->
<section class="register-page section-b-space">
  <div class="container">
      <div class="row">
          <div class="col-lg-8">

              <div class="theme-card">
                   
                  <form class="theme-form">
                    <div class="form-group"  [formGroup]="paymentForm">
                      <div class="form-row">
                          <div class="col-md-12">
                            <h3>Make a payment</h3>
                              <p>
                             From this page you can pay individual invoices or your monthly statement, if you require assistance with this procedure, please contact our sales office.</p>
                              <br />
                          </div>
                      </div>

                      
                      <div class="form-row">
                          <div class="col-md-12">
                              <label for="email">Trading Name</label>
                              <input type="text" class="form-control" id="tname"
                                      formControlName="tradingName"
                                      placeholder="Trading name">
                              <p *ngIf="paymentForm.get(['tradingName']).hasError('required')" 
                                    class="form-warning">Input Required</p>
                          </div>
                      </div>

                      <div class="row">
                          <div class="col-md-6">
                              <label for="email">Account #</label>
                              <input type="text" class="form-control whiteform" id="accountno"
                                      formControlName="accountNo"
                                      placeholder="Account #">
                              <p *ngIf="paymentForm.get(['accountNo']).hasError('required')" 
                                    class="form-warning">Input Required</p>
                          </div>
                          <div class="col-md-6">
                              
                            <label for="email">Amount Paying (&#163;)</label>
                              <input type="number" class="form-control whiteform" id="amountpaying"
                                      formControlName="amountPaying"
                                      placeholder="Amount Paying (&#163;)">
                              <p *ngIf="paymentForm.get(['amountPaying']).hasError('required')" 
                                    class="form-warning">Input Required</p>
                          </div>
                          
                          
                      </div>  

                      <div class="form-row">
                          <div class="col-md-12">
                              <label for="email">Reference</label>
                              <input type="text" class="form-control whiteform" id="transRefForm"
                                      formControlName="transRefForm"
                                      placeholder="Reference, e.g: invoice 1445127">
                              <p *ngIf="paymentForm.get(['transRefForm']).hasError('maxlength')" 
                                    class="form-warning">Too many characters</p>
                          </div>
                      </div>                    

                      

                      <div *ngIf="formError" class="form-row">
                          <h3>{{formError}}</h3>
                      </div>

                      <div class="form-row">
                          <button (click)="generateOpenBankingURL()" [disabled]="paymentForm?.invalid" 
                                   class="btn primary-btn btn-primary btn-blue radius-0">Submit</button>
                      </div>

                  </div>
                  </form>

              </div>
          </div>


          <div class="col-lg-4 hiddenSMALL">
            <div class="theme-card">
            <h3>Looking for Solent Express?</h3>
              <p class="highlightURL">

             Solent Express is a national service we offer on <a href="">selected products</a>, available nationwide
            with next day delivery. Exclusive to the flooring trade, <a [routerLink]="['/page/new-customer']">open an account</a> today</p> <br />

            </div>

            <div class="theme-card">
            <h3>Want us to save your details?</h3>
              <p class="highlightURL">

             We have the facility to securely store your card details (if you want us to), useful for if you make regular payments. We don't
             have access to them, the bank stores a token that allows us to re-take future payments with your consent. If you are interested
             in this, or have any questions, please <a [routerLink]="['/page/contact']">contact us</a> today</p> <br />

            </div>

          </div>


      </div>
  </div>
</section>