import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OrderStatus } from '../../_models/index';
import { AdminExpressService } from '../../_services/index';
import { takeUntil } from 'rxjs/operators';
@Component({
    selector: 'app-orderstatus',
    templateUrl: './orderstatus.component.html',
    styleUrls: ['./orderstatus.component.scss']
})
export class OrderStatusComponent implements OnInit, OnDestroy {
    orderstatuss: OrderStatus[];
    formError;
    editForm = false;

    statusForm = new UntypedFormGroup({
        id: new UntypedFormControl(null),
        name: new UntypedFormControl(null, Validators.required)
    });

    private modalRefService: NgbModalRef;
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    @ViewChild('EditStatus', { static: true }) private editStatusModal;

    constructor(
        private adminExpress: AdminExpressService,
        private modalService: NgbModal) { }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit(): void {
        this.fetchData();
    }

    fetchData() {
        this.adminExpress.getOrderStatus()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                statuss => this.orderstatuss = statuss);
    }

    evalAPIResponse (response) {
        if (response.status === true) {
            this.statusForm.reset();
            this.formError = null;
            this.modalService.dismissAll();
            this.fetchData();
        } else {
            this.formError = response.message;
        }

    }


    editStatus(status: OrderStatus) {
        this.editForm = true;
        this.statusForm.reset();
        this.statusForm.controls.id.setValue(status.id);
        this.statusForm.controls.name.setValue(status.name);
        this.modalRefService = this.modalService.open(this.editStatusModal);
    }

    addStatus() {
        this.editForm = false;
        this.statusForm.reset();
        this.modalRefService = this.modalService.open(this.editStatusModal);
    }

    saveStatus() {
        // Update details with API
        const statusID =  this.statusForm.controls.id.value;
        const statusName =  this.statusForm.controls.name.value;

        if (this.editForm === true) {
            this.adminExpress.updateOrderStatus(
                statusID, statusName)
                .subscribe(response => this.evalAPIResponse(response));
        } else {
            this.adminExpress.addOrderStatus(statusName)
                .subscribe(response => this.evalAPIResponse(response));
        }
    }


}
