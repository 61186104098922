import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { filter, map, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ViewProduct } from '../../../../_models/index';
import { AdminProductService } from '../../../../_services/index';
import { pipe } from 'rxjs';
import { HttpClient, HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { API_URL } from '../../../../app.const';
import { faTimes } from '@fortawesome/free-solid-svg-icons';


export function uploadProgress<T> (cb: ( progress: number ) => void ) {
    return tap(( event: HttpEvent<T> ) => {
        if ( event.type === HttpEventType.UploadProgress ) {
            cb(Math.round((100 * event.loaded) / event.total));
        }
    });
}

export function markAllAsDirty (form: UntypedFormGroup ) {
    for ( const control of Object.keys(form.controls) ) {
        form.controls[control].markAsDirty();
    }
}

export function toFormData<T> (formValue: T ) {
    const formData = new FormData();
    for ( const key of Object.keys(formValue) ) {
        const value = formValue[key];
        formData.append(key, value);
    }

    return formData;
}

export function toResponseBody<T> () {
    return pipe(
        filter(( event: HttpEvent<T> ) => event.type === HttpEventType.Response),
        map(( res: HttpResponse<T> ) => res.body)
    );
}

@Component({
    selector: 'app-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.scss']
})


export class DocumentsComponent implements OnInit, OnDestroy {
    faTimes = faTimes;
    editForm = false;
    productTest = false;
    progress = 0;
    success = false;
    productID;
    product: ViewProduct = null;
    formError;

    documentForm = new UntypedFormGroup({
        id: new UntypedFormControl(null),
        name: new UntypedFormControl(null, Validators.required),
        document: new UntypedFormControl(null, Validators.required)
    });

    private modalRefService: NgbModalRef;
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    @ViewChild('addDocumentModal', { static: true }) private addDocumentModal;

    constructor(
        private adminProductService: AdminProductService,
        private fb: UntypedFormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private modalService: NgbModal,
        private ref: ChangeDetectorRef,
        private http: HttpClient) { }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.productID = this.route.snapshot.paramMap.get('id');
        this.getProductDetails(this.productID);
    }

    getProductDetails(productID) {
        // We need to retreive the product from the API
        this.adminProductService.getProductExtras(productID).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(product => this.setProduct(product));
    }

    setProduct(product: ViewProduct) {
        this.product = product;
        this.productTest = true;
    }

    evalAPIResponse (response) {
        if (response.status === true) {
            this.progress = 0;
            this.success = true;
            this.documentForm.reset();
            this.formError = null;
            this.modalService.dismissAll();
            this.getProductDetails(this.productID);
        } else {
            this.formError = response.message;
        }

    }

    openDocModal() {
        this.editForm = false;
        this.documentForm.reset();
        this.modalRefService = this.modalService.open(this.addDocumentModal);

    }

    editDocument(documentID) {
        this.documentForm.reset();
        this.editForm = true;
        this.documentForm.controls.id.setValue(documentID);
        this.documentForm.controls.name.setValue(this.product.name);
        this.modalRefService = this.modalService.open(this.addDocumentModal);

    }

    saveNewDocument() {
        const uploadURL = API_URL + '/admin/products/extras/add_document';
        this.documentForm.controls.id.setValue(this.productID);
        this.success = false;
        if ( !this.documentForm.valid ) {
            markAllAsDirty(this.documentForm);
            return;
        }

        this.http.post(uploadURL, toFormData(this.documentForm.value), {
            reportProgress: true,
            observe: 'events',
        }).pipe(
            takeUntil(this.ngUnsubscribe),
            uploadProgress(progress => (this.progress = progress)),
            toResponseBody()
        ).subscribe(res => {
            this.evalAPIResponse(res);
        });
    }

    updateDocument() {
        const
            newName = this.documentForm.controls.name.value,
            documentID = this.documentForm.controls.id.value;
        this.adminProductService.updateDocName(newName, documentID).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(response => this.evalAPIResponse(response));
    }

    removeDocument(documentID) {
        if (confirm('Are you sure to delete this document?')) {
            this.adminProductService.removeDocument(this.productID, documentID).pipe(
                takeUntil(this.ngUnsubscribe))
                .subscribe(response => this.evalAPIResponse(response));
        }
    }

}
