import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './../_guards/index';
import { AdminProductsComponent } from './products/adminProducts.component';
import { CategoriesComponent } from './products/categories/categories.component';
import { AttributesComponent } from './products/attributes/attributes.component';
import { AddProductComponent  } from './products/add/add.component';
import { ManufacturersComponent  } from './products/manufacturers/manufacturers.component';
import { ExtrasComponent  } from './products/extras/extras.component';
import { RelatedComponent  } from './products/extras/related/related.component';
import { MainImageComponent  } from './products/extras/main-image/main-image.component';
import { OtherImagesComponent  } from './products/extras/other-images/other-images.component';
import { DocumentsComponent  } from './products/extras/documents/documents.component';
import { ColourtypesComponent  } from './products/colourtypes/colourtypes.component';
import { WysiwygComponent } from './products/extras/wysiwyg/wysiwyg.component';
import { StockrefsComponent } from './products/extras/other-images/stockrefs/stockrefs.component';
import { BlogComponent } from './blog/blog.component';
import { CreatePostComponent } from './blog/create-post/create-post.component';
import { PagesComponent } from './pages/pages.component';
import { CarriersComponent } from './carriers/carriers.component';
import { OrderStatusComponent } from './orderstatus/orderstatus.component';
const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: 'products',
                component: AdminProductsComponent,
                canActivate: [AuthGuard] ,
                data: {
                    title: 'Products',
                    breadcrumb: 'List'
                }
            },
            {
                path: 'product_categories',
                component: CategoriesComponent,
                canActivate: [AuthGuard] ,
                data: {
                    title: 'Categories',
                    breadcrumb: 'List'
                }
            },
            {
                path: 'product_manufacturers',
                component: ManufacturersComponent,
                canActivate: [AuthGuard] ,
                data: {
                    title: 'Manufacturers',
                    breadcrumb: 'List'
                }
            },
            {
                path: 'product_attributes',
                component: AttributesComponent,
                canActivate: [AuthGuard] ,
                data: {
                    title: 'Attributes',
                    breadcrumb: 'List'
                }
            },
            {
                path: 'product_colour_types',
                component: ColourtypesComponent,
                canActivate: [AuthGuard] ,
                data: {
                    title: 'Colour Types',
                    breadcrumb: 'List'
                }
            },
            {
                path: 'product_add/:id',
                component: AddProductComponent,
                canActivate: [AuthGuard] ,
                data: {
                    title: 'Add Product',
                    breadcrumb: 'Define Details'
                }
            },
            {
                path: 'extras/:id',
                component: ExtrasComponent,
                canActivate: [AuthGuard] ,
                data: {
                    title: 'Product Extras',
                    breadcrumb: 'Product Uploads'
                },
            },
            {
                path: 'extra_main_image/:id',
                component: MainImageComponent,
                canActivate: [AuthGuard] ,
                data: {
                    title: 'Main Product Image',
                    breadcrumb: 'Manage'
                },
            },
            {
                path: 'extra_other_images/:id',
                component: OtherImagesComponent,
                canActivate: [AuthGuard] ,
                data: {
                    title: 'Other Images',
                    breadcrumb: 'E.g colours'
                },
            },
            {
                path: 'extra_documents/:id',
                component: DocumentsComponent,
                canActivate: [AuthGuard] ,
                data: {
                    title: 'Documents',
                    breadcrumb: 'Manage, e.g spec sheets'
                }
            },
            {
                path: 'extra_wysiwyg/:id',
                component: WysiwygComponent,
                canActivate: [AuthGuard] ,
                data: {
                    title: 'WYSIWYG Editor',
                    breadcrumb: 'Additional content'
                }
            },
            {
                path: 'extra_stockrefs/:imageid/:productid',
                component: StockrefsComponent,
                canActivate: [AuthGuard] ,
                data: {
                    title: 'Image Stockrefs',
                    breadcrumb: 'Assign Stockrefs to image'
                }
            },
            {
                path: 'extra_related_products/:productid',
                component: RelatedComponent,
                canActivate: [AuthGuard] ,
                data: {
                    title: 'Related Products',
                    breadcrumb: 'Assign related'
                }
            },
            {
                path: 'list_blog_posts',
                component: BlogComponent,
                canActivate: [AuthGuard] ,
                data: {
                    title: 'List Blog Posts',
                    breadcrumb: 'Additional content'
                }
            },
            {
                path: 'add_blog_post/:slug',
                component: CreatePostComponent,
                canActivate: [AuthGuard] ,
                data: {
                    title: 'Add Blog Item',
                    breadcrumb: 'Manage blog post'
                }
            },
            {
                path: 'pages',
                component: PagesComponent,
                canActivate: [AuthGuard] ,
                data: {
                    title: 'Manage custom content',
                    breadcrumb: 'WYSIWYG'
                }
            },
            {
                path: 'express/carriers',
                component: CarriersComponent,
                canActivate: [AuthGuard] ,
                data: {
                    title: 'Manage Express Carriers',
                    breadcrumb: ''
                }
            },
            {
                path: 'express/status',
                component: OrderStatusComponent,
                canActivate: [AuthGuard] ,
                data: {
                    title: 'Manage Express Order Status',
                    breadcrumb: ''
                }
            }

        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AdminRoutingModule { }
