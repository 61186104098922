<!-- section start -->
<ng-template #postcodeModal let-c="close" class="modal-dialog-centered" let-d="dismiss">
        <div class="modal-header">
          <h4 class="modal-title">Confirm Postcode</h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

        	<form class="form">
                    <div class="form-group" [formGroup]="postcodeForm">
                    <h5>Note: Delivery charges (if required) will be calculated from this postcode,
                    	if an invalid postcode is given, you may be asked to pay more before we
                    	dispatch goods</h5>
                    <label for="name">Delivery Postcode</label>
                    <div class="input-group">
                    <input class="form-control" placeholder="Delivery Postcode"
                        id="postcode"
                        type="text"
                        formControlName="postcode">
                    </div>
                    <br />
                    
                    <!--
                    <div class="form-check">
                        <label for="clearance">Next Day Delivery</label>
                        <input id="express" class="form-check-input" type="checkbox" formControlName="nextDay">
                    </div>

                    
                    <div class="form-check">
                        <label for="clearance">AM Drop</label>
                        <input id="express" class="form-check-input" type="checkbox" formControlName="amDrop">
                    </div>
                -->

                    <br />

                    <button class="btn btn-success" [disabled]="postcodeForm?.invalid" 
                    (click)="calculateViaAPI()">Calculate</button>
                    
                    </div>
            </form>
            

           <button type="button" class="btn btn-default primary-btn danger" 
                  (click)="c('Close click')">Close
            </button>
        
    </div>
</ng-template>

<section class="agency blog blog-sec blog-sidebar sider">
<div class="container">
<div class="row pl-0 pr-0">
<div class="col-lg-8">
<div class="row">
<div class="col-12 blog-sec blog-list" *ngFor="let item of basket">
    <div class="blog-agency">
        <div class="row blog-contain pl-0 pr-0">
            <div class="col-xl-2 col-lg-3 col-md-4 p-0">
                <div class=" text-center">
                    <img [src]="item.colourAWSURL" 
                    	alt="" class="img-fluid basket-img">
                </div>
            </div>
            <div class="col-xl-10 col-lg-9 col-md-8 p-0">
                <div class="img-container">
                    <div class="row">
						<div class="col-xl-10 col-lg-9 col-md-8 ">
                        <div class="blog-info">
                            <h5 class="blog-head font-600">{{item.productName}} <br /> {{item.colourName}}</h5>
                            <p *ngIf="item.qtyWidth <= 1">Pack Size: {{item.packSize}} m2</p>
                            <p>Your Order: {{calculateLineSize(item)}} m2</p>
                            <p> In Stock? 
                            <fa-icon *ngIf="checkStockObj(item) === false;" class="fa-lg redFA" size="lg" [icon]="faTimes"></fa-icon>
                            <fa-icon *ngIf="checkStockObj(item) === true;" class="fa-lg greenFA" size="lg" [icon]="faCheck"></fa-icon>
                            <br /></p>
                            <p *ngIf="checkStockObj(item) === false"> 
                                Stock amount: {{checkStockStatus(item)}}<br />
                                <span class="form-error">We only have part of your order in stock, we will dispatch it
                                    when it is all available, if you wish to have it sooner, please reduce
                                    your order to match our stock levels</span>
                            </p>
                            <div class="basketButtons">
	                            <fa-icon  (click)="oneLessQty(item)" class="basket-icon-modify" 
	                            		  size="2x" [icon]="faMinus"> 		  	
                    		   </fa-icon>
	                            <input class="form-control" type="text" 
					                value="{{item.qtyLength}}" readonly />

					            <fa-icon  (click)="oneMoreQty(item)"  class="basket-icon-modify" 
					            		  size="2x" [icon]="faPlus">
					             </fa-icon>
					        </div>
                        </div>
                        </div>

                        <div class="col-xl-2 col-lg-3 col-md-4 p-0">
                        <div class="blog-info">
                            <h5 class="blog-head font-600">Unit Pirce: £{{item.unitPrice}}</h5>
                            <p> Line value: £{{calculateLineValue(item)}}</p>
                            
                            <div class="btn-bottom m-t-20">
                                <button (click)="removeCartItem(item)" class="btn btn-default primary-btn danger">
                                	Remove
                                </button>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
</div>
	<div class="col-lg-4">            
		<div class="theme-card">

		    Total Ex VAT: 
		    <input class="form-control" type="text" 
		                value="{{totalExVat| currency:'GBP'}}" readonly />
		    <div *ngIf="deliveryChecked === false">
		    Delivery: 
		    <input class="form-control" type="text" 
		                value="TBD" readonly />
		    </div>
		    <div *ngIf="deliveryChecked === true">
		    Delivery: 
		    <input class="form-control" type="text" 
		                value="{{delivery| currency:'GBP'}}" readonly />
		    </div>
		    
		    VAT: 
		    <input class="form-control" type="text" 
		                value="{{totalVat| currency:'GBP'}}" readonly />
		    Total:
		    <input class="form-control" type="text" 
		                value="{{fullTotal| currency:'GBP'}}" readonly />
		    <div class="basket-buttons text-center">
			    <button class="btn btn-default primary-btn success" (click)="getDeliveryQuote()">
			    	Calculate Delivery
			    </button>

                <h3 class="form-error" *ngIf="deliveryError !== null">ERROR!! {{deliveryError}} <br /></h3>

                <h3 class="form-error" *ngIf="deliveryPremium === 0">Order qualifies for free premium delivery <br /></h3>

                <div *ngIf="deliveryPremium >= 0.01">
                    <div *ngIf="deliveryPremium === delivery">
                        <h3 class="form-error">Premium delivery at no extra charge<br /></h3>
                    </div>

                    <div *ngIf="premiumUpgrade === false">
                        <div *ngIf="deliveryPremium !== delivery">
                            <h3 class="form-error">Upgrade to a premium delivery
                                slot for an extra {{deliveryPremium| currency:'GBP'}}<br /></h3>
                                <button class="btn btn-success primary-btn info" 
                                        (click)="upgradeDelivery(deliveryPremium)"
                                        [disabled]="!deliveryChecked">
                                    Upgrade
                                </button>
                        </div>
                    </div>

                    
                
                </div>


                <a [routerLink]="['/express/checkout']">
    			    <button class="btn btn-default primary-btn info" 
    			    	    (click)="proceedCheckout()"
    			    	    [disabled]="!deliveryChecked">
    			    	Checkout
    			    </button>
                </a>
			</div>
		</div> 

		<div class="theme-card">
			<h4>Order over £325* for free delivery to most UK postcodes.</h4>
		</div>

        <div  *ngIf="delivery !== 0" class="theme-card">
            <h4>Please note, delivery is calculated via weight and postcode popularity.
                If your order is over a certain weight (mainly glue/screed) or in an area that is not easily accessible,
                you may not be eligible for free delivery. Underlay also carries a surcharge due to it requiring a pallet 
                over 1.20m</h4>
        </div>


		<div class="theme-card">
			<h4>Free Solent Express hoodie for all orders over £500</h4>
		</div>

        <div class="theme-card">
            <h6>*Some postcodes and heavy products (such as glue/screed) may carry a delivery surcharge</h6>
        </div>
	</div>
</div>
</div>
</section>
<!-- section end -->