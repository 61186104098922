import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ViewProduct } from '../../../_models/index';
import { AdminProductService } from '../../../_services/index';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-extras',
    templateUrl: './extras.component.html',
    styleUrls: ['./extras.component.scss']
})
export class ExtrasComponent implements OnInit, OnDestroy {
    productID = null;
    product: ViewProduct = null;
    productTest = false;
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private adminProductService: AdminProductService,
        private route: ActivatedRoute,
        private router: Router) {
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.productID = this.route.snapshot.paramMap.get('id');
        this.getProductDetails(this.productID);
    }

    getProductDetails(productID) {
        // We need to retreive the product from the API
        this.adminProductService.getProductExtras(productID).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(product => this.setProduct(product));

    }

    setProduct(product) {
        this.product = product;
        this.productTest = true;
    }

    mainImage() {
        // Navigate to main image
        this.router.navigate(['/admin/extra_main_image', this.productID]);
    }

    otherImages() {
        // Navigate to main image
        this.router.navigate(['/admin/extra_other_images', this.productID]);
    }

    navDocuments() {
        this.router.navigate(['/admin/extra_documents', this.productID]);
    }

    updateWysiwyg() {
        this.router.navigate(['/admin/extra_wysiwyg', this.productID]);
    }

    updateRelated() {
        this.router.navigate(['/admin/extra_related_products', this.productID]);
    }

}
