<ng-template #EditCarrier let-c="close" let-d="dismiss">
        <div class="modal-header">
          <h4 class="modal-title">Edit Carrier</h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div *ngIf="formError">
	            <div class="alert alert-danger" role="alert">
	                    <h1>{{formError}}</h1>
	            </div>
			</div> 
            <form class="form">
                    <div class="form-group" [formGroup]="carrierForm">
                    <label for="name">Name</label>
                    <div class="input-group">
                    <input class="form-control" placeholder="Name"
                        id="name"
                        type="text"
                        formControlName="name">
                    </div>
                    <p *ngIf="carrierForm.get(['name']).hasError('required')" 
		                style="color:red;">You must add a value</p>
		            <br />

		            <label for="name">Tracking Prefix URL</label>
                    <div class="input-group">
                    <input class="form-control" placeholder="Tracking Prefix URL"
                        id="prefix"
                        type="text"
                        formControlName="trackingPrefix">
                    </div>
                    <p *ngIf="carrierForm.get(['trackingPrefix']).hasError('required')" 
		                style="color:red;">You must add a value</p>
		            <br />

		            <label for="name">Example Tracking ID</label>
                    <div class="input-group">
                    <input class="form-control" placeholder="Example ID: e.g. 12355A"
                        id="tackingid"
                        type="text"
                        formControlName="trackingExample">
                    </div>
                    <p *ngIf="carrierForm.get(['trackingExample']).hasError('required')" 
		                style="color:red;">You must add a value</p>
		            <br />

		            

                    <button class="btn btn-success" [disabled]="carrierForm?.invalid" 
                    (click)="updateCarrier()">Save</button>

                    
                    </div>
            </form>

        <div class="modal-footer">
          <button type="button" class="btn btn-outline-dark" (click)="c('Close click')">Close</button>
        </div>
    </div>
</ng-template>


<section class="event team-sec speaker set-relative" id="speaker">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-sm-12 speker-container">
                <div class="table-responsive">
    				<table class="table table-striped">
    				  <thead>
    				  <tr>
    				    <th scope="col"># (Edit)</th>
    				    <th scope="col">Name</th>
    				    <th scope="col">Tracking Prefix</th>
    				    <th scope="col">Example</th>
    				  </tr>
    				  </thead>
    				  <tbody>

    				  <tr *ngFor="let carrier of carriers; index as i">
    				    <th scope="row" (click)="editCarrier(carrier)">{{ carrier.id }}</th>

    				    <td>{{ carrier.name}}</td>
    				    <td>{{carrier.trackingPrefix}}  </td>
    				    <td>
    				    	<a href="{{carrier.trackingPrefix}}{{carrier.exampleTrackingId}}" target="_blank">
    				    	{{carrier.exampleTrackingId}}
    				    	</a>
    				    </td>
 
    				  </tr>
    				  </tbody>
    				</table>

    				

                    <br /><br />

                </div>

            </div>

          
        </div>
    </div>
</section>
