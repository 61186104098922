import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ECommerceComponent } from './e-commerce/e-commerce.component';

const routes: Routes = [
    {
        path: 'e-commerce',
        component: ECommerceComponent,
        data: {
            title: 'ECommerce | Unice Landing Page'
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LayoutsRoutingModule { }
