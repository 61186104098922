import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
// Third party imports
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ColorPickerModule } from 'ngx-color-picker';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgxLoadingModule } from 'ngx-loading';
// Local imports
import { LayoutsModule } from '../layouts/layouts.module';
import { AdminRoutingModule } from './admin-routing.module';
import { SharedModule } from '../shared/shared.module';
import { AuthGuard } from './../_guards/index';
import { AuthenticationService,
    JwtHttpInterceptor,
    PageService,
    AdminCategoryService,
    AdminManufacturerService,
    AdminAttributeService,
    AdminProductService,
    AdminBlogService,
    AdminPageService,
    AdminExpressService } from './../_services/index';
import { AdminComponent } from './admin.component';
import { AdminProductsComponent } from './products/adminProducts.component';
import { CategoriesComponent } from './products/categories/categories.component';
import { SingleImageUploadComponent } from './single-image-upload/single-image-upload.component';
import { MultiImageUploadComponent } from './multi-image-upload/multi-image-upload.component';
import { ProgressComponent } from './progress/progress.component';
import { AttributesComponent } from './products/attributes/attributes.component';
import { AddProductComponent } from './products/add/add.component';
import { ManufacturersComponent } from './products/manufacturers/manufacturers.component';
import { ExtrasComponent } from './products/extras/extras.component';
import { MainImageComponent } from './products/extras/main-image/main-image.component';
import { OtherImagesComponent } from './products/extras/other-images/other-images.component';
import { DocumentsComponent } from './products/extras/documents/documents.component';
import { ColourtypesComponent } from './products/colourtypes/colourtypes.component';
import { WysiwygComponent } from './products/extras/wysiwyg/wysiwyg.component';
import { BlogComponent } from './blog/blog.component';
import { CreatePostComponent } from './blog/create-post/create-post.component';
import { PagesComponent } from './pages/pages.component';
import { StockrefsComponent } from './products/extras/other-images/stockrefs/stockrefs.component';
import { CarriersComponent } from './carriers/carriers.component';
import { OrderStatusComponent } from './orderstatus/orderstatus.component';
import { RelatedComponent } from './products/extras/related/related.component';

@NgModule({
    declarations: [
      	AdminComponent,
        AdminProductsComponent,
        CategoriesComponent,
        SingleImageUploadComponent,
        MultiImageUploadComponent,
        ProgressComponent,
        AttributesComponent,
        AddProductComponent,
        ManufacturersComponent,
        ExtrasComponent,
        MainImageComponent,
        OtherImagesComponent,
        DocumentsComponent,
        ColourtypesComponent,
        WysiwygComponent,
        BlogComponent,
        CreatePostComponent,
        PagesComponent,
        StockrefsComponent,
        CarriersComponent,
        OrderStatusComponent,
        RelatedComponent
    ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        AdminRoutingModule,
        LayoutsModule,
        SharedModule,
        NgbModule,
        FontAwesomeModule,
        ColorPickerModule,
        CKEditorModule,
        NgxLoadingModule.forRoot({})
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtHttpInterceptor, multi: true },
        AuthGuard,
        AuthenticationService,
        PageService,
        AdminCategoryService,
        AdminManufacturerService,
        AdminAttributeService,
        AdminProductService,
        AdminBlogService,
        AdminPageService,
        AdminExpressService
    ]
})
export class AdminModule { }
