import { Component, OnInit } from '@angular/core';
import {
    faHeart,
    faShoppingBasket,
    faUsers,
} from '@fortawesome/free-solid-svg-icons';

import { faHtml5 } from '@fortawesome/free-brands-svg-icons';

@Component({
    selector: 'app-ecommerce-product-tab',
    templateUrl: './ecommerce-product-tab.component.html',
    styleUrls: ['./ecommerce-product-tab.component.scss']
})

export class EcommerceProductTabComponent implements OnInit {
    faHtml5 = faHtml5;
    faHeart = faHeart;
    faShoppingBasket = faShoppingBasket;
    faUsers = faUsers;
    constructor () {
    }

    ngOnInit () {
    }


}
