import { Injectable } from '@angular/core';
import { BehaviorSubject} from 'rxjs';

// Menu
export interface Menu {
    path?: string;
    title?: string;
    type?: string;
    icon?: string;
    badgeType?: string;
    badgeValue?: string;
    active?: boolean;
    megaMenu?: boolean;
    megaMenuType?: string; // small, medium, large
    bookmark?: boolean;
    children?: Menu[];
}

@Injectable({
    providedIn: 'root'
})
export class NavService {
    MENUITEMS: Menu[] = [
        { path: '/', title: 'Home', type: 'link' },
        { path: '/products/categories', title: 'Products', type: 'link' },
        { path: '/blog/feed', title: 'News', type: 'link' },
        { path: '/page/about-us', title: 'About', type: 'link' },
        { path: '/page/payments', title: 'Payments', type: 'link' },
        { path: '/page/new-customer', title: 'New Customer', type: 'link' },
        { path: '/page/contact', title: 'Contact', type: 'link' }
        
    ];
  	// Array
    items = new BehaviorSubject<Menu[]>(this.MENUITEMS);

    ADMINMENUITEMS: Menu[] = [
        { title: 'Products', type: 'sub', children: [
            { path: '/admin/product_add/null', title: 'New Product', type: 'link' },
            { path: '/admin/products', title: 'List Products', type: 'link' },
            { path: '/admin/product_categories', title: 'Categories', type: 'link' },
            { path: '/admin/product_manufacturers', title: 'Manufacturers', type: 'link' },
            { path: '/admin/product_attributes', title: 'Attributes', type: 'link' },
            { path: '/admin/product_colour_types', title: 'Colour Types', type: 'link' },

        ]},
        { path: '/admin/list_blog_posts', title: 'Blog', type: 'link' },
        { path: '/admin/pages', title: 'Content', type: 'link' },
        { title: 'Express', type: 'sub', children: [
            { path: '/admin/express/carriers', title: 'Manage Carriers', type: 'link' },
            { path: '/admin/express/status', title: 'Order Status', type: 'link' },

        ]},
    ];
    // Array
    adminItems = new BehaviorSubject<Menu[]>(this.ADMINMENUITEMS);
    constructor () {   }
}
