<section class="event team-sec speaker set-relative" id="speaker">
	<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-sm-12 speker-container">

                <form>
				  <div class="form-group form-inline">
				      Search for a product:  <input class="form-control ml-2" type="text" [formControl]="filter"/>
				  </div>
				  <p> Click on the product name to edit it</p>
				  <br />
				</form>

				<div class="table-responsive">
				<table class="table table-striped">
				  <thead>
				  <tr>
				    <th scope="col">#</th>
				    <th scope="col">Published</th>
				    <th scope="col">Product</th>
				    <th scope="col">Category</th>
				    <th scope="col">Manufacturer</th>
				    <th scope="col">Extras</th>
				    <th scope="col">Clearance</th>
				  </tr>
				  </thead>
				  <tbody>
				  <tr *ngFor="let product of products$ | async; index as i">
				    <th scope="row"><span (click)="editProduct(product.id)">{{ i + 1 }}</span></th>
				    <td *ngIf="product.hidden === true">
				    	<fa-icon (click)="markProductHidden(product.id)" 
			            		class="fa-lg redFA" size="lg" [icon]="faTimes"></fa-icon>
			        </td>

			        <td *ngIf="product.hidden !== true">
				    	<fa-icon (click)="markProductHidden(product.id)" 
			            		class="fa-lg greenFA" size="lg" [icon]="faCheck"></fa-icon>
				    </td>

				    <td>
				      
				      <ngb-highlight (click)="editProduct(product.id)" [result]="product.name" [term]="filter.value"></ngb-highlight>
				    </td>
				    <td><ngb-highlight [result]="product.categoryName" [term]="filter.value"></ngb-highlight></td>
				    <td><ngb-highlight [result]="product.manufacturerName" [term]="filter.value"></ngb-highlight></td>
				    <td><span (click)="manageExtras(product.id)">Update</span></td>
				    <td *ngIf="product.clearance !== true">
				    	<fa-icon (click)="markProductClearance(product.id)" 
			            		class="fa-lg redFA" size="lg" [icon]="faTimes"></fa-icon>
			        </td>
				    <td *ngIf="product.clearance === true">
				    	<fa-icon (click)="markProductClearance(product.id)" 
			            		class="fa-lg greenFA" size="lg" [icon]="faCheck"></fa-icon>
				    </td>
				  </tr>
				  </tbody>
				</table>
				</div>
            </div>

          
        </div>
    </div>
</section>
