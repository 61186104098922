<section class="ecommerce-tab">
    <div class="container">

        <div class="isotopeContainer row">
            <div class="col-lg-6 col-sm-6 isotopeSelector">
                <div class="product-box">

                  <div class="center-content spacebelow">
                        <div class="front">
                              <a href="https://www.portal.solentwholesale.com">
                             <fa-icon  class="blueicon" size="5x" [icon]="faLaptopCode"></fa-icon>
                             </a>
                        </div>

                      </div>

                   
                      <div class="product-detail center-content">
                       
                          <h6>Customer Portal</h6>
                     
                        <p class="displayhome">Check stock/place orders</p>
                      </div>


                </div>
            </div>


            <div class="col-lg-6 col-sm-6 isotopeSelector branding new ">
                <div class="product-box">
                   

                     <div class="center-content spacebelow">
                        <div class="front">
                           <a [routerLink]="['/products/list', 'clearance']">
                             <fa-icon  class="greenicon" size="5x" [icon]="faShoppingBasket"></fa-icon>
                            </a>
                        </div>
                      </div>
                      <div class="product-detail center-content">
                          <h6>Clearance</h6>
                          <p class="displayhome">Discontinued Products</p>
                      </div>


                </div>
            </div>






        </div>
    </div>
</section>