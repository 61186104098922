<ng-template #addAttributeModal let-c="close" let-d="dismiss">
        <div class="modal-header">
          <h4 class="modal-title">Add Attribute</h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div *ngIf="formError">
	            <div class="alert alert-danger" role="alert">
	                    <h1>{{formError}}</h1>
	            </div>
			</div> 
            <form class="form">
                    <div class="form-group" [formGroup]="attributeForm">
                    <label for="name">Name</label>
                    <div class="input-group">
                    <input class="form-control" placeholder="Attribute Name"
                        id="name"
                        type="text"
                        formControlName="name">
                    </div>

                    <br />

                    <button class="btn btn-success" [disabled]="attributeForm?.invalid" 
                    (click)="saveNewAttribute()">Save</button>
                        

                    
                    </div>
            </form>

        <div class="modal-footer">
          <button type="button" class="btn btn-outline-dark" (click)="c('Close click')">Close</button>
        </div>
    </div>
</ng-template>

<ng-template #editAttributeModal let-c="close" let-d="dismiss">
        <div class="modal-header">
          <h4 class="modal-title">Edit Attribute</h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div *ngIf="formError">
                <div class="alert alert-danger" role="alert">
                        <h1>{{formError}}</h1>
                </div>
            </div> 
            <form class="form">
                    <div class="form-group" [formGroup]="attributeForm">
                    <label for="name">Name</label>
                    <div class="input-group">
                    <input class="form-control" placeholder="Attribute Name"
                        id="name"
                        type="text"
                        formControlName="name">
                    </div>


                    <button class="btn btn-success" (click)="updateAttribute(attributeQ[0].id)">
                        Update Attribute
                    </button>
                    
                    </div>
            </form>

        <div class="modal-footer">
          <button type="button" class="btn btn-outline-dark" (click)="c('Close click')">Close</button>
        </div>
    </div>
</ng-template>

<section class="event team-sec speaker set-relative" id="speaker">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-sm-12 speker-container">

                <div class="table-responsive">
    				<table class="table table-striped">
    				  <thead>
    				  <tr>
    				    <th scope="col"># (Edit)</th>
    				    <th scope="col">Name </th>
    				    <th scope="col">Disable</th>
    				  </tr>
    				  </thead>
    				  <tbody>

    				  <tr *ngFor="let attribute of attributes; index as i">
    				    <th scope="row" (click)="editAttribute(attribute.id)">{{ attribute.id }}</th>

    				    <td>{{ attribute.name}}</td>
    				    <td *ngIf="attribute.hidden === true;" (click)="markAttributeHidden(attribute.id)">Enable</td>
                        <td *ngIf="attribute.hidden === false;" (click)="markAttributeHidden(attribute.id)">Disable</td>
    				  </tr>
    				  </tbody>
    				</table>

    				<button class="btn btn-danger" (click)="addAttribute()">
                		Add Attribute
                	</button>
                </div>
            </div>

          
        </div>
    </div>
</section>