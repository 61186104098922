import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BlogRoutingModule } from './blog-routing.module';
import { BlogComponent } from './blog.component';
// New Code
import { LayoutsModule } from '../layouts/layouts.module';
import { BlogHomeComponent } from './blog-home/blog-home.component';
import { BlogItemComponent } from './blog-item/blog-item.component';
// Old Code
import { SidebarComponent } from './widgets/sidebar/sidebar.component';
import { PostsByTagComponent } from './posts-by-tag/posts-by-tag.component';


@NgModule({
    declarations: [
        BlogComponent,
        BlogHomeComponent,
        BlogItemComponent,
        SidebarComponent,
        PostsByTagComponent,
    ],
    imports: [
        CommonModule,
        BlogRoutingModule,
        SharedModule,
        LayoutsModule,
        NgbModule,
    ]
})
export class BlogModule { }
