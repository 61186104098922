<section class="breadcrumb-section-main inner-2 breadcrumb-section-sm">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="breadcrumb-contain ">
                        <div>
                            <h2 class="breadcrumb-txt">{{title}}</h2>
                        </div>
                        <div>
                            <ul>
                                <li><a class="white" href="javascript:void()"></a></li>
                                <li><a class="white" href="javascript:void()"></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</section>