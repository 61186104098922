import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ViewProduct} from '../../../../_models/index';
import { AdminProductService } from '../../../../_services/index';

@Component({
    selector: 'app-wysiwyg',
    templateUrl: './wysiwyg.component.html',
    styleUrls: ['./wysiwyg.component.scss']
})
export class WysiwygComponent implements OnInit, OnDestroy {
    productID;
    product: ViewProduct = null;
    productTest = false;
    formError;
    browserTest = false;
    public wysiwygModel = {
        editorData: ''
    };
    public Editor;

    private ngUnsubscribe: Subject<void> = new Subject<void>();
    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private http: HttpClient,
        private route: ActivatedRoute,
        private router: Router,
        private adminProductService: AdminProductService
    ) { }


    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.productID = this.route.snapshot.paramMap.get('id');
        this.getProductDetails(this.productID);
        if (isPlatformBrowser(this.platformId)) {
            this.loadCkEditor();
        }
    }

    loadCkEditor() {
        const ClassicEditor = require('@ckeditor/ckeditor5-build-classic');
        this.Editor = ClassicEditor;
        this.browserTest = true;
    }

    getProductDetails(productID) {
        // We need to retreive the product from the API
        this.adminProductService.getProductExtras(productID).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(product => this.setProduct(product));

    }

    setProduct(product) {
        this.product = product;
        this.wysiwygModel.editorData = product.wysiwyg;
        this.productTest = true;
    }

    evalAPIResponse (response) {
        if (response.status === true) {
            this.formError = null;
            this.router.navigate(['/admin/extras/', this.productID]);
        } else {
            this.formError = response.message;
        }

    }

    updateWysiwyg() {
        this.adminProductService.updateProductWYSIWYG(this.wysiwygModel.editorData, this.productID).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(result => this.evalAPIResponse(result));
    }
}
