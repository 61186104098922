<section class="event team-sec speaker set-relative" id="speaker">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-sm-12 speker-container">
                <div class="table-responsive">
    				<table class="table table-striped">
    				  <thead>
    				  <tr>
    				    <th scope="col"># (Edit)</th>
    				    <th scope="col">Name </th>
    				    <th scope="col">Pub Date</th>
    				    <th scope="col">Author</th>
    				    <th scope="col">Published</th>
    				  </tr>
    				  </thead>
    				  <tbody>

    				  <tr *ngFor="let post of posts; index as i">
    				    <th scope="row" (click)="addNewPost(post.slug)">{{ post.id }}</th>

    				    <td>{{ post.title }} </td>
    				    <td>{{ post.pubDate }} </td>
    				    <td>{{ post.authorName }} </td>
    				    <td *ngIf="post.published === true;">
    				    	<fa-icon (click)="publishPost(post.id)" 
			            		class="fa-lg greenFA" size="lg" [icon]="faCheck"></fa-icon>    				    	
    				    </td>
                        <td *ngIf="post.published === false;">
                        	<fa-icon (click)="publishPost(post.id)" 
			            		class="fa-lg redFA" size="lg" [icon]="faTimes"></fa-icon>    
                        </td>
                        
    				  </tr>
    				  </tbody>
    				</table>

    				<button class="btn btn-danger" (click)="addNewPost(null)">
                		Add Post
                	</button>

                    <br /><br />
                </div>

            </div>

          
        </div>
    </div>
</section>
