<ng-template #addDocumentModal let-c="close" let-d="dismiss">
        <div class="modal-header">
          <h4 class="modal-title">Add Document</h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div *ngIf="formError">
	            <div class="alert alert-danger" role="alert">
	                    <h1>{{formError}}</h1>
	            </div>
			</div> 
            <form class="form">
                    <div class="form-group" [formGroup]="documentForm">
                    <label for="name">Name</label>
                    <div class="input-group">
                    <input class="form-control" placeholder="Name"
                        id="name"
                        type="text"
                        formControlName="name">
                    </div>
                    <p *ngIf="documentForm.get(['name']).hasError('required')" 
		                style="color:red;">You must add a value</p>
		            <br />

		            <div *ngIf="editForm === false">
		            <app-single-image-upload formControlName="document" [progress]="progress"></app-single-image-upload>     

                    <button class="btn btn-success" [disabled]="documentForm?.invalid" 
                    (click)="saveNewDocument()">Save</button>
	                </div>

	                <div *ngIf="editForm === true">
                    <button class="btn btn-success"
                    (click)="updateDocument()">Save</button>
	                </div>
                    
                    </div>
            </form>

        <div class="modal-footer">
          <button type="button" class="btn btn-outline-dark" (click)="c('Close click')">Close</button>
        </div>
    </div>
</ng-template>


<section class="event team-sec speaker set-relative" id="speaker">
    <div *ngIf="productTest === true" class="container">
        <div class="row">
            <div class="col-md-12 col-sm-12 speker-container">
                <div class="bluetext"> 
                    <a [routerLink]="['/admin/extras/', productID]"><h3>Back To Product Admin</h3></a><br />
                </div>

                <div class="table-responsive">
    				<table class="table table-striped">
    				  <thead>
    				  <tr>
    				    <th scope="col"># (Edit)</th>
    				    <th scope="col">Name</th>
    				    <th scope="col">File</th>
    				    <th scope="col">Remove</th>
    				  </tr>
    				  </thead>
    				  <tbody>

    				  <tr *ngFor="let doc of product.documents; index as i">
    				    <th scope="row" (click)="editDocument(doc.id)">{{ doc.id }}</th>

    				    <td>{{ doc.name}}</td>
    				    <td><a href="{{doc.awsURL}}" target="_blank">View Document</a></td>
    				    <td><fa-icon (click)="removeDocument(doc.id)" 
			            		class="fa-lg redFA" size="lg" [icon]="faTimes"></fa-icon>  </td>
    				    
    				  </tr>
    				  </tbody>
    				</table>

    				<button class="btn btn-danger" (click)="openDocModal()">
                		Add new
                	</button>

                    <br /><br />

                </div>

            </div>

          
        </div>
    </div>
</section>
