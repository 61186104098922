import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import * as fromRoot from '../../_ngrx/index';
import * as myActions from '../../_ngrx/actions';
import {Store} from '@ngrx/store';
import { AuthenticationService, SolentExpressService } from '../../_services/';
import { CartItem, CartTotal, CustomerAddress, GenericResponse } from '../../_models/';
import { UntypedFormControl, UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject} from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { DOCUMENT } from '@angular/common';
@Component({
    selector: 'app-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit, OnDestroy {
    basket: CartItem[];
    cartTotal: CartTotal;
    formError;
    orderID = null;
    deliveryAddress: CustomerAddress;
    basket$: Observable<CartItem[]> = this.store.select(fromRoot.selectBasket);
    total$: Observable<CartTotal> = this.store.select(fromRoot.selectTotals);
    deliveryAddress$: Observable<CustomerAddress> = this.store.select(fromRoot.selectDelivery);
    submitOrderTest = false;
    // Build payment form
    /*
        cardNumber: new FormControl(null, [Validators.required, Validators.minLength(19)]),
        cardExpiryMM: new FormControl(null, [Validators.required, Validators.minLength(2)]),
        cardExpiryYY: new FormControl(null, [Validators.required, Validators.minLength(2)]),
        cardCVC: new FormControl(null, [Validators.required, Validators.minLength(3)]),
    */
    paymentForm = new UntypedFormGroup({
        delName: new UntypedFormControl(null, [Validators.required]),
        delPhone: new UntypedFormControl(null, [Validators.required]),
        delAddressOne: new UntypedFormControl(null, [Validators.required]),
        delTown: new UntypedFormControl(null, [Validators.required]),
        delCounty: new UntypedFormControl(null, [Validators.required]),
        delPosteCodeRO: new UntypedFormControl(null, [Validators.required]),
        delComments: new UntypedFormControl(null),
        orderReference: new UntypedFormControl(null),
        invName: new UntypedFormControl(null, [Validators.required]),
        invAddressOne: new UntypedFormControl(null, [Validators.required]),
        invTown: new UntypedFormControl(null, [Validators.required]),
        invCounty: new UntypedFormControl(null, [Validators.required]),
        invPostCode: new UntypedFormControl(null, [Validators.required]),
        customerEmail: new UntypedFormControl(null, [Validators.required, Validators.email]),
    });
    public loading = false;
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    private modalRefService: NgbModalRef;

    constructor(
        private http: HttpClient,
        private store: Store<fromRoot.State>,
        private authService: AuthenticationService,
        private expressService: SolentExpressService,
        private fb: UntypedFormBuilder,
        private router: Router,
        private sanitizer: DomSanitizer,
        private modalService: NgbModal,
        @Inject(PLATFORM_ID) private platformId: any,
        @Inject(DOCUMENT) private document: Document) { }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit(): void {
        this.basket$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(basket => this.basket = basket);
        this.deliveryAddress$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(address => this.populateDelivery(address));
        this.total$.subscribe(total => {
            this.cartTotal = total;
            if (total.deliveryCheck === false) {
                // after testing implent router
                this.router.navigate(['/express/basket']);
            }
        });
        this.expressService.getInvAddress(this.authService.returnCustomerNo()).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(address => {
                this.populateInvoiceAdddress(address);
            });
    }

    populateInvoiceAdddress(address: CustomerAddress) {
        /* Query the backend API and populate the form with the default
           invoice address for the customer, we allow this to be over-ridden
           so that it can be changed to allow the use of payments by cards
           that are registered to a different address.
        */
        this.paymentForm.controls.invName.setValue(address.orderdelivL1);
        this.paymentForm.controls.invAddressOne.setValue(address.orderdelivL2);
        this.paymentForm.controls.invTown.setValue(address.orderdelivL3);
        this.paymentForm.controls.invCounty.setValue(address.orderdelivL4);
        this.paymentForm.controls.invPostCode.setValue(address.orderdelivL5);
        // extract email and remove any whitespace to ensure form validates
        const rawEmail = address.customerEmail.toString();
        this.paymentForm.controls.customerEmail.setValue(rawEmail.replace(/\s/g, ''));
    }

    populateDelivery(address: CustomerAddress) {
        this.deliveryAddress = address;
        // Set values in form
        if (address !== null) {
            this.paymentForm.controls.delName.setValue(address.orderdelivL1);
            this.paymentForm.controls.delPhone.setValue(address.siteContactNo);
            this.paymentForm.controls.delAddressOne.setValue(address.orderdelivL2);
            this.paymentForm.controls.delTown.setValue(address.orderdelivL3);
            this.paymentForm.controls.delCounty.setValue(address.orderdelivL4);
            this.paymentForm.controls.delPosteCodeRO.setValue(address.orderdelivL5);
        }
    }

    submitOrder() {
        this.formError = null;
        // Once the payment has been processed, we need to now save the order
        // in the backend, then return the user to their account page

        // Get delivery address from form
        const deliveryAddress: CustomerAddress = {
            siteContactNo: this.paymentForm.controls.delPhone.value,
            orderdelivL1: this.paymentForm.controls.delName.value,
            orderdelivL2: this.paymentForm.controls.delAddressOne.value,
            orderdelivL3: this.paymentForm.controls.delTown.value,
            orderdelivL4: this.paymentForm.controls.delCounty.value,
            orderdelivL5: this.paymentForm.controls.delPosteCodeRO.value,
            orderdelivL6: '',
        };

        const orderlines: CartItem[] = this.basket;
        const totals: CartTotal = this.cartTotal; // includes delivery consignments
        const deliveryComments: string = this.paymentForm.controls.delComments.value;
        const orderReference: string = this.paymentForm.controls.orderReference.value;
        const accountno: string = this.authService.returnCustomerNo();
        const userid: string = this.authService.returnUserID();

        this.expressService.submitNewOrder(
            deliveryAddress, orderlines, totals, deliveryComments,
            orderReference, accountno, userid)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(response => this.evalOrderSubmit(response));
    }

    evalOrderSubmit(response: GenericResponse) {
        if (response.status === true) {
            this.submitOrderTest = true;
            this.orderID = response.message;
            // order submitted, now we need to take payment
            this.buildPayment();
        } else {
            this.formError = 'Error processing';
        }
    }

    buildPayment() {
        // First we build the payment, so it can be called on its own
        // if the order has been submitted
        const cardAddress = this.generateAddressString();
        const cardPostcode = this.paymentForm.controls.invPostCode.value;
        const custEmail = this.paymentForm.controls.customerEmail.value;
        const amount = Math.round(Number(this.cartTotal.total) * 100);
        const transRef = this.orderID.toString();

        this.makePayment(
            amount, // amount
            cardAddress, // line1 address
            cardPostcode, // card invPostCode
            transRef, // orderID in the backend
            custEmail
        );

    }

    generateAddressString() {
        const addrString =
        this.paymentForm.controls.invAddressOne.value + ', ' +
        this.paymentForm.controls.invTown.value + ', ' +
        this.paymentForm.controls.invCounty.value;
        return addrString;
    }

    makePayment(
        paymentAmount: number,
        addressL1: string,
        PostCode: string,
        customerOrderRef: string,
        email: string
    ) {
        this.loading = true;
        const paymentObject = this.expressService.buildHostedExpressPaymentObject(
            paymentAmount, addressL1, PostCode, customerOrderRef,
            email, this.orderID);
        // Now we need to sign the object
        this.expressService.generateAPISignature(paymentObject).subscribe(
            signature => this.processPayment(signature, paymentObject));
    }


    processPayment(signature, paymentObject: any) {
        if (isPlatformBrowser(this.platformId)) {
            const form = this.document.createElement('form');
            form.setAttribute('method', 'post');
            form.setAttribute('action', 'https://payment.card-saver.co.uk/paymentform/');
            // use _self to redirect in same tab, _blank to open in new tab
            // form.setAttribute('target', '_blank');
            form.setAttribute('target', '_self');
            // Add all the data to be posted as Hidden elements
            console.log(paymentObject.callbackURL);
            form.appendChild(this.createHiddenElement('action', paymentObject.action));
            form.appendChild(this.createHiddenElement('amount', paymentObject.amount));
            form.appendChild(this.createHiddenElement('redirectURL', paymentObject.redirectURL));
            form.appendChild(this.createHiddenElement('customerAddress', paymentObject.customerAddress));
            form.appendChild(this.createHiddenElement('customerEmail', paymentObject.customerEmail));
            form.appendChild(this.createHiddenElement('customerPostCode', paymentObject.customerPostCode));
            form.appendChild(this.createHiddenElement('countryCode', paymentObject.countryCode));
            form.appendChild(this.createHiddenElement('currencyCode', paymentObject.currencyCode));
            form.appendChild(this.createHiddenElement('formResponsive', paymentObject.formResponsive));
            form.appendChild(this.createHiddenElement('merchantName', paymentObject.merchantName));
            form.appendChild(this.createHiddenElement('merchantData', paymentObject.merchantData));
            form.appendChild(this.createHiddenElement('merchantWebsite', paymentObject.merchantWebsite));
            form.appendChild(this.createHiddenElement('merchantID', paymentObject.merchantID));
            form.appendChild(this.createHiddenElement('orderRef', paymentObject.orderRef));
            form.appendChild(this.createHiddenElement('type', paymentObject.type));
            form.appendChild(this.createHiddenElement('signature', signature));

            this.document.body.appendChild(form);
            form.submit();
        }
    }


    private createHiddenElement(name: string, value: string): HTMLInputElement {
        if (isPlatformBrowser(this.platformId)) {
            const hiddenField = this.document.createElement('input');
            hiddenField.setAttribute('name', name);
            hiddenField.setAttribute('value', value);
            hiddenField.setAttribute('type', 'hidden');
            return hiddenField;
        }
    }



}
