<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<section class="section-b-space">
  <div class="container padding-cls">
      <div class="checkout-page">
          <div class="checkout-form">
              <div class="form-group" [formGroup]="paymentForm">
                  <div class="row">
                      <div class="col-lg-6 col-sm-12 col-xs-12">

                        <div class="checkout-title">
                              <h3>Delivery Address</h3>
                          </div>

                          <div class="row check-out">
                              <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                  <div class="field-label">Delivery Name</div>
                                  <input type="text" name="delName" placeholder="" formControlName="delName">
                                  <p *ngIf="paymentForm.get(['delName']).hasError('required')" 
                                    class="form-warning">Value required</p>
    
                              </div>

                              <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                  <div class="field-label">Site Phone Number</div>
                                  <input type="text" name="delPhone"  placeholder="" formControlName="delPhone">
                                  <p *ngIf="paymentForm.get(['delPhone']).hasError('required')" 
                                    class="form-warning">Value required</p>

                              </div>

                              <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                  <div class="field-label">Address Line 1</div>
                                  <input type="text" name="delAddressOne" placeholder="" formControlName="delAddressOne">
                                  <p *ngIf="paymentForm.get(['delAddressOne']).hasError('required')" 
                                    class="form-warning">Value required</p>

                              </div>
                              <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                  <div class="field-label">Town/City</div>
                                  <input type="text" name="delTown" placeholder="" formControlName="delTown">
                                  <p *ngIf="paymentForm.get(['delTown']).hasError('required')" 
                                    class="form-warning">Value required</p>
        
                              </div>
                              <div class="form-group col-md-12 col-sm-6 col-xs-12">
                                  <div class="field-label">County</div>
                                  <input type="text" name="delCounty"  placeholder="" formControlName="delCounty">
                                  <p *ngIf="paymentForm.get(['delCounty']).hasError('required')" 
                                    class="form-warning">Value required</p>

                              </div>
                              <div class="form-group col-md-12 col-sm-6 col-xs-12">
                                  <div class="field-label">Postal Code</div>
                                  <input type="text" readonly name="delPosteCodeRO" formControlName="delPosteCodeRO">

                                  <a [routerLink]="['/express/basket']" 
                                 class="btn btn-default primary-btn radius-0 change-post">Change Post Code</a>
                              </div>
                              
                        </div>

                          <div class="checkout-title">
                              <h3>Order Information</h3>
                          </div>
                          <div class="row check-out">
                               <div class="form-group col-md-12 col-sm-6 col-xs-12">
                                  <div class="field-label">Delivery Comments</div>
                                  <input type="text" name="delComments"  placeholder="E.g. Leave on driveway" 
                                  formControlName="delComments">
                              </div>

                              <div class="form-group col-md-12 col-sm-6 col-xs-12">
                                  <div class="field-label">Order Reference</div>
                                  <input type="text" name="orderReference"  placeholder="E.g. Mrs Smith" 
                                  formControlName="orderReference">
                              </div>
                          </div>


                          <div class="checkout-title">
                              <h3>Billing Address</h3>
                          </div>
                          <div class="row check-out">
                              <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                  <div class="field-label">Name</div>
                                  <input type="text" name="invName" placeholder="" formControlName="invName">
                                  <p *ngIf="paymentForm.get(['invName']).hasError('required')" 
                                    class="form-warning">Value required</p>
    
                              </div>

                              <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                  <div class="field-label">Address Line 1</div>
                                  <input type="text" name="invAddressOne" placeholder="" formControlName="invAddressOne">
                                  <p *ngIf="paymentForm.get(['invAddressOne']).hasError('required')" 
                                    class="form-warning">Value required</p>

                              </div>
                              <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                  <div class="field-label">Town/City</div>
                                  <input type="text" name="invTown" placeholder="" formControlName="invTown">
                                  <p *ngIf="paymentForm.get(['invTown']).hasError('required')" 
                                    class="form-warning">Value required</p>
        
                              </div>
                              <div class="form-group col-md-12 col-sm-6 col-xs-12">
                                  <div class="field-label">County</div>
                                  <input type="text" name="invCounty" placeholder="" formControlName="invCounty">
                                  <p *ngIf="paymentForm.get(['invCounty']).hasError('required')" 
                                    class="form-warning">Value required</p>

                              </div>
                              <div class="form-group col-md-12 col-sm-6 col-xs-12">
                                  <div class="field-label">Postal Code</div>
                                  <input type="text" name="invPostCode" placeholder="" formControlName="invPostCode">
                                  <p *ngIf="paymentForm.get(['invPostCode']).hasError('required')" 
                                    class="form-warning">Value required</p>
                                  
                              </div>

                              <div class="form-group col-md-12 col-sm-6 col-xs-12">
                                  <div class="field-label">Email</div>
                                  <input type="email" name="invPostCode" placeholder="" formControlName="customerEmail">
                                  <p *ngIf="paymentForm.get(['customerEmail']).hasError('required')" 
                                    class="form-warning">Value required</p>

                                    <p *ngIf="paymentForm.get(['customerEmail']).hasError('email')" 
                                    class="form-warning">Must be a valid email</p>
                                  
                              </div>

                          </div>

                       



                        
                      </div>

                      <div class="col-lg-6 col-sm-12 col-xs-12">

                          <div class="checkout-details">
                              <div class="order-box">
                                  <div class="title-box">
                                      <div>Product <span> Total</span></div>
                                  </div>
                                  <ul class="qty">
                                      <li *ngFor="let item of basket;">
                                         {{item.colourName}} <br />
                                         {{item.packSize}}m2
                                        <span>
                                         {{item.qtyLength}}
                                          </span>
                                      </li>
                                  </ul>
                                  <ul *ngIf="!basket.length" class="qty">
                                   <li class="empty-checkout"> There are no products in cart </li>
                                  </ul>
                                  <ul class="sub-total">
                                      <li>
                                        Total Ex VAT <span class="count">{{cartTotal.totalExVat| currency:'GBP'}}</span>
                                      </li>
                                      <li>
                                        Delivery <span class="count">{{cartTotal.delivery| currency:'GBP'}}</span>
                                      </li>
                                  </ul>
                                  <ul class="total">
                                      <li>Total Inc VAT<span class="count">{{cartTotal.total| currency:'GBP'}}</span></li>
                                  </ul>

                                  <a class="btn btn-default primary-btn radius-0">Not Sure? Save For Later</a>
                              </div>

                          </div>
                          <br />

                          <div class="stripe-section">

                            
                            <div class="row ">

                              <div class="form-group col-md-12 col-sm-6 col-xs-12">

                                    <div *ngIf="submitOrderTest === false;">
                                     <button (click)="submitOrder()" [disabled]="paymentForm?.invalid"  class="btn btn-default primary-btn radius-0">Submit</button>
                                   </div>
 
                                   <div *ngIf="submitOrderTest === true;">
                                     <button (click)="buildPayment()" [disabled]="paymentForm?.invalid"  class="btn btn-default primary-btn radius-0">Submit</button>
                                   </div>
                                   <div *ngIf="formError">
                                      <h1 class="form-warning">{{formError}}</h1>
                                 </div>
                              </div>


                            </div>
                        

                          </div>


                      </div>
                  </div>

              </div>
          </div>
      </div>
  </div>
</section>