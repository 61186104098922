import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GenericResponse, CustomPage } from '../_models/index';
import { API_URL, } from '../app.const';

@Injectable()
export class AdminPageService {
    private addNewPageURL = API_URL + '/admin/pages/add_new';
    private updatePageURL = API_URL + '/admin/pages/update';
    private publishPageURL = API_URL + '/admin/pages/publish';
    private getallPagesURL = API_URL + '/admin/pages/list_all';

    constructor (private http: HttpClient) {}

    addNewPage(page: CustomPage) {
        return this.http.post<GenericResponse>(this.addNewPageURL,
            JSON.stringify({ page }));
    }

    updatePage(page: CustomPage) {
        return this.http.post<GenericResponse>(this.updatePageURL,
            JSON.stringify({ page }));
    }

    publishPage(pageID) {
        return this.http.post<GenericResponse>(this.publishPageURL,
            JSON.stringify({ pageID }));
    }

    getAllPages() {
        return this.http.get<CustomPage[]>(this.getallPagesURL);
    }




}
