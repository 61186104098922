<!-- Login section -->
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<section class="login-page section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                
                
                <div class="theme-card">
                	<h3 class="title-font">Solent Express</h3>

                    <form class="theme-form" name="form" [formGroup]="userLoginForm">
                        <div class="form-group">
                            <label for="email">Email</label>
                            <input type="text" class="form-control" autocomplete="off" 
                                   placeholder="Email" formControlName="username" 
                                   name="username"  required>
                        </div>
                        <div class="form-group">
                            <label for="review">Password</label>
                            <input type="password" class="form-control" autocomplete="off" 
                                   placeholder="Enter your password"
                                   required name="password" formControlName="password" >
                        </div>
                        <h4 class="redtext" *ngIf="formError">** {{formError}} **</h4>
                        <button (click)="login()" class="btn primary-btn btn-default radius-0">
                            Login
                        </button>

                        <a [routerLink]="['/page/new-customer']" class="btn primary-btn btn-default radius-0">
                            Register
                        </a>
                    </form>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="theme-card authentication-right">
                    <h3 class="title-font">In the right place?</h3>
                    <p>This page is a login for Solent Express service, our nationwide service to deliver
                    	goods (via third party carrier). If you are looking for the standard 
                    online order portal for Solent Wholesale, please visit the link below.</p>
                    	<br /><br /><br />
                    <a href="https://www.portal.solentwholesale.com" class="btn primary-btn btn-default radius-0">
                           Customer Portal
                    </a>
                    </div>
            </div>
        </div>
    </div>
</section>

<!-- end Login section -->

