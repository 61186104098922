<ng-template #addStockrefModal let-c="close" let-d="dismiss">
        <div class="modal-header">
          <h4 class="modal-title">Add Stock Reference</h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div *ngIf="formError">
	            <div class="alert alert-danger" role="alert">
	                    <h1>{{formError}}</h1>
	            </div>
			</div> 

            <h3>{{productInfo.productName}}</h3><br />
            <h3>{{productInfo.colourName}}</h3><br />
            <form class="form">
                    <div class="form-group" [formGroup]="stockrefForm">
                    
                    <div>
                        <label>Stock Reference</label>
                        <div class="input-group">
                            <input class="form-control" placeholder="Stockref"
                                id="stockref"
                                type="text"
                                formControlName="stockref">
                        </div>
                         <br />
                         <p *ngIf="stockrefForm.get(['stockref']).hasError('required')" 
                            style="color:red;">You must add a value</p>
                    </div>

                    <div>
                        <label>Width</label>
                        <div class="input-group">
                            <input class="form-control" placeholder="Put 1, unless carpet"
                                id="width"
                                type="number"
                                formControlName="width">
                        </div>
                         <br />
                         <p *ngIf="stockrefForm.get(['width']).hasError('required')" 
                            style="color:red;">You must add a value</p>
                    </div>

                    <div>
                        <label>Pack Size</label>
                        <div class="input-group">
                            <input class="form-control" placeholder="Pack Size, e.g. 3.34 for LVT"
                                id="packsize"
                                type="number"
                                formControlName="packSize">
                        </div>
                         <br />
                         <p *ngIf="stockrefForm.get(['packSize']).hasError('required')" 
                            style="color:red;">You must add a value</p>
                    </div>

                    <div>
                        <label>Pallet Length</label>
                        <div class="input-group">
                            <input class="form-control" placeholder="Max Length on the pallet"
                                id="palletlength"
                                type="number"
                                formControlName="palletLength">
                        </div>
                         <br />
                         <p *ngIf="stockrefForm.get(['palletLength']).hasError('required')" 
                            style="color:red;">You must add a value</p>
                    </div>

                    <div class="form-check">
                        <label for="clearance">Small Item</label>
                        <input id="smallItem" class="form-check-input" type="checkbox" formControlName="smallItem">
                    </div>

                    <div *ngIf="editForm === true">
                        <label>Free Text</label>
                        <div class="input-group">
                            <input class="form-control" placeholder="Custom Stock Description"
                                id="palletlength"
                                type="text"
                                formControlName="freeText">
                        </div>
                         <br />
                         <p *ngIf="stockrefForm.get(['freeText']).hasError('required')" 
                            style="color:red;">You must add a value</p>
                    </div>
                    

		            
                    <button class="btn btn-success" [disabled]="stockrefForm?.invalid" 
                    (click)="saveStockRef()">Save</button>
	                </div>

                    
                    
            </form>

        <div class="modal-footer">
          <button type="button" class="btn btn-outline-dark" (click)="c('Close click')">Close</button>
        </div>
    </div>
</ng-template>

<section class="event team-sec speaker set-relative" id="speaker">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-sm-12 speker-container">
                <div *ngIf="productInfo?.productID" class="bluetext"> 
                    <a [routerLink]="['/admin/extra_other_images/', productInfo.productID]"><h3>{{productInfo.productName}}</h3></a><br />
                </div>
                <h3 *ngIf="productInfo?.colourName" >{{productInfo.colourName}}</h3><br />
                <div class="table-responsive">
    				<table class="table table-striped">
    				  <thead>
    				  <tr>
    				    <th scope="col"># (Edit)</th>
    				    <th scope="col">Stockref</th>
    				    <th scope="col">Product</th>
    				    <th scope="col">Width</th>
    				    <th scope="col">PackSize</th>
    				    <th scope="col">Realitex</th>
    				    <th scope="col">Delete</th>
    				  </tr>
    				  </thead>
    				  <tbody *ngIf="stockrefs">

    				  <tr *ngFor="let stkref of stockrefs; index as i">
    				    <th scope="row" (click)="editStockref(stkref)">{{ stkref.id }}</th>

    				    <td>{{ stkref.stockref}}</td>
    				    <td>{{ stkref.productName}}</td>
    				    <td>{{ stkref.width}}</td>
    				    <td>{{ stkref.packSize}}</td>
    				    <td>{{ stkref.freeText}}</td>
    				    <td><fa-icon (click)="removeStockref(stkref.id)" 
			            		class="fa-lg redFA" size="lg" [icon]="faTimes"></fa-icon>  </td>
    				    
    				  </tr>
    				  </tbody>
    				</table>

    				<button class="btn btn-danger" (click)="openStockrefModal()">
                		Add new
                	</button>

                    <br /><br />

                </div>

            </div>

          
        </div>
    </div>
</section>