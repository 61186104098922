import { Component, OnInit  } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Products } from 'src/app/shared/model/product.model';
import { PageService } from 'src/app/_services';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-solent-selection',
  templateUrl: './solent-selection.component.html',
  styleUrls: ['./solent-selection.component.scss']
})
export class SolentSelectionComponent implements OnInit{
    products: Products[] = [];
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    constructor (
        private title: Title,
        private solentProductService: PageService) { }

    ngOnInit () {
        this.title.setTitle('Solent Wholesale | Solent Selection');
        this.getSSProducts();
    }

    getSSProducts() {
        this.solentProductService.getSSProducts()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(ssProducts => {
                this.products = ssProducts;  // all products
            });
    }

    imageCheck(image) {
        if (image === null ) {
            return 'https://swc-website.s3-eu-west-1.amazonaws.com/static_images/no_photo.jpg';
        } else {
            return image;
        }
    }



}

