
<section class="register-page section-b-space">
  <div class="container">
      <div class="row">
          <div class="col-lg-6">

              <div class="theme-card">
              	<form class="theme-form">
                    <div class="form-group" [formGroup]="screedForm">
                      <div class="form-row">
                          <div class="col-md-12">
                            <h3>Screed / Latex</h3>

                              <p> Calculate how much screed you need, based on the square meterage you need to cover
	                         and at what thickness</p>
                              <br />
                          </div>
                      </div>

                      <div class="form-row">
                          <div class="col-md-12">
                              <label for="bagsize">Bag Size</label>
                              <select class="form-control"
				                    formControlName="bagSize">
				            <option disabled selected value> --Select-- </option>
				            <option *ngFor="let size of sizes" [ngValue]="size.value" >{{size.name}}kg</option>
				            </select> <br />
                          </div>
                      </div>

                      <div class="form-row">
                          <div class="col-md-6">
                              <label for="sqm">SQM</label>
                              <input type="number" class="form-control whiteform" id="sqm"
                                      formControlName="sqm"
                                      placeholder="Square Meterage">
                          </div>
                          <div class="col-md-6">
                              
                            <label for="thickness">Thickness (mm)</label>
                              <input type="number" class="form-control whiteform" id="thickness"
                                      formControlName="thickness"
                                      placeholder="Thickness (mm)">
                          </div>
                          
                      </div>

                      <div class="form-row">
                          <div class="col-md-12">
                              <label for="units">Units Required</label>
                              <input type="text" readonly class="form-control"
                              		  [value]="unitsReq | number : '1.2-2'"	
                                      placeholder="Fill form in to calculate">
                          </div>                          
                      </div>  

                      
                      

                  </div>
                </form>
              </div>
          </div>


          <div class="col-lg-6">
            <div class="theme-card">
            	<form class="theme-form">
                    <div class="form-group" [formGroup]="plywoodForm">
                    	<div class="form-row">
                          <div class="col-md-12">

				            <h3>Plywood / Hardboard </h3>
				              <p>Calculate how many sheets of plywood you need to cover a certain area</p> 

			              	<br />
			          		</div>
			          	</div>

			          	<div class="form-row">
                          <div class="col-md-12">
                              <label for="sqm">SQM</label>
                              <input type="number" class="form-control whiteform" id="sqm"
                                      formControlName="sqm"
                                      placeholder="Square Meterage">
                          </div>                          
                      </div>



			          	<div class="form-row">
                          <div class="col-md-12">
                              <label for="email">Large Sheets 8x4</label>
                              <input type="text" readonly class="form-control"
                              		  [value]="largeSheetsReq | number : '1.2-2'"	
                                      placeholder="Fill form in to calculate">
                          </div>                          
                      </div>  

                      <div class="form-row">
                          <div class="col-md-12">
                              <label for="email">Small Sheets 4x2</label>
                              <input type="text" readonly class="form-control"
                              		  [value]="smallSheetsReq | number : '1.2-2'"	
                                      placeholder="Fill form in to calculate">
                          </div>                          
                      </div>  


              		</div>
              	</form>
            </div>


          </div>


      </div>
  </div>
</section>

