<section class="event team-sec speaker set-relative" id="speaker">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 speker-container">

            <div *ngIf="formError">
                <div class="alert alert-danger" role="alert">
                        <h1>{{formError}}</h1>
                </div>
            </div> 

            <form class="theme-form">
                    <div class="form-group"  [formGroup]="blogForm">
                      <div class="form-row">
                          <div class="col-md-12">
                            <h3>New Post</h3>
                          </div>
                      </div>

                      <div class="form-row">
                          <div class="col-md-12">
                              <label for="email">Article Title</label>
                              <input type="text" class="form-control" id="name"
                                      formControlName="name"
                                      placeholder="E.g. New Product from Balta...">
                          </div>
                      </div>
                      <div class="form-row">
                          <div class="col-md-12">
                              <label for="email">Tags (seperate with a comma)</label>
                              <input type="text" class="form-control" id="tags"
                                      formControlName="tags"
                                      placeholder="Tags, e.g. 'New Product, cormar'">
                          </div>
                      </div>
                      
                      <!-- if editing a form -->
                      <div *ngIf="blogPostQ">

                        <div *ngIf="blogPostQ.awsURL === null" class="form-row">
                          <div class="col-md-12">
                               <app-single-image-upload formControlName="image" [progress]="progress">
                                
                               </app-single-image-upload>
                            </div>
                        </div>

                        <div *ngIf="blogPostQ.awsURL !== null" class="form-row">
                            <div class="col-md-12">
                                 <button class="btn btn-danger" 
                                        (click)="removeCurrentImage(blogPostQ.id)">
                                      Remove Current Image
                                  </button>
                              </div>
                        </div>

                       </div>

                       <!-- else -->
                       <div *ngIf="!blogPostQ">
                         <div class="col-md-12">
                                 <app-single-image-upload formControlName="image" [progress]="progress">
                                  
                                 </app-single-image-upload>
                          </div>
                        </div>
                      

                      

                  
                      <div class="form-row">
                          <div *ngIf="browserTest === true" class="col-md-12">
                          		 <ckeditor [editor]="Editor" formControlName="wysiwyg"></ckeditor>
                          	</div>
                      </div>

                      <div *ngIf="!blogPostQ" class="form-row">
                          <div class="col-md-12">
                          		  <button class="btn btn-success" [disabled]="blogForm?.invalid" 
			                    (click)="savePost()">Save</button>
                          	</div>
                      </div>

                      <div *ngIf="blogPostQ" class="form-row">
                          <div class="col-md-12">
                                <button class="btn btn-success" (click)="savePost()">Save</button>
                            </div>
                      </div>
                 

                 
                   </div>
                  </form>


            

            <br /><br />
            

            </div>



          
        </div>
    </div>
</section>