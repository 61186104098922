import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MenuComponent } from './components/navigation/menu/menu.component';
import { MenuAdminComponent } from './components/navigation/menuAdmin/menu.component';
import { TapToTopComponent } from './components/tap-to-top/tap-to-top.component';
import { RouterModule } from '@angular/router';
import { CenterMenuComponent } from './components/navigation/center-menu/center-menu.component';
import { ToastrModule } from 'ngx-toastr';
import { OrderByPipe } from './pipes/order-by.pipe';
import { LoaderComponent } from './components/loader/loader.component';
import { HeaderComponent } from './components/pages/header/header.component';
import { HeaderAdminComponent } from './components/pages/headerAdmin/header.component';
import { BreadcrumbComponent } from './components/pages/breadcrumb/breadcrumb.component';
import { FooterComponent } from './components/pages/footer/footer.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CreditCardDirective } from './../_directives/creditcard';
import { WindowRef } from './../_services/windowref';
@NgModule({
    declarations: [
        MenuComponent,
        MenuAdminComponent,
        HeaderComponent,
        HeaderAdminComponent,
        BreadcrumbComponent,
        FooterComponent,
        TapToTopComponent,
        CenterMenuComponent,
        OrderByPipe,
        LoaderComponent,
        CreditCardDirective,
    ],
    exports: [
        MenuComponent,
        MenuAdminComponent,
        LoaderComponent,
        CenterMenuComponent,
        TapToTopComponent,
        OrderByPipe,
        HeaderComponent,
        HeaderAdminComponent,
        BreadcrumbComponent,
        FooterComponent,
        CreditCardDirective,
    ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        RouterModule,
        NgbModule,
        ToastrModule.forRoot(),
        FontAwesomeModule
    ],
    providers: [WindowRef]
})
export class SharedModule { }
