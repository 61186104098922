import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { filter, map, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ColourStockRef, BasicInfo } from '../../../../../_models/index';
import { AdminProductService } from '../../../../../_services/index';
import { pipe } from 'rxjs';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-stockrefs',
    templateUrl: './stockrefs.component.html',
    styleUrls: ['./stockrefs.component.scss']
})
export class StockrefsComponent implements OnInit, OnDestroy {
    faTimes = faTimes;
    formError;
    productID = null;
    imageID = null;
    editForm = false;
    stockrefs: ColourStockRef[];
    productInfo: BasicInfo = null;
    stockrefForm = new UntypedFormGroup({
        id: new UntypedFormControl(null),
        stockref: new UntypedFormControl(null, Validators.required),
        width: new UntypedFormControl(null, Validators.required),
        packSize: new UntypedFormControl(null, Validators.required),
        freeText: new UntypedFormControl('a', Validators.required),
        palletLength: new UntypedFormControl(null, Validators.required),
        smallItem: new UntypedFormControl(false),
    });

    private modalRefService: NgbModalRef;
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    @ViewChild('addStockrefModal', { static: true }) private addStockrefModal;

    constructor(
        private adminProductService: AdminProductService,
        private modalService: NgbModal,
        private route: ActivatedRoute) {
        this.route.params.subscribe(params => {
            this.productID = params.productid;
            this.imageID = params.imageid;
            // Now we need to get product and colour names with this data
            this.adminProductService.getBasicInfo(this.productID, this.imageID).pipe(
                takeUntil(this.ngUnsubscribe))
                .subscribe(productInfo => this.productInfo = productInfo);
        });

    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.fetchData();
    }

    fetchData() {
        this.adminProductService.listImageStockRefs(this.productID, this.imageID).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(stockrefs => this.stockrefs = stockrefs);
    }

    openStockrefModal() {
        this.editForm = false;
        this.stockrefForm.reset();
        this.stockrefForm.controls.freeText.setValue('none');
        this.modalRefService = this.modalService.open(this.addStockrefModal);
    }

    evalAPIResponse (response) {
        if (response.status === true) {
            this.editForm = false;
            this.stockrefForm.reset();
            this.formError = null;
            this.modalService.dismissAll();
            this.fetchData();
        } else {
            this.formError = response.message;
        }

    }

    editStockref(stockref: ColourStockRef) {
        this.stockrefForm.reset();
        this.editForm = true;
        this.stockrefForm.controls.id.setValue(stockref.id);
        this.stockrefForm.controls.stockref.setValue(stockref.stockref);
        this.stockrefForm.controls.width.setValue(stockref.width);
        this.stockrefForm.controls.packSize.setValue(stockref.packSize);
        this.stockrefForm.controls.palletLength.setValue(stockref.palletLength);
        this.stockrefForm.controls.freeText.setValue(stockref.freeText);
        this.stockrefForm.controls.smallItem.setValue(stockref.smallItem);
        this.modalRefService = this.modalService.open(this.addStockrefModal);
    }

    saveStockRef() {
        const newStockRef: ColourStockRef = {
            id: this.stockrefForm.controls.id.value,
            stockref: this.stockrefForm.controls.stockref.value,
            productID: this.productID,
            imageID: this.imageID,
            freeText: this.stockrefForm.controls.freeText.value,
            width: this.stockrefForm.controls.width.value,
            packSize: this.stockrefForm.controls.packSize.value,
            palletLength: this.stockrefForm.controls.palletLength.value,
            smallItem: this.stockrefForm.controls.smallItem.value,
        };

        if (this.editForm === false) {
            this.adminProductService.newStockRef(newStockRef).pipe(
                takeUntil(this.ngUnsubscribe))
                .subscribe(response => this.evalAPIResponse(response));
        } else {
            this.adminProductService.updateStockRef(newStockRef).pipe(
                takeUntil(this.ngUnsubscribe))
                .subscribe(response => this.evalAPIResponse(response));
        }
    }

    removeStockref(stockrefID) {
        if (confirm('Are you sure to delete this stockref?')) {
            this.adminProductService.removeImageStockref(this.imageID, stockrefID).pipe(
                takeUntil(this.ngUnsubscribe))
                .subscribe(response => this.evalAPIResponse(response));
        }
    }

}
