<section class="event team-sec speaker set-relative" id="speaker">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 speker-container">

            <div *ngIf="formError">
                <div class="alert alert-danger" role="alert">
                        <h1>{{formError}}</h1>
                </div>
            </div> 
            <div *ngIf="browserTest === true">
            <ckeditor [editor]="Editor" [(ngModel)]="wysiwygModel.editorData"></ckeditor>
            </div>
            <br /><br />
            <button class="btn btn-success"
                    (click)="updateWysiwyg()">Save</button>

            </div>



          
        </div>
    </div>
</section>