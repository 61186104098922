<ng-template #EditStatus let-c="close" let-d="dismiss">
        <div class="modal-header">
          <h4 class="modal-title">Edit Status</h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div *ngIf="formError">
	            <div class="alert alert-danger" role="alert">
	                    <h1>{{formError}}</h1>
	            </div>
			</div> 
            <form class="form">
                    <div class="form-group" [formGroup]="statusForm">
                    <label for="name">Name</label>
                    <div class="input-group">
                    <input class="form-control" placeholder="Name"
                        id="name"
                        type="text"
                        formControlName="name">
                    </div>
                    <p *ngIf="statusForm.get(['name']).hasError('required')" 
		                style="color:red;">You must add a value</p>
		            <br />


		            

                    <button class="btn btn-success" [disabled]="statusForm?.invalid" 
                    (click)="saveStatus()">Save</button>

                    
                    </div>
            </form>

        <div class="modal-footer">
          <button type="button" class="btn btn-outline-dark" (click)="c('Close click')">Close</button>
        </div>
    </div>
</ng-template>


<section class="event team-sec speaker set-relative" id="speaker">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-sm-12 speker-container">
                <div class="table-responsive">
                	<button class="btn btn-danger" (click)="addStatus()">
                        Add new
                    </button>
    				<table class="table table-striped">
    				  <thead>
    				  <tr>
    				    <th scope="col"># (Edit)</th>
    				    <th scope="col">Name</th>
    				  </tr>
    				  </thead>
    				  <tbody>

    				  <tr *ngFor="let status of orderstatuss; index as i">
    				    <th scope="row" (click)="editStatus(status)">{{ status.id }}</th>

    				    <td>{{ status.name}}</td>
 
    				  </tr>
    				  </tbody>
    				</table>

    				

                    <br /><br />

                </div>

            </div>

          
        </div>
    </div>
</section>
