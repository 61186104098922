import { createAction, props } from '@ngrx/store';
import { CartItem, CartTotal, CustomerAddress } from '../_models/index';

export const addToCart = createAction(
    '[CART] Add Item',
    props<{ cartItem: CartItem}>()
);

export const removeFromCart = createAction(
    '[CART] Remove Item',
    props<{ cartItem: CartItem}>()
);

export const updateCart = createAction(
    '[CART] Update Item',
    props<{ cartItem: CartItem}>()
);

export const updateSavedCart = createAction(
    '[CART] Load Saved Basket',
    props<{ cart: CartItem[]}>()
);

export const setCartLines = createAction(
    '[CART] Set Lines',
    props<{ newLines: number}>()
);

export const updateCartTotals = createAction(
    '[CART] Update Totals',
    props<{ cartTotal: CartTotal}>()
);

export const updateAddress = createAction(
    '[CART] Update Update Address',
    props<{ customerAddress: CustomerAddress}>()
);
