import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, PRIMARY_OUTLET } from '@angular/router';

import { filter, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, OnDestroy {

    public url: any;
    public breadcrumbs;
    public title: string;
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    constructor (
        private activatedRoute: ActivatedRoute,
        private router: Router) {
        this.router.events
            .pipe(filter((event) => {
                if (event instanceof NavigationEnd) {
                    this.url = event.url;
                }
                return event instanceof NavigationEnd;
            }))
            .pipe(map(() => this.activatedRoute))
            .pipe(map((route) => {
                while (route.firstChild) {
                    route = route.firstChild;
                }
                return route;
            }))
            .pipe(filter(route => route.outlet === PRIMARY_OUTLET))
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(event => {
                const title = event.snapshot.data.title,
                    parent = event.parent.snapshot.data.breadcrumb,
                    child = event.snapshot.data.breadcrumb;
                this.breadcrumbs = {};
                this.title = title;
                this.breadcrumbs = {
                    parentBreadcrumb: parent,
                    childBreadcrumb: child
                };
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit () {
    }

}
