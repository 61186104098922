 <div class="blog-side">
        <div>
            <h5 class="blog-title">Tags</h5>
            <div class="sidebar-container borders">
                <button *ngFor="let tag of tags;"
                    [routerLink]="['/blog/tags', tag.id]"
                   class="btn btn-default primary-btn info btn-small">{{tag.name}}</button>
            </div>
        </div>
        <div>
            <h5 class="blog-title">Recent Posts</h5>
            <div class="sidebar-container">
                <div *ngFor="let post of posts" class="post-container d-flex">
                    <div class="w-35 m-r-25">
                        <img src="{{post.awsURL}}" alt=""
                             class="img-fluid">
                    </div>
                    <div><h5 class="post-head">{{post.title}}</h5>
                        <h6 class="date">{{post.pubDate}}</h6>
                    </div>
                </div>
            </div>
        </div>
        <!--
        <div class="sidebar-container">
            <h5 class="blog-title">newsletter</h5>
            <div class="newsletter text-center form">
                <div class="form-group"><input type="text" class="form-control" placeholder="enter email">
                    <a href="javascript:void()"> <i class="fa fa-paper-plane" aria-hidden="true"></i> </a></div>
            </div>
        </div> -->
        <div>
            <!--
            <h5 class="blog-title">instagram</h5>
            <div class="sidebar-container insta">
                <ul class="blog-insta">
                    <li><a href=""> <img src="assets/images/agency/blog/2.jpg" alt="" class="img-fluid">
                    </a></li>
                    <li><a href=""> <img src="assets/images/agency/blog/3.png" alt="" class="img-fluid">
                    </a></li>
                    <li><a href=""> <img src="assets/images/agency/blog/4.jpg" alt="" class="img-fluid">
                    </a></li>
                    <li><a href=""> <img src="assets/images/agency/blog/6.jpg" alt="" class="img-fluid">
                    </a></li>
                </ul>
                <ul class="blog-insta">
                    <li><a href=""> <img src="assets/images/agency/blog/6.jpg" alt="" class="img-fluid">
                    </a></li>
                    <li><a href=""> <img src="assets/images/agency/blog/4.jpg" alt="" class="img-fluid">
                    </a></li>
                    <li><a href=""> <img src="assets/images/agency/blog/3.png" alt="" class="img-fluid">
                    </a></li>
                    <li><a href=""> <img src="assets/images/agency/blog/2.jpg" alt="" class="img-fluid">
                    </a></li>
                </ul>
            </div>
            -->


        </div>
</div>