import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// New code
import { PaymentsComponent } from './payments/payments.component';
import { PaymentstatusComponent } from './payments/paymentstatus/paymentstatus.component';
import { ContactComponent } from './contact/contact.component';
import { NewCustomerComponent } from './newcustomer/newcustomer.component';
import { AftercareComponent } from './aftercare/aftercare.component';
import { EcomRegisterComponent } from './ecom-register/ecom-register.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { CustomPagesComponent } from './custom-pages/custom-pages.component';
import { CalculatorsComponent } from './calculators/calculators.component';
import { SolentSelectionComponent } from './solent-selection/solent-selection.component';


const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: 'calculators',
                component: CalculatorsComponent,
                data: {
                    title: 'Calcualtors',
                    breadcrumb: 'All Calcualtors'
                }
            },
            {
                path: 'solent-selection',
                component: SolentSelectionComponent,
                data: {
                    title: 'Solent Selection',
                    breadcrumb: 'Unique own brand ranges'
                }
            },
            {
                path: 'payments',
                component: PaymentsComponent,
                data: {
                    title: 'Payments Page',
                    breadcrumb: 'Faqs'
                }
            },
            {
                path: 'payments/results',
                component: PaymentstatusComponent,
                data: {
                    title: 'Payments Status',
                    breadcrumb: 'Faqs'
                }
            },
            {
                path: 'contact',
                component: ContactComponent,
                data: {
                    title: 'Contact Page',
                    breadcrumb: 'Faqs'
                }
            },
            {
                path: 'new-customer',
                component: NewCustomerComponent,
                data: {
                    title: 'New Customer',
                    breadcrumb: 'Application Form'
                }
            },
            {
                path: 'portal-registration',
                component: EcomRegisterComponent,
                data: {
                    title: 'Portal Registration',
                    breadcrumb: 'Register for service'
                }
            },
            {
                path: 'after-care',
                component: AftercareComponent,
                data: {
                    title: 'After Care',
                    breadcrumb: 'Product After Care Advice'
                }
            },
            {
                path: '404',
                component: PageNotFoundComponent
            },
            {
                path: 'about-us',
                component: AboutUsComponent,
                data: {
                    title: 'About Us',
                    breadcrumb: 'About Us'
                }
            },
            {
                path: 'content/:slug',
                component: CustomPagesComponent,
                data: {
                    title: 'Solent Wholesale Carpets',
                    breadcrumb: '01243 774623'
                }
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PagesRoutingModule { }
