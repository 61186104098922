import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GenericResponse } from '../_models/index';
import { API_URL } from '../app.const';

@Injectable()
export class AdminManufacturerService {
    private removeManImageURL = API_URL + '/admin/products/remove_man_image';
    private updateManURL = API_URL + '/admin/products/edit_manufacturer';
    private markManShowcaseURL = API_URL + '/admin/products/mark_man_showcase';

    constructor (private http: HttpClient) {}


    removeManufacturerImage (manufacturerID) {
	    return this.http.post<GenericResponse>(this.removeManImageURL,
	        JSON.stringify({ manufacturerID }));
  	}

  	updateManufacturer (manufacturerID, name) {
	    return this.http.post<GenericResponse>(this.updateManURL,
	        JSON.stringify({ manufacturerID, name }));
  	}

    markManufacturerShowcase(manufacturerID) {
        return this.http.post<GenericResponse>(this.markManShowcaseURL,
            JSON.stringify({ manufacturerID }));
    }




}
