<section class="breadcrumb-section-main inner-2 breadcrumb-section-sm">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="breadcrumb-contain ">
                        <div>
                            
                        </div>
                        <div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</section>

<section class="ecommerce pt-0 ecommerce-home">
    <owl-carousel-o [options]="ecombannerOptions" class="ecommerce-home-slider">
          <ng-template carouselSlide *ngFor="let data of banner">
      <div class="item-class" [ngStyle]="{'background-image': 'url('+ data.img +')'}">
          <div class="container">
              <div class="row">
                  <div class="col-xl-7 col-lg-7 col-sm-8">
                      <div class="contain">
                          <div>
                              <h1 class="whitetext">{{data.title}}</h1>
                              <p class="whitetext">{{data.description}}</p>
                              <a  *ngIf="data.link" [routerLink]="data.link" class="btn btn-default primary-btn  radius-0" href="javascript:void()">{{data.btn}}</a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      </ng-template>
      </owl-carousel-o>
</section>