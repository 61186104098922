import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageService } from '../../../_services/index';
import { PaymentResponse } from '../../../_models/index';
import { takeUntil } from 'rxjs/operators';
import { Subject} from 'rxjs';

@Component({
    selector: 'app-paymentstatus',
    templateUrl: './paymentstatus.component.html',
    styleUrls: ['./paymentstatus.component.scss']
})
export class PaymentstatusComponent implements OnInit {
    paymentObject: PaymentResponse
    paymentMessage : string = null;
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    constructor(
        private route: ActivatedRoute,
        private pageService: PageService) { 
            route.queryParams.subscribe((params) => this.getPaymentStatus(params.payment_id));
        }

    ngOnInit(): void {
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    getPaymentStatus(paymentID) {
        console.log(paymentID);
        this.pageService.lookupPaymentStatus(paymentID).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(response => {
                if (response === null ) {
                    this.paymentMessage = "Invalid JWT"
                } else {
                    this.paymentObject = response;
                }
            });
    }

}
