import { Component, OnInit,  HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { WindowRef } from './../../../_services/windowref';
import { isPlatformBrowser} from '@angular/common';

@Component({
    selector: 'app-tap-to-top',
    templateUrl: './tap-to-top.component.html',
    styleUrls: ['./tap-to-top.component.scss']
})
export class TapToTopComponent implements OnInit {
    public showScroll: boolean;
    public showScrollHeight = 500;
    public hideScrollHeight = 10;

    constructor (
        @Inject(DOCUMENT) private document: Document,
        private winRef: WindowRef,
        @Inject(PLATFORM_ID) private platformId: any) { }

    ngOnInit () {
    }

    onActivate () {
        const scrollToTop = this.winRef.nativeWindow.setInterval(() => {
            const pos = this.winRef.nativeWindow.pageYOffset;
            if (pos > 0) {
                this.winRef.nativeWindow.scrollTo(0, pos - 400); // how far to scroll on each step
            } else {
                this.winRef.nativeWindow.clearInterval(scrollToTop);
            }
        }, 60);
    }



@HostListener('window:scroll', [])
    onWindowScroll ()
    {
        if (( this.winRef.nativeWindow.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop) > this.showScrollHeight)
        {
            this.showScroll = true;
        }
        else if ( this.showScroll && (this.winRef.nativeWindow.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop) < this.hideScrollHeight)
        {
            this.showScroll = false;
        }
    }
}