import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject} from 'rxjs';
import { SolentExpressService } from '../../_services/';
import { isPlatformBrowser } from '@angular/common';


@Component({
    selector: 'app-payments',
    templateUrl: './payments.component.html',
    styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit, OnDestroy {

    formError: string = null;
    paymentForm = new UntypedFormGroup({
        accountNo: new UntypedFormControl(null, [Validators.required, Validators.maxLength(9)]),
        tradingName: new UntypedFormControl(null, [Validators.required, Validators.maxLength(100)]),
        amountPaying: new UntypedFormControl(null, [Validators.required, Validators.maxLength(100)]),
        transRefForm: new UntypedFormControl(null, [Validators.required, Validators.maxLength(30)]),
    });
    public loading = false;

    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor (
        private title: Title,
        private expressService: SolentExpressService,
    ) { }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit () {
        this.title.setTitle('Solent Wholesale | Payments');
    }


    generateOpenBankingURL() {
        this.formError = null;
        const amount = this.paymentForm.controls.amountPaying.value;
        // Now we need to get the value as pence
        const accountNo = this.paymentForm.controls.accountNo.value;
        const tradingName = this.paymentForm.controls.tradingName.value;
        const transRef = this.paymentForm.controls.accountNo.value + '-' + this.paymentForm.controls.tradingName.value;

        this.expressService.generateOpenBankingURL(amount, transRef, accountNo, tradingName )
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe( response => {
                if (response.url === null) {
                    this.formError = 'Sorry, there has been a problem';
                } else {
                    // navigate user to response.url
                    window.location.href = response.url;
                }
            });
    }
}
