import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GenericResponse, Carrier, OrderStatus } from '../_models/index';
import { API_URL } from '../app.const';

@Injectable()
export class AdminExpressService {
    private getCarriersURL = API_URL + '/admin/express/list_carriers';
    private updateCarrierURL = API_URL + '/admin/express/edit_carrier';
    private getOrderStatusURL = API_URL + '/admin/express/list_order_status';
    private addOrderStatusURL = API_URL + '/admin/express/add_order_status';
    private editOrderStatusURL = API_URL + '/admin/express/edit_order_status';
    constructor (private http: HttpClient) {}

    getCarriers() {
        return this.http.get<Carrier[]>(this.getCarriersURL);
    }

    updateCarrier(carrierID, carrierName, carrierTrackingPrefix, trackingExampleID) {
        return this.http.post<GenericResponse>(this.updateCarrierURL,
            JSON.stringify({ carrierID, carrierName, carrierTrackingPrefix, trackingExampleID }));
    }

    getOrderStatus() {
        return this.http.get<OrderStatus[]>(this.getOrderStatusURL);
    }

    addOrderStatus(name) {
        return this.http.post<GenericResponse>(this.addOrderStatusURL,
            JSON.stringify({ name }));
    }

    updateOrderStatus(statusID, name) {
        return this.http.post<GenericResponse>(this.editOrderStatusURL,
            JSON.stringify({ statusID, name }));
    }

}
