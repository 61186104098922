<section class="bg-light">
  <div class="container">
    <div class="row">
      <div class="offset-md-2 col-md-8">
        <div class="title title2">
          <div class="sub-title">
            <div>
              <h3 class="title-text">Recent Blog Posts</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12">
        <div class="agency blog blog-sec">
            <owl-carousel-o [options]="blogCarouselOptions" class="blog-slider">
                <ng-template *ngFor="let post of posts" carouselSlide>
                  <div class="blog-agency">
                    <div class="blog-contain">
                      <img alt="" class="img-fluid img-blog-home" [src]="post.awsURL">
                      <div class="img-container center-content">
                        <div class="center-content">
                          <div class="blog-info">
                            <div class="m-b-20">
                              <div class="center-text">
                                <i aria-hidden="true" class="fa fa-clock-o m-r-10"></i>
                                <h6 class="m-r-25 font-blog">{{post.pubDate}}</h6>
                                <i aria-hidden="true" class="fa fa-map-marker m-r-10"></i>
                                <h6 class="font-blog">{{post.authorName}}</h6>
                              </div>
                            </div>
                            <h5 class="blog-head font-600">{{post.title}}</h5>
                            <p [innerHTML]="post.content| slice:0:300" class="para2">
                             
                            </p><p>Text condensed, read full article?</p>
                            <div class="btn-bottom m-t-20">
                              <a [routerLink]="['/blog/article', post.slug]"
                                  class="btn btn-default primary-btn info">Read More</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
            </owl-carousel-o>
        </div>
      </div>
    </div>
  </div>
</section>