import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as fromRoot from './../_ngrx/index';
import * as myActions from './../_ngrx/actions';
import {Store} from '@ngrx/store';
import {
    CustomerAddress,
    DeliveryQuote,
    CartItem,
    CartTotal,
    GenericResponse,
    Orders,
    StockCheck,
    OpenBankingURL
} from '../_models/index';
import { API_URL } from '../app.const';

@Injectable()
export class SolentExpressService {
    private getCustomerAddressURL = API_URL + '/express/get_delivery_address';
    private getCustomerINVAddressURL = API_URL + '/express/get_invoice_address';
    private getDeliveryQuoteURL = API_URL + '/express/delivery_quote';
    private stockCheckURL = API_URL + '/express/stock_check';
    private cardSaverPaymentURL = 'https://payment.card-saver.co.uk/direct/';
    private pythonPaymentURL = API_URL + '/card-saver/make_payment';
    private cardSaverCallBackURL = API_URL + '/card-saver/post_call_back';
    private generateAPISignatureURL = API_URL + '/card-saver/generate-signature';
    private generateHostedAPISignatureURL = API_URL + '/card-saver/generate-hosted-signature';
    private genOpenBankingURL = API_URL + '/payments/gen_openbanking_url';
    private getRecentOrdersURL = API_URL + '/express/order_history';
    private submitOrderURL = API_URL + '/express/submit_order';
    private markOrderPaidURL = API_URL + '/card-saver/mark_order_paid';
    private checkOrderPaidURL = API_URL + '/card-saver/check_order_paid';
    private saveBasketURL = API_URL + '/express/save_basket';
    private fetchBasketURL = API_URL + '/express/fetch_basket';
    private sampleRequestURL = API_URL + '/express/sample_request';
    constructor (
        private store: Store<fromRoot.State>,
        private http: HttpClient) {}

    getDefaultAddress(customerNo) {
        return this.http.post<CustomerAddress>(this.getCustomerAddressURL,
            JSON.stringify({ customerNo }));
    }

    getInvAddress(customerNo) {
        return this.http.post<CustomerAddress>(this.getCustomerINVAddressURL,
            JSON.stringify({ customerNo }));
    }

    getRecentOrders(customerNo) {
        return this.http.post<Orders[]>(this.getRecentOrdersURL,
            JSON.stringify({ customerNo }));
    }

    checkStock(orderLines) {
        return this.http.post<StockCheck[]>(this.stockCheckURL,
            JSON.stringify({ orderLines }));
    }

    sampleRequest(customerNo, sampleName) {
        return this.http.post<GenericResponse>(this.sampleRequestURL,
            JSON.stringify({ customerNo, sampleName }));
    }

    saveBasket(userid, basket) {
        return this.http.post<GenericResponse>(this.saveBasketURL,
            JSON.stringify({ userid, basket }));
    }

    updateBasket(basket: CartItem[]) {

        const newBasket = [];
        let lineCount = 0;
        for (const item of basket) {
            // rest line numbers
            const lineNo = lineCount += 1;
            item.lineno = lineNo;
            newBasket.push(item);
        }
        this.store.dispatch(myActions.setCartLines ({newLines: lineCount}));
        this.store.dispatch(myActions.updateSavedCart ({cart: newBasket}));
    }

    fetchBasket(userid) {
        return this.http.post<CartItem[]>(this.fetchBasketURL,
            JSON.stringify({ userid }));
    }

    getDeliveryQuote(
        basket: CartItem[],
        ordervalue: number,
        postcode: string,
        customerNo: string,
        nextDay: boolean,
        amDrop: boolean,
    ) {
        return this.http.post<DeliveryQuote>(this.getDeliveryQuoteURL,
            JSON.stringify({ basket, ordervalue, postcode, customerNo, nextDay, amDrop }));
    }

    buildHostedPaymentObject(
        paymentAmount: number,
        email,
        customerOrderRef,
        merchantDataForm,
    ) {
        // merchantID: '121447',
        // non 3d 108166
        // 3d 108167
        const paymentObject: any = {
            action: 'SALE',
            amount: paymentAmount, // in pence, e.g. 100 is 1pound
            customerEmail: email,
            countryCode: '826',
            currencyCode: '826',
            formResponsive: 'Y',
            merchantData: merchantDataForm,
            merchantName: 'Solent Wholesale',
            merchantWebsite: 'https://www.solentwholesale.com',
            merchantID: '121447',
            orderRef: customerOrderRef, // create a reference for the order
            redirectURL: 'https://cmsapi.solentwholesale.com/card-saver/validate_hosted_payment',
            type: '1', // 1 for eccomerce
        };
        return paymentObject;
    }

    buildHostedExpressPaymentObject(
        paymentAmount: number,
        addressL1: string,
        PostCode: string,
        customerOrderRef: string,
        email,
        merchantDataForm
    ) {
        // merchantID: '121447',
        // non 3d 108166
        // 3d 108167
        const paymentObject: any = {
            action: 'SALE',
            amount: paymentAmount, // in pence, e.g. 100 is 1pound
            customerAddress: addressL1,
            customerEmail: email,
            customerPostCode: PostCode,
            countryCode: '826',
            currencyCode: '826',
            formResponsive: 'Y',
            merchantData: merchantDataForm,
            merchantName: 'Solent Wholesale',
            merchantWebsite: 'https://www.solentwholesale.com',
            merchantID: '121447',
            orderRef: customerOrderRef, // create a reference for the order
            redirectURL: 'https://cmsapi.solentwholesale.com/card-saver/validate_hosted_payment',
            type: '1', // 1 for eccomerce
        };

        return paymentObject;

    }


    buildPaymentObject(
        paymentAmount: number,
        addressL1: string,
        PostCode: string,
        customerCardCVV: number,
        customerCardExpiryDate: number,
        customerCardNumber: number,
        customerOrderRef: string,
        email
    ) {
        // merchantID: '121447',
        // non 3d 108166
        // 3d 108167
        const paymentObject: any = {
            action: 'SALE',
            amount: paymentAmount, // in pence, e.g. 100 is 1pound
            cardCVV: customerCardCVV,
            cardExpiryDate: customerCardExpiryDate, // e.g. 0124 for Jan 2024
            cardNumber: customerCardNumber,
            customerAddress: addressL1,
            customerEmail: email,
            customerPostCode: PostCode,
            countryCode: '826',
            currencyCode: '826',
            merchantName: 'Solent Wholesale',
            merchantWebsite: 'https://www.solentwholesale.com',
            merchantID: '121447',
            orderRef: customerOrderRef, // create a reference for the order
            type: '1', // 1 for eccomerce
        };

        return paymentObject;


    }


    generateAPISignature(paymentObject) {
        return this.http.post(this.generateAPISignatureURL,
            JSON.stringify({ paymentObject }));
    }

    makePythonPayment(paymentObject) {
        /* Card saver servers do not have CORS, so we can't perform the post request from
        client/browser side, so we send the data to the API, make the request there and then
        post the response back to the frontend
        */
        const myObject = paymentObject;
        myObject.orderRef = encodeURIComponent(paymentObject.orderRef).replace(/%20/g, '+');
        const myParams = new HttpParams({ fromObject: myObject });
        return this.http.post(this.pythonPaymentURL,
            JSON.stringify({payloadStr: myParams.toString()}), {responseType: 'text'});
    }

    submitNewOrder(
        deliveryAddress: CustomerAddress,
        orderlines: CartItem[],
        totals: CartTotal,
        deliveryComments: string,
        orderRefernece: string,
        accountNumber: string,
        userID: string,
    ) {

        return this.http.post<GenericResponse>(this.submitOrderURL,
            JSON.stringify({
                deliveryAddress, orderlines, totals, deliveryComments, orderRefernece,
                accountNumber, userID
            }));

    }

    generateOpenBankingURL(amount, transref, accountno, tradingName) {
        return this.http.post<OpenBankingURL>(this.genOpenBankingURL,
            JSON.stringify({ amount, transref, accountno, tradingName }));
    }

    markOrderPaid(orderID) {
        return this.http.post<GenericResponse>(this.markOrderPaidURL,
            JSON.stringify({ orderID }));
    }

    checkOrderPaid(orderID) {
        return this.http.post<GenericResponse>(this.checkOrderPaidURL,
            JSON.stringify({ orderID }));
    }


}
