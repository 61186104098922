<!-- section start -->
<section class="agency blog blog-sec blog-sidebar blog-split sider">
    <div class="container">
        <div class="row pl-0 pr-0">
            <div class="col-lg-9">
                <div class="row">
                    <div class="col-12 blog-sec blog-list" *ngFor="let post of posts">
                        <div class="blog-agency">
                            <div class="row blog-contain pl-0 pr-0">
                                <div class="col-xl-4 col-lg-5 col-md-6 p-0" [ngClass]="post.id%2==0?'order-md-2':''">
                                    <div class=" text-center">
                                        <img [src]="post.awsURL" alt="" class="img-fluid blog-img">
                                    </div>
                                </div>
                                <div class="col-xl-8 col-lg-7 col-md-6 p-0">
                                    <div class="img-container center-content">
                                        <div class="center-content">
                                            <div class="blog-info">
                                                <div class="m-b-20">
                                                    <div class="center-text">
                                                        <i class="fa fa-clock-o m-r-10" aria-hidden="true"></i>
                                                        <h6 class="m-r-25 font-blog">{{post.pubDate}}</h6>
                                                        <i class="fa fa-map-marker m-r-10" aria-hidden="true"></i>
                                                        <h6 class="font-blog">{{post.authorName}}</h6>
                                                    </div>
                                                </div>
                                                <h5 class="blog-head font-600">{{post.title}}</h5>
                                                <p class="para2" [innerHTML]="post.content"></p>
                                                <div class="btn-bottom m-t-20">
                                                    <a [routerLink]="['/blog/article', post.slug]"
                                                        class="btn btn-default primary-btn info">Read More</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
              <app-sidebar-blog></app-sidebar-blog>
            </div>
        </div>
    </div>
</section>
<!-- section end -->