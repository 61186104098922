<!-- section start -->
<section class="register-page section-b-space">
  <div class="container">
      <div class="row">
          <div class="col-lg-6">
                  <form class="theme-form">
                    <div class="form-group"  [formGroup]="contactForm">
                      <div class="form-row">
                          <div class="col-md-12">
                            <h3>Portal Registration</h3>
                              <p>

                             Register to use our online ordering system</p> <br />
                          </div>
                      </div>

                      <div class="form-row">
                          <div class="col-md-12">
                              <label for="email">Trading Name</label>
                              <input type="text" class="form-control" id="tname"
                                      formControlName="tname"
                                      placeholder="Trading name">
                                <p *ngIf="contactForm.get(['tname']).hasError('required')" 
                                    style="color:red;">A name is required</p>
                                <p *ngIf="contactForm.get(['tname']).hasError('maxlength')" 
                                    style="color:red;">You are limited to 100 characters</p>
                          </div>
                      </div>
                      <div class="form-row">
                          <div class="col-md-6">
                              <label for="email">Email</label>
                              <input type="email" class="form-control" id="email"
                                      formControlName="email"
                                      placeholder="Email address">
                                <p *ngIf="contactForm.get(['email']).hasError('required')" 
                                    style="color:red;">An email  is required</p>
                                <p *ngIf="contactForm.get(['email']).hasError('maxlength')" 
                                    style="color:red;">You are limited to 100 characters</p>
                          </div>
                          <div class="col-md-6">
                              <label for="review">Account Number</label>
                              <input type="text" class="form-control" id="accNumber"
                                      formControlName="accNumber"
                                      placeholder="Account number">
                                <p *ngIf="contactForm.get(['accNumber']).hasError('required')" 
                                    style="color:red;">An account number is required</p>
                                <p *ngIf="contactForm.get(['accNumber']).hasError('maxlength')" 
                                    style="color:red;">You are limited to 100 characters</p>
                          </div>
                          
                      </div>

                      <br />
                      <div class="form-row">
                          <button class="btn primary-btn btn-primary btn-blue radius-0"
                                  [disabled]="contactForm?.invalid"  (click)="submitContact()"  
                          >Submit</button>
                      </div>
                  </div>
                  </form>
            
          </div>

          <div class="col-lg-3 hiddenSMALL">

            <h3>Business Info</h3>
            <h6><fa-icon  class="faContact" [icon]="faMarker"></fa-icon> Address</h6>
            <p> Solent Wholesale Carpets<br />
                Zola Park, Barnfield Dr<br />
                Chichester, W Sussex<br />
                PO19 6UX<br />
            </p><br />
            <h6><fa-icon  class="faContact" [icon]="faEnvelope"></fa-icon>Email</h6>
            <p> <a href="mailto:sales@solentwholesale.com">sales&#64;solentwholesale.com</a></p><br />

            <h6><fa-icon  class="faContact" [icon]="faPhone"></fa-icon>Phone</h6>
            <p> <a href="tel:01243774623">01243 774623</a></p><br />

          </div>

          <div class="col-lg-3 hiddenSMALL">
            
            <h3>New Customer?</h3>
              <p class="highlightURL">

             Are you in the flooring trade? Looking for a new supplier with lots of stock, a great product selection and 
             competitive prices?</p> 
             <br />

             <a [routerLink]="['/page/new-customer']" class="btn btn-default primary-btn info">Open An Account Today</a>

             <br />

            

          </div>
      </div>
  </div>
</section>

