<section class="event team-sec speaker set-relative" id="speaker">
    <div class="container">
        <div *ngIf="productTest === true" class="row">
            <div class="col-md-12 col-sm-12 speker-container">
                <div *ngIf="product.mainImageAWSURL === null">
                	<div *ngIf="formError">
			            <div class="alert alert-danger" role="alert">
			                    <h1>{{formError}}</h1>
			            </div>
					</div> 
                	<form class="form">
                    <div class="form-group" [formGroup]="imageForm">

                		<app-single-image-upload formControlName="image" [progress]="progress">
                		</app-single-image-upload>

                		<br />
                		<button class="btn btn-success" [disabled]="imageForm?.invalid" 
	                    (click)="saveNewImage()">Save New Image</button>

                	</div>
                </form>


                </div>

                <div *ngIf="product.mainImageAWSURL !== null">
                	<button class="btn btn-danger"
                        (click)="removeMainImage()">Remove Current Image</button>

                    <img src="{{product.mainImageAWSURL}}">
                </div>


            </div>

          
        </div>
    </div>
</section>
