import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { Subject} from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CustomPage } from '../../_models/index';
import { PageService, AdminPageService } from '../../_services/index';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-pages',
    templateUrl: './pages.component.html',
    styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit, OnDestroy {

    faCheck = faCheck;
    faTimes = faTimes;
    formError = null;
    selectedPage: CustomPage = null;
    browserTest = false;
    pages: CustomPage[] = [];
    pageForm = new UntypedFormGroup({
        id: new UntypedFormControl(null),
        name: new UntypedFormControl(null, [Validators.required, Validators.maxLength(50)]),
        content: new UntypedFormControl(null, Validators.required)
    });

    public Editor;
    private modalRefService: NgbModalRef;
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    @ViewChild('addPageModal', { static: true }) private addPageModal;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private pageService: PageService,
        private adminPageService: AdminPageService,
        private modalService: NgbModal
    ) { }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.getPages();
        if (isPlatformBrowser(this.platformId)) {
            this.loadCkEditor();
        }
    }

    loadCkEditor() {
        const ClassicEditor = require('@ckeditor/ckeditor5-build-classic');
        this.Editor = ClassicEditor;
        this.browserTest = true;
    }

    addPage() {
        this.selectedPage = null;
        this.pageForm.reset();
        this.modalRefService = this.modalService.open(this.addPageModal, { size: 'lg' });
    }

    editPage(slug) {
        this.selectedPage = null;
        this.pageService.getCustomPage(slug).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(page => this.setPage(page));
    }

    setPage(page: CustomPage) {
        this.selectedPage = page;
        this.pageForm.reset();
        this.pageForm.controls.id.setValue(page.id);
        this.pageForm.controls.name.setValue(page.name);
        this.pageForm.controls.content.setValue(page.content);
        this.modalRefService = this.modalService.open(this.addPageModal, { size: 'lg' });

    }

    evalAPIResponse (response) {
        if (response.status === true) {
            this.pageForm.reset();
            this.formError = null;
            this.selectedPage = null;
            this.modalService.dismissAll();
            this.getPages();
        } else {
            this.formError = response.message;
        }

    }

    getPages() {
        this.adminPageService.getAllPages().pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(pages => this.pages = pages);
    }

    getOnePage(slug) {
        this.pageService.getCustomPage(slug).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(page => this.selectedPage = page);

    }

    publishPage(pageID) {
        this.adminPageService.publishPage(pageID).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(response => this.evalAPIResponse(response));
    }

    savePage() {
        const newPage: CustomPage = {
            id: this.pageForm.controls.id.value,
            name: this.pageForm.controls.name.value,
            content: this.pageForm.controls.content.value,
        };

        if (this.selectedPage === null) {
            this.adminPageService.addNewPage(newPage).pipe(
                takeUntil(this.ngUnsubscribe))
                .subscribe(response => this.evalAPIResponse(response));
        } else {
            this.adminPageService.updatePage(newPage).pipe(
                takeUntil(this.ngUnsubscribe))
                .subscribe(response => this.evalAPIResponse(response));
        }
    }

}
