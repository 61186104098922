<section class="event team-sec speaker set-relative" id="speaker">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-sm-12 speker-container">

                  <div class="bluetext"> 
                    <a [routerLink]="['/admin/extras/', productID]"><h3>Back To Product Admin</h3></a><br />
                  </div>

                	<div *ngIf="formError">
			            <div class="alert alert-danger" role="alert">
			                    <h1>{{formError}}</h1>
			            </div>
					</div> 
                	<form class="form">
                    <div class="form-group" [formGroup]="imageForm">

                		<app-multi-image-upload formControlName="image" [progress]="progress">
                		</app-multi-image-upload>

                		<br />
                		<button class="btn btn-success" [disabled]="imageForm?.invalid" 
	                    (click)="saveImages()">Save Images</button>

                	</div>
                </form>
            </div>
        </div>

        <div *ngIf="productTest === true" class="row">
        	<div class="col-12 col-xs-12">
            <div class="filter-section">
                                <div class="container">
                      <form class="form">
                        <div class="form-group" [formGroup]="coloursForm">

                          <div class="card cardother" *ngFor="let colour of product.colours">
                              <img src="{{colour.awsURL}}" class="card-img-top autofit">
                              <br />
                              <div class="card-body">
                                <h6>{{colour.origFilename}}</h6>
                                <fa-icon (click)="removeImage(colour.id)" 
                                class="fa-lg redFA" size="lg" [icon]="faTimes"></fa-icon>
                                <br />
                                <div class="input-group">
                                <input class="form-control" placeholder="Colour Name"
                                    id="{{colour.id}}"
                                    type="text"
                                    formControlName="{{colour.id}}">
                                </div>

                              </div>
                              
                              <a [routerLink]="['/admin/extra_stockrefs/', colour.id, product.id]"><h3>Stockref</h3></a>

                              <label for="cat_select">Colour Type</label>
                                <select class="form-control"
                                        formControlName="{{colour.id}}str">
                                <option disabled selected value> --Select-- </option>
                                <option *ngFor="let ct of colourTypes" [ngValue]="ct.id" >{{ct.name}}</option>
                                </select>

                          </div>
                          <br /><br />

                          <button class="btn btn-success" [disabled]="coloursForm?.invalid" 
                        (click)="updateImageNames()">Update Names</button>
                            
                        </div>
                      </form>

            </div>

          </div>
        </div>

        </div>



    </div>
</section>
