import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { takeUntil, take } from 'rxjs/operators';
import { Subject  } from 'rxjs';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ProductAttribute, GenericResponse } from '../../../_models/index';
import { AdminAttributeService} from '../../../_services/index';

@Component({
    selector: 'app-attributes',
    templateUrl: './attributes.component.html',
    styleUrls: ['./attributes.component.scss']
})
export class AttributesComponent implements OnInit, OnDestroy {
    formError = null;
    attributes: ProductAttribute[] = null;
    attributeQ: ProductAttribute[] = null;
    attributeForm = new UntypedFormGroup({
        id: new UntypedFormControl(null),
        name: new UntypedFormControl(null, Validators.required),
    });

    private modalRefService: NgbModalRef;
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    @ViewChild('addAttributeModal', { static: true }) private addAttributeModal;
    @ViewChild('editAttributeModal', { static: true }) private editAttributeModal;

    constructor(
        private adminAttributeService: AdminAttributeService,
        private fb: UntypedFormBuilder,
        private modalService: NgbModal,
        private ref: ChangeDetectorRef) { }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.getAttributes();
    }

    getAttributes() {
        // Get list of all attributes
        this.adminAttributeService.getAttributes().pipe(
            take(1),
            takeUntil(this.ngUnsubscribe))
            .subscribe(x => this.attributes = x);
    }

    addAttribute() {
        this.attributeForm.reset();
        this.modalRefService = this.modalService.open(this.addAttributeModal);
    }

    evalAPIResponse (response: GenericResponse) {
        if (response.status === true) {
            this.attributeForm.reset();
            this.formError = null;
            this.modalService.dismissAll();
            this.getAttributes();
        } else {
            this.formError = response.message;
        }

    }

    saveNewAttribute() {
        const newName = this.attributeForm.controls.name.value;
        this.adminAttributeService.saveNewAttribute(newName).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(response => this.evalAPIResponse(response));
    }

    editAttribute(id) {
        this.attributeQ = this.attributes.filter(x => x.id === id);
        this.attributeForm.controls.id.setValue(this.attributeQ[0].id);
        this.attributeForm.controls.name.setValue(this.attributeQ[0].name);
        this.modalRefService = this.modalService.open(this.editAttributeModal);

    }

    updateAttribute(id) {
        const updatedName = this.attributeForm.controls.name.value;
        this.adminAttributeService.editAttribute(id, updatedName).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(response => this.evalAPIResponse(response));
    }

    markAttributeHidden(id) {
        this.adminAttributeService.markAttributeDisabled(id).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(response => this.evalAPIResponse(response));
    }

}
