import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-e-commerce',
    templateUrl: './e-commerce.component.html',
    styleUrls: ['./e-commerce.component.scss']
})
export class ECommerceComponent implements OnInit {

    constructor (private title: Title) { }

    ngOnInit () {
        this.title.setTitle('Solent Wholesale | Home');
    }

}
