<app-header></app-header>
<!-- breadcrumb section start -->
<section class="breadcrumb-section-main inner-2 breadcrumb-section-sm">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="breadcrumb-contain ">
                        <div>
                            <h2 class="breadcrumb-txt">Products</h2>
                        </div>
                        <div>
                            <ul>
                                <li><a class="white" href="javascript:void()">List Categories</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
<!--breadcrumb section end -->
<!-- section start -->


<div class="agency">
<section class="agency blog blog-sec blog-sidebar blog-split sider">
    <div class="container">
        <div class="row pl-0 pr-0">
            <div class="col-lg-9">
                <div class="row">
                    <div class="card" *ngFor="let category of categories">
                          <!-- add query params to the category URL for SEO -->
                          <a [routerLink]="['/products/list', category.id]" [queryParams]="{category: category.slug}">
                          <img  src="{{category.awsURL}}" class="card-img-top autofit">
                          </a>
                          <div class="card-body">
                            <h4 class="card-title">{{category.name}}</h4>
                          </div>
                      </div>
                </div>
            </div>
            <div class="col-lg-3">
               <div class="blog-side">
                    <div>
                        <h5 class="blog-title">Our Products</h5>
                        <div class="sidebar-container borders">
                            Please find below the categories of products that we sell, over 90% of the products
                            available on our website are in stock at our Chichester warehouse. We do also supply
                            goods to special order, in these instances they will be marked as "To Order" when
                            viewing the product.
                        </div>
                    </div>
                    <div>
                        <h5 class="blog-title">Solent Selection</h5>
                        <div class="sidebar-container">
                            Solent Selection is our own brand of exclusive ranges<br />
                            We now offer multiple carpet ranges under our own branding, across multiple widths and backings this offers over 
                            130 unique colour options, if you include multiple widths and backings and pile weight this offering expands to 
                            over 200 unique options, truly something for all customers.
                            <br /><br />
                            <a [routerLink]="['/page/solent-selection']" class="btn btn-default primary-btn info">Solent Selection</a><br /><br />
                        </div>

                        
                        <div *ngIf="productFeature?.length" class="sidebar-container">
                            <h5 class="blog-title">Featured Products</h5>
                            <div *ngFor="let product of productFeature;" class="">
                                <div class="center-content">
                                <a [routerLink]="['/products/view', product.slug]">
                                <img src="{{product.mainImageAWSURL}}" alt=""
                                     class="img-fluid sidebarimage">
                                </a>
                                <h4 class="post-head grey">{{product.name}}</h4>
                                    <h6 class="date grey">{{product.briefDescription}}</h6>
                                </div>
                                <br />
                            </div>
                        </div>
                    </div>

                    <div>



                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- about section end-->


    </div>



<!-- Section ends -->
<app-footer></app-footer>