<section *ngIf="products" class="ecommerce feature-product">
  <div class="container">
    <div class="row">
      <div class="offset-md-2 col-md-8">
        <div class="title title2">
          <div class="sub-title">
            <div>
              <h3 class="title-text">Some of our products..</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12">
        <owl-carousel-o [options]="productSliderOptions" class="product-slider">
          <ng-container *ngFor="let product of products">
            <ng-template carouselSlide>
              <div>
                <div class="product-box">
                    <div class="img-wrapper">
                        <div class="front">
                          <a>
                            <img alt="" class="sliderimage" [routerLink]="['/products/view', product.slug]" 
                                 routerLinkActive="active" src="{{product.mainImageAWSURL}}">
                          </a>
                        </div>

                      </div>
                      <div class="product-detail">
                        <a href="product-page(no-sidebar).html">
                          <h6>{{product.name}}</h6>
                        </a>
                        <p>{{product.briefDescription}}</p>
                      </div>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>