import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavService, Menu } from '../../../../shared/service/nav.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-center-menu',
    templateUrl: './center-menu.component.html',
    styleUrls: ['./center-menu.component.scss']
})
export class CenterMenuComponent implements OnInit, OnDestroy {
    public menuItems: Menu[];
    public openSide = false;
    public activeItem = 'home';
    public active = false;
    public activeChildItem = '';
    public overlay = false;
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    constructor ( public navServices: NavService) { }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit () {
        this.navServices.items.pipe(takeUntil(this.ngUnsubscribe)).subscribe(menuItems => {
            this.menuItems = menuItems;
        });
    }

    toggleSidebar () {
        this.openSide = !this.openSide;
    }

    closeOverlay () {
        this.openSide = false;
    }

    // For Active Main menu in Mobile View
    setActive (menuItem) {
        if (this.activeItem === menuItem) {
            this.activeItem = '';
        } else {
            this.activeItem = menuItem;
        }
    }

    isActive (item) {
        return this.activeItem === item;
    }

    // For Active Child Menu in Mobile View
    setChildActive (subMenu) {
        if (this.activeChildItem === subMenu) {
            this.activeChildItem = '';
        } else {
            this.activeChildItem = subMenu;
        }
    }

    ischildActive (subMenu) {
        return this.activeChildItem === subMenu;
    }



}
