import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    ProductCategory,
    Manufacturer,
    ViewProduct,
    GenericResponse,
    ColourType,
    BlogPost,
    PostTags,
    ContactForm,
    CustomPage,
    NewCustomerForm,
    PaginateProducts,
    StockrefPrice,
    PaymentResponse } from '../_models/index';
import { API_URL } from '../app.const';

@Injectable()
export class PageService {
    private showCategoriesURL = API_URL + '/products/list_categories';
    private showManufacturersURL = API_URL + '/products/list_manufacturers';
    private listProductsURL = API_URL + '/products/list_by_category';
    private fetchProductURL = API_URL + '/products/fetchcone';
    private getColourTypesURL = API_URL + '/products/list_colour_types';
    private getBlogPostsURL = API_URL + '/blog/list_all_posts';
    private getOneBlogPostURL = API_URL + '/blog/list_one_post';
    private getXBlogPostsURL = API_URL + '/blog/list_x_posts';
    private getBlogTagsURL = API_URL + '/blog/list_all_tags';
    private getBlogPostByTagURL = API_URL + '/blog/get_posts_by_tag';
    private submitContactFormURL = API_URL + '/pages/new_contact_form';
    private getCustomPageURL = API_URL + '/pages/get_custom_page';
    private submitCustomerFormURL = API_URL + '/pages/new_customer_form';
    private getSearchResultsURL = API_URL + '/pages/search_results';
    private getFeatureProductsURL = API_URL + '/products/feature';
    private getSolentSProductsURL = API_URL + '/products/solent_selection';
    private priceQueryURL = API_URL + '/express/price_query';
    private getRelatedProductsURL = API_URL +  '/products/get_related';
    private submitRegisterFormURL = API_URL + '/pages/register_form';
    private getPaymentStatusURL = API_URL + '/pages/payment_status';


    constructor (private http: HttpClient) {}

    getCategories(admin) {
        return this.http.post<ProductCategory[]>(this.showCategoriesURL,
            JSON.stringify({ admin }));
    }

    getManufacturers(admin) {
        return this.http.post<Manufacturer[]>(this.showManufacturersURL,
            JSON.stringify({ admin }));
    }

    getProducts(categoryIDS: [], selectedPage: number) {
        return this.http.post<PaginateProducts>(this.listProductsURL,
            JSON.stringify({ categoryIDS, selectedPage }));
    }

    getSSProducts() {
        return this.http.get<ViewProduct[]>(this.getSolentSProductsURL);
    }

    getRelatedProducts(productSlug) {
        return this.http.post<ViewProduct[]>(this.getRelatedProductsURL,
            JSON.stringify({ productSlug }));
    }


    getOneProduct(productSlug) {
        return this.http.post<ViewProduct>(this.fetchProductURL,
            JSON.stringify({ productSlug }));
    }

    getFeatureProducts(amount) {
        return this.http.post<ViewProduct[]>(this.getFeatureProductsURL,
            JSON.stringify({ productQty: amount }));
    }
    

    getColourTypes(categoryID) {
        return this.http.post<ColourType[]>(this.getColourTypesURL,
            JSON.stringify({ categoryID }));
    }

    getAllBlogPosts() {
        return this.http.get<BlogPost[]>(this.getBlogPostsURL);
    }

    getOneBlogPost(postSlug) {
        return this.http.post<BlogPost>(this.getOneBlogPostURL,
            JSON.stringify({ postSlug }));
    }

    getXBlogPosts(amount: number) {
        return this.http.post<BlogPost[]>(this.getXBlogPostsURL,
            JSON.stringify({ amount }));
    }

    getBlogTags() {
        return this.http.get<PostTags[]>(this.getBlogTagsURL);
    }

    getBlogPostByTag(tagID) {
        return this.http.post<BlogPost[]>(this.getBlogPostByTagURL,
            JSON.stringify({ tagID }));
    }

    submitContactForm(contactForm: ContactForm) {
        return this.http.post<GenericResponse>(this.submitContactFormURL,
            JSON.stringify({ contactForm }));
    }

    submitRegisterForm(registerForm) {
        return this.http.post<GenericResponse>(this.submitRegisterFormURL,
            JSON.stringify({ registerForm }));
    }

    submitCustomerForm(customerForm: NewCustomerForm) {
        return this.http.post<GenericResponse>(this.submitCustomerFormURL,
            JSON.stringify({ customerForm }));
    }

    getCustomPage(slug) {
        return this.http.post<CustomPage>(this.getCustomPageURL,
            JSON.stringify({ slug }));
    }

    getSearchResults(searchString) {
        return this.http.post<ViewProduct[]>(this.getSearchResultsURL,
            JSON.stringify({ searchString }));
    }

    priceQuery(stockref: string, qty: number, terms: boolean, customerNo: string) {
        return this.http.post<StockrefPrice>(this.priceQueryURL,
            JSON.stringify({ stockref, qty, terms, customerNo }));
    }

    lookupPaymentStatus(paymentID) {
        return this.http.post<PaymentResponse>(this.getPaymentStatusURL,
            JSON.stringify({ paymentID }));
    }


}
