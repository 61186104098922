<div class="agency">
<app-header></app-header>
<!--section 404 start-->
<div class="bg-purple">
    <div class="section-404">
        <div class="container">
            <div class="row">
                <div class="col-md-8 offset-md-2 col-12">
                    <div class="central-body">
                        <h1 class="text-inner">404</h1>
                        <h3 class="sub-text text-white">page not found</h3>
                        <p class="text-404 text-white text-center">The Page You Are Attempting To Reach Is Currently Not Available. <br />
                         This May Be Because The Page Does Not Exist, Or Has Been Moved.</p>
                        <a [routerLink]="['/products/categories']" class="btn btn-default primary-btn transparent m-t-20">view products</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--section 404 end-->
<app-footer></app-footer>
</div>
