<!-- Nav Start-->
<header class="ecommerce">
  <div class="container">
      <div class="row">
          <div class="col">
              <nav>
                  <a class="logo-erapper" [routerLink]="['/admin']"><img alt="" class="img-fluid"
                                                        src="assets/images/solent/logo_header.png"></a>
                 <app-menu-admin></app-menu-admin>
              </nav>
          </div>
      </div>
  </div>
</header>
<!-- Nav end-->