<ng-template #addManModal let-c="close" let-d="dismiss">
        <div class="modal-header">
          <h4 class="modal-title">Add Manufacturer</h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div *ngIf="formError">
	            <div class="alert alert-danger" role="alert">
	                    <h1>{{formError}}</h1>
	            </div>
			</div> 
            <form class="form">
                    <div class="form-group" [formGroup]="manForm">
                    <label for="name">Name</label>
                    <div class="input-group">
                    <input class="form-control" placeholder="Manufacturer Name"
                        id="name"
                        type="text"
                        formControlName="name">
                    </div>

                    <app-single-image-upload formControlName="image" [progress]="progress"></app-single-image-upload>

                    <br />

                    <button class="btn btn-success" [disabled]="manForm?.invalid" 
                    (click)="saveNewManufacturer()">Add Manufacturer</button>
                    
                    </div>
            </form>

        <div class="modal-footer">
          <button type="button" class="btn btn-outline-dark" (click)="c('Close click')">Close</button>
        </div>
    </div>
</ng-template>

<ng-template #editManModal let-c="close" let-d="dismiss">
        <div class="modal-header">
          <h4 class="modal-title">Edit Manufacturer</h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div *ngIf="formError">
                <div class="alert alert-danger" role="alert">
                        <h1>{{formError}}</h1>
                </div>
            </div> 
            <form class="form">
                    <div class="form-group" [formGroup]="manForm">
                    <label for="name">Name</label>
                    <div class="input-group">
                    <input class="form-control" placeholder="Category Name"
                        id="name"
                        type="text"
                        formControlName="name">
                    </div>

                    <div *ngIf="manufacturerQ[0].awsURL !== null">
                        <br />
                        <button class="btn btn-danger"
                        (click)="removeImage(manufacturerQ[0].id)">Remove Current Image</button>
                    </div>

                    <div *ngIf="manufacturerQ[0].awsURL === null">
                        <!-- if no image exists, give option to add -->
                        <app-single-image-upload formControlName="image" [progress]="progress"></app-single-image-upload>
                        <button class="btn btn-danger"
                            (click)="updateManImage(manufacturerQ[0].id)">Update Image</button>
                    </div>

                    <br />

                    <button class="btn btn-success" (click)="updateManufacturer(manufacturerQ[0].id)">
                        Update Manufacturer
                    </button>
                        

                    
                    </div>
            </form>

        <div class="modal-footer">
          <button type="button" class="btn btn-outline-dark" (click)="c('Close click')">Close</button>
        </div>
    </div>
</ng-template>


<section class="event team-sec speaker set-relative" id="speaker">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-sm-12 speker-container">
                <div class="table-responsive">
    				<table class="table table-striped">
    				  <thead>
    				  <tr>
    				    <th scope="col"># (Edit)</th>
    				    <th scope="col">Name </th>
    				    <th scope="col">Showcase</th>
    				  </tr>
    				  </thead>
    				  <tbody>

    				  <tr *ngFor="let man of manufacturers; index as i">
    				    <th scope="row" (click)="editManufacturer(man.id)">{{ man.id }}</th>

    				    <td>{{ man.name}}</td>
    				    <td *ngIf="man.showcase === true;" (click)="markManShowcase(man.id)">Un-Showcase</td>
                        <td *ngIf="man.showcase === false;" (click)="markManShowcase(man.id)">Showcase</td>
    				  </tr>
    				  </tbody>
    				</table>

    				<button class="btn btn-danger" (click)="addManufacturer()">
                		Add Manufacturer
                	</button>

                    <br /><br />
                    <p>
                        Showcase displays the manufacturer logo in various front end templates.
                    </p>
                </div>

            </div>

          
        </div>
    </div>
</section>
