<!-- section start -->
<section class="agency blog blog-sec blog-sidebar blog-split sider">
    <div class="container">
        <div class="row pl-0 pr-0 table-responsive">
            <div class="col-lg-12">
                <div class="row">

                	<app-order-history></app-order-history>

                </div>
            </div>
        </div>
    </div>
</section>
<!-- section end -->