<section class="event team-sec speaker set-relative" id="speaker">
<div class="container-fluid" *ngIf="productTest === true">

    <div class="row">
        <div class="col-lg-6">
                <img *ngIf="product.mainImageAWSURL === null" 
                	src="http://localhost:4200/assets/images/inner-page/category/1.jpg" width="100%">

                <img *ngIf="product.mainImageAWSURL !== null" 
                	src="{{product.mainImageAWSURL}}" width="100%">
        </div>
        <div class="col-lg-6 rtl-text">
            <div class="product-right">
                <h2>{{product.name}}</h2>

                <div class="border-product">
                    <h6 class="product-title">Product Details</h6>
                    <p>{{product.briefDescription}}
                    </p>
                    <h6 class="product-title">Product WYSIWYG</h6>
                    <div [innerHTML]="product.wysiwyg">

                    </div>
                </div>
                <div class="border-product">
                    <h3 (click)="mainImage()">Manage Main Image </h3>
	                <h3 (click)="otherImages()">Manage Other Images </h3>
	                <h3 (click)="navDocuments()">Manage Documents </h3>
                    <h3 (click)="updateWysiwyg()">Update WYSIWYG editor</h3>
                    <h3 (click)="updateRelated()">Manage Related Products</h3>

                </div>
            </div>
        </div>
    </div>

</div>

<div class="row">
        
</div>
</section>