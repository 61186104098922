<section class="ecommerce-tab">
    <div class="container">

        <div class="isotopeContainer row">
            <div class="col-lg-3 col-sm-6 isotopeSelector">
                <div class="product-box">

                  <div class="center-content spacebelow">
                        <div class="front">
                         
                             <fa-icon  class="redicon" size="5x" [icon]="faHeart"></fa-icon>
                         
                        </div>

                      </div>

                   
                      <div class="product-detail">
                       
                          <h6>Truly Independent</h6>
                        
                        <p class="displayhome">We are one of the last truly independent flooring distributors left in the UK, family owned and set for the third generation to continue the great name of Solent Wholesale.</p>
                      </div>


                </div>
            </div>


            <div class="col-lg-3 col-sm-6 isotopeSelector branding new ">
                <div class="product-box">
                   

                     <div class="center-content spacebelow">
                        <div class="front">
                             <fa-icon  class="greenicon" size="5x" [icon]="faShoppingBasket"></fa-icon>
                        </div>
                      </div>
                      <div class="product-detail">
                       
                          <h6>Large Selection</h6>
                        
                        <p class="displayhome">With over 500,00 sqm worth of stock in our warehouse, we not only have a wide selection available, but also nearly everything is available next day.</p>
                      </div>


                </div>
            </div>


            <div class="col-lg-3 col-sm-6 isotopeSelector branding new ">
                <div class="product-box">
                   

                    <div class="center-content spacebelow">
                        <div class="front">
                             <fa-icon  class="blueicon" size="5x" [icon]="faUsers"></fa-icon>
                        </div>
                      </div>
                      <div class="product-detail">
                        
                          <h6>Why Use Us?</h6>
                        
                        <p class="displayhome">Great prices, friendly staff, friendly drivers, our carpets always come clean and wrapped and our warehouse is kept spotless to ensure your goods are perfect for installation!</p>
                      </div>


                </div>
            </div>

            <div class="col-lg-3 col-sm-6 isotopeSelector branding new ">
                <div class="product-box">
                   

                    <div class="center-content spacebelow">
                        <div class="front">
                             <fa-icon  class="yellowicon" size="5x" [icon]="faHtml5"></fa-icon>
                        </div>
                      </div>
                      <div class="product-detail">
                       
                          <h6>5m in stock</h6>
                        
                        <p class="displayhome">We carry a range of 5m wide in stock. Unlike other wholesalers, we have it available for next day delivery, or same day collection on a large number of ranges .</p>
                      </div>


                </div>
            </div>


        </div>
    </div>
</section>