<!-- section start -->
<section class="register-page section-b-space">
  <div class="container">
      <div class="row">
          <div class="col-lg-8">

              <div class="theme-card">
    
                     
                          <div class="col-md-12">
                            <h3>Payment Status</h3> <br /><br />

                                <div *ngIf="paymentObject">

                                
                                
                                    <img *ngIf="paymentObject.status === 1" src="https://swc-website.s3-eu-west-1.amazonaws.com/static_images/approved.png">

                                    <img *ngIf="paymentObject.status === 0" src="https://swc-website.s3-eu-west-1.amazonaws.com/static_images/declined.png">

                                    <img *ngIf="paymentObject.status === 2" src="https://swc-website.s3-eu-west-1.amazonaws.com/static_images/pending.png">
                                        
                                    <div class="table-responsive">
                                        <table class="table table-striped">
                                          <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Value</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                          <tr>
                                            <td> From </td>
                                            <td> {{paymentObject.user}} </td>
                                          </tr> 
                                          <tr>
                                            <td> Amount </td>
                                            <td> {{paymentObject.amount | currency:'GBP'}} </td>
                                          </tr> 
                                          <tr>
                                            <td> Reference </td>
                                            <td> {{paymentObject.reference}}  </td>
                                          </tr> 
                                          <tr>
                                            <td> Status </td>
                                            <td> {{paymentObject.statusString}} </td>
                                          </tr> 
                                          </tbody>
                                        </table>
                                    </div>
                                    
                                </div>

                              <h1 *ngIf="paymentMessage">{{paymentMessage}}</h1>

                              <br />
                          </div>
      
             
                     


              </div>
          </div>

          <div class="col-lg-4 hiddenSMALL">
            <div class="theme-card">
            <h3>Looking for Solent Express?</h3>
              <p class="highlightURL">

             Solent Express is a national service we offer on <a href="">selected products</a>, available nationwide
            with next day delivery. Exclusive to the flooring trade, <a [routerLink]="['/page/new-customer']">open an account</a> today</p> <br />

            </div>

            <div class="theme-card">
            <h3>Want us to save your details?</h3>
              <p class="highlightURL">

             We have the facility to securely store your card details (if you want us to), useful for if you make regular payments. We don't
             have access to them, the bank stores a token that allows us to re-take future payments with your consent. If you are interested
             in this, or have any questions, please <a [routerLink]="['/page/contact']">contact us</a> today</p> <br />

            </div>

          </div>


      </div>
  </div>
</section>