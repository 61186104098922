<section class="event team-sec speaker set-relative" id="speaker">
	<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-sm-12 speker-container">

                <form>
				  <div class="form-group form-inline">
				      Search for a product:  <input class="form-control ml-2" type="text" [formControl]="filter"/>
				  </div>
				  <p> Click on the product name to edit it</p>
				  <br />
				</form>

				<div class="table-responsive">
				<table class="table table-striped">
				  <thead>
				  <tr>
				    <th scope="col">#</th>
				    <th scope="col">Related</th>
				    <th scope="col">Product</th>
				    <th scope="col">Category</th>
				    <th scope="col">Manufacturer</th>
				  </tr>
				  </thead>
				  	<tr><th scope="col">Related Products</th></tr>
				  	<tbody *ngFor="let product of products$ | async; index as i">
				  	 <tr *ngIf="relatedIDS.indexOf(product.id) > -1">
					    <th scope="row"><span>{{ product.id }}</span></th>

					    <td><fa-icon (click)="removeRelated(product.id)" 
                            class="fa-lg redFA" size="lg" [icon]="faTimes"></fa-icon>
					    </td>
					    <td>
					      
					      <ngb-highlight [result]="product.name" [term]="filter.value"></ngb-highlight>
					    </td>
					    <td><ngb-highlight [result]="product.categoryName" [term]="filter.value"></ngb-highlight></td>
					    <td><ngb-highlight [result]="product.manufacturerName" [term]="filter.value"></ngb-highlight></td>

				  </tr>
				  </tbody>

				  <tr><th scope="col">Un-Related Products</th></tr>
				  	<tbody *ngFor="let product of products$ | async; index as i">
				  	 <tr *ngIf="relatedIDS.indexOf(product.id) < 0">
					    <th scope="row"><span>{{ product.id }}</span></th>

					    <td><fa-icon (click)="addRelated(product.id)" 
                            class="fa-lg greenFA" size="lg" [icon]="faCheck"></fa-icon>
					    </td>
					    <td>
					      
					      <ngb-highlight [result]="product.name" [term]="filter.value"></ngb-highlight>
					    </td>
					    <td><ngb-highlight [result]="product.categoryName" [term]="filter.value"></ngb-highlight></td>
					    <td><ngb-highlight [result]="product.manufacturerName" [term]="filter.value"></ngb-highlight></td>

				  </tr>
				  </tbody>

					
				</table>
				</div>
            </div>

          
        </div>
    </div>
</section>
