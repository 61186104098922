<!-- Nav Start-->
<header class="ecommerce">
  <div class="container">
    <div class="row">
      <div class="col-sm-4 center-content">
          <a [routerLink]="['/']">
            <img alt="" class="navlogo" src="assets/images/solent/web1.png"></a>
        </div>

        <div class="col-sm-4 center-content">
          <div *ngIf="expressCustomer === true;" class="account center-text ">
            <div class="iconpadding center-content accountIcon">
              <a [routerLink]="['/express/my-account']">
                <fa-icon class="fa-lg blueFA iconAccount" size="2x" [icon]="faUserCircle"></fa-icon>
              </a>
              My Account
            </div>
              <div class="fa-stack has-badge iconpadding center-content" [attr.data-count]="cartQty">
                <a [routerLink]="['/express/basket']">
                  <fa-icon class="fa-lg blueFA" size="2x" [icon]="faShoppingCart"></fa-icon>
                </a>
                 Basket
              </div>
          </div>

          <div *ngIf="expressCustomer === false;" class="account center-text">
            <div class="iconpadding center-content">
              <button [routerLink]="['/express/login']"
                   class="btn btn-default primary-btn info btn-small exphome">Express Login</button>
            </div>
              
          </div>

        </div>
        <div class="col-sm-4">
          <div class="headersearch">
              <form class="form">
                  <div [formGroup]="searchForm">
                  <div class="input-group">
                  <input type="searchString" class="form-control" id="saerchInput" 
                         formControlName="searchString"
                         aria-describedby="searchInput" placeholder="Search..." 
                         (keyup.enter)="onEnter()">
                    <div class="input-group-append">
                      <span (click)="onEnter()" class="input-group-text">GO!</span>
                  </div>
                </div>
                  </div>
              </form>
          </div>
        </div>
    </div>
      <div class="row">
          <div class="col">
              <nav class="navbar-expand-sm">
                  
                 <app-menu></app-menu>
                 
                  <div class="top-header-right">
                      
                          
                          <!--
                          <li class="account ">
                              <a aria-expanded="false" data-toggle="dropdown" href="javascript:void()" id="dropdownMenuButton2">
                                  <i class="icon-user"></i>
                              </a>
                              <div aria-labelledby="dropdownMenuButton2" class="dropdown-menu dropdown-menu-right">
                                  <a class="dropdown-item" href="javascript:void()">Login</a>
                                  <a class="dropdown-item" href="javascript:void()">Account</a>
                                  <a class="dropdown-item" href="javascript:void()">Whishlist</a>
                                  <a class="dropdown-item" href="javascript:void()">Checkout</a>
                              </div>
                          </li>
                        -->
                      
                  </div>
              </nav>
          </div>
      </div>
  </div>
</header>
<!-- Nav end-->