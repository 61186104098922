import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Third party imports
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ShareModule } from 'ngx-sharebuttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { LightboxModule } from 'ngx-lightbox';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxLoadingModule } from 'ngx-loading';
// Local imports
import { WindowRef } from './../_services/windowref';
import { DigitOnlyDirective } from './digit-only.directive';
import { SharedModule } from '../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutsModule } from '../layouts/layouts.module';
import { ProductRoutingModule } from './product-routing.module';
import { ProductComponent } from './product.component';
import { SafePipe } from './transform.component';
import { ProductResultsComponent } from './product-results/product-results.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { ProductCategoriesComponent } from './product-categories/product-categories.component';

@NgModule({
    declarations: [
        ProductComponent,
        ProductResultsComponent,
        ProductDetailComponent,
        ProductCategoriesComponent,
        SafePipe,
        DigitOnlyDirective
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ProductRoutingModule,
        SharedModule,
        LayoutsModule,
        NgbModule,
        CarouselModule,
        FontAwesomeModule,
        ShareButtonsModule.withConfig({
            debug: true
        }),
        ShareModule,
        ShareIconsModule,
        LightboxModule,
        NgxLoadingModule.forRoot({})
    ],
    providers: [WindowRef]
})
export class ProductModule { }
