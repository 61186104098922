<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

<h3>Recent Orders</h3>

<div class="container">
    <div class="row">
    	<div *ngIf="staffMember === true">

    		<div class="form-group"  [formGroup]="searchForm">

                      <div class="form-row">
                          <div class="col-md-12">
                              <label for="email">Account Number</label>
                              <input type="text" class="form-control" id="fname"
                                      formControlName="accountNumber"
                                      placeholder="Account Number">
                          </div>
                      </div>
            </div>



    	</div>
			<div class="table-responsive">
				<table class="table table-striped fullwidth">
				  <thead>
				  <tr>
				    <th scope="col">ID</th>
				    <th scope="col">Paid?</th>
				    <th scope="col">Your Ref</th>
				    <th scope="col">Products</th>
				    <th scope="col">Our Ref</th>
				    <th scope="col">Placed</th>
				    <th scope="col">Order Tracking</th>
				    <th scope="col">Total Price</th>

				  </tr>
				  </thead>
				  <tbody>
				  <tr *ngFor="let order of orders">
				    <th scope="row"><span>{{ order.id }}</span></th>
				    <td>
				    	<fa-icon *ngIf="order.paid === false;" class="fa-lg redFA" size="lg" [icon]="faTimes"></fa-icon>
				    	<fa-icon *ngIf="order.paid === true;" class="fa-lg greenFA" size="lg" [icon]="faCheck"></fa-icon>
				    	<br />
				    	<span *ngIf="order.paid === true;">{{order.paymentMessage}}</span>
				    	<button *ngIf="order.paid === false;" (click)="buildHostedPayment(order)"
				    		class="btn btn-default primary-btn smallRadius">PAY NOW</button>
				    </td>
				    <td>{{order.custRef}}</td>
				    <td> <span *ngFor="let line of order.products">
				    	{{line.colourName}} <br />
				    	Quantity: {{line.qtyLength}}
				    	</span>
				    </td>
				    <td>{{order.realitexOrderNo}}</td>
				    <td>{{order.orderDate}}</td>
				    <td> <span *ngFor="let consignment of order.consignments">
				    	<a href=""> {{consignment.carrierName}}</a> <br />
				    	Status: {{consignment.status}}
				    	</span>
				    </td>
				    <td>{{order.fullTotal}}</td>
				  </tr>
				  </tbody>
				</table>

				
			</div>

		<div>
		</div>
	</div>
</div>
