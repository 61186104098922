<ng-template #addPageModal let-c="close" let-d="dismiss">
        <div class="modal-header">
          <h4 class="modal-title">Add Custom Page</h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div *ngIf="formError">
	            <div class="alert alert-danger" role="alert">
	                    <h1>{{formError}}</h1>
	            </div>
			</div> 
            <form class="form">
                    <div class="form-group" [formGroup]="pageForm">
                    <label for="name">Name</label>
                    <div class="input-group">
                    <input class="form-control" placeholder="Name"
                        id="name"
                        type="text"
                        formControlName="name">
                    </div>
                    <p *ngIf="pageForm.get(['name']).hasError('maxlength')" 
                                    style="color:red;">You are limited to 50 characters</p>
                    <p *ngIf="pageForm.get(['name']).hasError('required')" 
		                style="color:red;">You must add a value</p>
		            <br />

		            <div *ngIf="browserTest === true" class="input-group">
		            	<ckeditor [editor]="Editor" formControlName="content"></ckeditor>

		            </div>



                    <button class="btn btn-success" [disabled]="pageForm?.invalid" 
                    (click)="savePage()">Save</button>

                    
                    </div>
            </form>

        <div class="modal-footer">
          <button type="button" class="btn btn-outline-dark" (click)="c('Close click')">Close</button>
        </div>
    </div>
</ng-template>


<section class="event team-sec speaker set-relative" id="speaker">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-sm-12 speker-container">
                <div class="table-responsive">
    				<table class="table table-striped">
    				  <thead>
    				  <tr>
    				    <th scope="col"># (Edit)</th>
    				    <th scope="col">Name</th>
    				    <th scope="col">Slug</th>
    				    <th scope="col">Published</th>
    				  </tr>
    				  </thead>
    				  <tbody>

    				  <tr *ngFor="let page of pages; index as i">
    				    <th scope="row" (click)="editPage(page.slug)">{{ page.id }}</th>

    				    <td>{{ page.name}}</td>
    				    <td>{{ page.slug}}</td>

    				    <td *ngIf="page.published === true;">
    				    	<fa-icon (click)="publishPage(page.id)" 
			            		class="fa-lg greenFA" size="lg" [icon]="faCheck"></fa-icon>    				    	
    				    </td>
                        <td *ngIf="page.published === false;">
                        	<fa-icon (click)="publishPage(page.id)" 
			            		class="fa-lg redFA" size="lg" [icon]="faTimes"></fa-icon>    
                        </td>
 
    				  </tr>
    				  </tbody>
    				</table>

    				<button class="btn btn-danger" (click)="addPage()">
                		Add new
                	</button>

                    <br /><br />

                </div>

            </div>

          
        </div>
    </div>
</section>
