<!-- section start -->
<section class="agency blog-sec blog-sidebar single_blog_item">
    <div class="container">
        <div *ngIf="blogPost" class="row">
            <div class="col-lg-9">
                <div class="blog-block m-b-20">
                    <div class="blog-box">
                        <div class="overflow-hidden">
                            <img class="img-fluid blur-up lazyload fixedheight" src="{{blogPost.awsURL}}" alt="blog">
                        </div>
                    </div>
                </div>
                <div class="blog-text">
                    <h6>{{blogPost.pubDate}}</h6><a href="javascript:void()">
                        <h3 class="blog-head">{{blogPost.title}}</h3>
                    </a>
                    <div class="blog-divider"></div>
                    <div class="blog-description">
                        <p [innerHTML]="blogPost.content"></p>
                        
                        <h5>{{blogPost.authorName}}</h5>
                    </div>
                </div>
                <!-- Comment Sec Start
                <h3 class="page-header-comment">{{blogData.section}}</h3>
                <div class="comment-list blog_comment">
                    <div class="comment_sec" *ngFor="let data of blogData.commentSection">
                        <article class="row">
                            <div class="" [ngClass]="data.Id==2?'col-11 offset-1':'col-12'">
                                <div class="blog_center" [ngClass]="data.Id==2?re_comment:''">
                                    <div>
                                        <figure class="thumbnail">
                                            <img [src]="data.commentImg" alt="">
                                        </figure>
                                    </div>
                                    <div class="blog_user arrow left">
                                        <div class="panel-body">
                                            <div class="text-left">
                                                <div class="comment-user"><i class="fa fa-user"></i>
                                                    <h6>{{data.commentName}}</h6>
                                                </div>
                                                <div class="comment-date"><i class="fa fa-clock-o"></i>
                                                    <h6>{{data.commentDate}}</h6>
                                                </div>
                                            </div>
                                            <div class="comment-post">
                                                <p>
                                                    {{data.commentDescription}}
                                                </p>
                                            </div>
                                            <a href="javascript:void()" class="link-btn"><i class="fa fa-reply"></i>
                                                {{data.commentReplayTag}}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                     
                    </div>
                </div>
                -->
  

            </div>
            <div class="col-lg-3">
                <app-sidebar-blog></app-sidebar-blog>
            </div>
        </div>
    </div>
</section>
<!-- section end -->