import {map} from 'rxjs/operators';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL } from '../app.const';
import { SolentExpressService } from '../_services/solentExpress.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class AuthenticationService {
    private loginUrl = API_URL + '/user/login';
    private tokenCheckURL = API_URL + '/express/token_test';
    private jwtHelper: JwtHelperService = new JwtHelperService();
    constructor(
        private httpClient: HttpClient,
        private expressService: SolentExpressService,
        @Inject(PLATFORM_ID) private platformId: any) { }

    login(username: string, password: string) {
        return this.httpClient.post(
            this.loginUrl,
            JSON.stringify({ username, password })).pipe(
            map((user: any) => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    /*  store user details and jwt token in local storage to keep user
                        logged in between page refreshes
                    */
                    if (isPlatformBrowser(this.platformId)) {
                        localStorage.setItem('cmsToken', JSON.stringify(user));
                        localStorage.setItem('rawJWT', user.token);
                        // Now check for saved baskets....
                        const tokenDecode = this.jwtHelper.decodeToken(user.token);
                        this.expressService.fetchBasket(tokenDecode.customerNo)
                            .subscribe(x => this.expressService.updateBasket(x));
                    }
                }
            }));
    }

    logout() {
        // remove user from local storage to log user out
        if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem('cmsToken');
            localStorage.removeItem('rawJWT');
        }
    }

    tokenTest () {
        return this.httpClient.get<boolean>(this.tokenCheckURL);
    }

    localTokenTest () {
        if (isPlatformBrowser(this.platformId)) {
            if (localStorage.getItem('cmsToken')) {
                // logged in so we can proceed to test that ecomUserToken JWT token
                // has OrderEntry permission
                const token = localStorage.getItem('cmsToken');
                const tokenDecode = this.jwtHelper.decodeToken(token);

                if (tokenDecode.roles.includes('express')) {
                    return true;
                }
                if (tokenDecode.roles.includes('solent_staff')) {
                    return true;
                }
                if (tokenDecode.roles.includes('family')) {
                    return true;
                }
                return false;
            }
        }

        return false;
    }

    returnCustomerNo () {
        if (isPlatformBrowser(this.platformId)) {
            if (localStorage.getItem('cmsToken')) {
                // logged in so we can proceed to test that ecomUserToken JWT token
                // has OrderEntry permission
                const token = localStorage.getItem('cmsToken');
                const tokenDecode = this.jwtHelper.decodeToken(token);
                return tokenDecode.customerNo;
            }
        }

        return null;
    }

    returnUserName () {
        if (isPlatformBrowser(this.platformId)) {
            if (localStorage.getItem('cmsToken')) {
                // logged in so we can proceed to test that ecomUserToken JWT token
                // has OrderEntry permission
                const token = localStorage.getItem('cmsToken');
                const tokenDecode = this.jwtHelper.decodeToken(token);
                return tokenDecode.userName;
            }
        }

        return null;
    }

    returnUserID () {
        if (isPlatformBrowser(this.platformId)) {
            if (localStorage.getItem('cmsToken')) {
                // logged in so we can proceed to test that ecomUserToken JWT token
                // has OrderEntry permission
                const token = localStorage.getItem('cmsToken');
                const tokenDecode = this.jwtHelper.decodeToken(token);
                return tokenDecode.user_id;
            }
        }

        return null;
    }

    staffTest () {
        if (isPlatformBrowser(this.platformId)) {
            if (localStorage.getItem('cmsToken')) {
                // logged in so we can proceed to test that ecomUserToken JWT token
                // has OrderEntry permission
                const token = localStorage.getItem('cmsToken');
                const tokenDecode = this.jwtHelper.decodeToken(token);
                if (tokenDecode.roles.includes('cpanel')) {
                    return true;
                } else {
                    // set price to read only if only a regular user
                    return false;
                }
            }
        }
        return false;
    }
}
