import { Action, createReducer, on } from '@ngrx/store';
import * as ExpressActions from './actions';
import { CartItem, CustomerAddress, CartTotal} from '../_models/index';

export interface State {
    items?: CartItem[];
    lines: number;
    deliveryAddress?: CustomerAddress;
    totals?: CartTotal;
}

/*
export const initialState: State = {
    items: [
        {
            lineno: 1,
            stockref: 'LG-SOFTCHALKOAK',
            qtyLength: 10,
            qtyWidth: 1,
            unitPrice: 23.37,
            productID: 7,
            productName: 'Lagoon Glue Down',
            productSlug: 'lagoon-glue-down',
            packSize: 2.6,
            palletLength: 1.20,
            colourName: 'Lagoon DB Soft Chalk Oak',
            colourID: 163,
            smallItem: false,
            colourAWSURL: 'https://s3-eu-west-1.amazonaws.com/swc-website-products/7-Lagoon Glue Down/other_images/Lagoon Glue Down4d7011a0-fffa-4be0-87e6-dbb93c19d55a.jpg'
        }
    ],
    lines: 1,
    deliveryAddress: {
        orderdelivL1: 'Solent Wholesale',
        orderdelivL2: 'Zola Park',
        orderdelivL3: 'Barnfield Drive',
        orderdelivL4: 'Chichester W Sussex',
        orderdelivL5: 'PO19 6UX',
        orderdelivL6: 'Blaa',
        siteContactNo: '07966664379'
    },
    totals: {
        totalExVat: 0,
        delivery: 0,
        vat: 0,
        total: 0,
        premium: false,
        deliveryCheck: false,
        consignments: [],
    },
};
*/
export const initialState: State = {
    items: [],
    lines: 0,
    deliveryAddress: {
        orderdelivL1: null,
        orderdelivL2: null,
        orderdelivL3: null,
        orderdelivL4: null,
        orderdelivL5: null,
        orderdelivL6: null,
        siteContactNo: null,
    },
    totals: {
        totalExVat: 0,
        delivery: 0,
        vat: 0,
        total: 0,
        premium: false,
        deliveryCheck: false,
        consignments: [],
    },
};


function addCartItem(original, payload) {
    // perform a deep clone
    const currentState = JSON.parse(JSON.stringify(original));
    /* first we need to test if the stockref already exists
       in state, if it does, update it.
    */
    currentState.push(payload);
    /*
    const index = currentState.findIndex(item => item.stockref === payload.stockref);
    if (index === -1) {
        // Stockref does not exist in state
        currentState.push(payload);
    } else {
        // stockref does exist in state
        const currentItem = currentState.filter(x => x.stockref === payload.stockref);
        const existingQty = currentItem[0].qtyLength;
        payload.qtyLength = Number(payload.qtyLength) + Number(existingQty);
        // remove old item
        currentState.splice(index, 1);
        // Ad new item
        currentState.push(payload);

    }
    */
    // new state is current basket, plus new item
    return currentState;
}

function updateCartItem(original, payload) {
    // perform a deep clone
    const currentState = JSON.parse(JSON.stringify(original));
    /* first we need to test if the stockref already exists
       in state, if it does, update it.
    */
    const index = currentState.findIndex(item => item.stockref === payload.stockref);
    if (index !== -1) {
        // stockref does exist in state
        // remove old item
        currentState.splice(index, 1);
        // Ad new item
        currentState.push(payload);

    }
    // new state is current basket, plus new item
    return currentState;
}


function removeCartItem(original, payload) {
    // perform a deep clone
    const currentState = JSON.parse(JSON.stringify(original));
    const index = currentState.findIndex(item => item.stockref === payload.stockref);
    currentState.splice(index, 1);
    // new state is current basket, plus new item
    return currentState;
}

function setInitialState() {
    return initialState;
}

const expressReducer = createReducer(
    setInitialState(),
    on(ExpressActions.addToCart, (state, { cartItem }) => ({
        items: addCartItem(state.items, cartItem),
        lines: Number(state.lines)  + 1,
        deliveryAddress: state.deliveryAddress,
        totals: state.totals,
    })),
    on(ExpressActions.removeFromCart, (state, { cartItem }) => ({
        items: removeCartItem(state.items, cartItem),
        lines: state.lines,
        deliveryAddress: state.deliveryAddress,
        totals: state.totals,
    })),
    on(ExpressActions.updateCart, (state, { cartItem }) => ({
        items: updateCartItem(state.items, cartItem),
        lines: state.lines,
        deliveryAddress: state.deliveryAddress,
        totals: state.totals,
    })),
    on(ExpressActions.setCartLines, (state, { newLines }) => ({
        items: state.items,
        lines: newLines,
        deliveryAddress: state.deliveryAddress,
        totals: state.totals,
    })),
    on(ExpressActions.updateSavedCart, (state, { cart }) => ({
        items: cart,
        lines: state.lines,
        deliveryAddress: state.deliveryAddress,
        totals: state.totals,
    })),
    on(ExpressActions.updateCartTotals, (state, { cartTotal }) => ({
        items: state.items,
        lines: state.lines,
        deliveryAddress: state.deliveryAddress,
        totals: cartTotal,
    })),

    on(ExpressActions.updateAddress, (state, { customerAddress }) => ({
        items: state.items,
        lines: state.lines,
        deliveryAddress: customerAddress,
        totals: state.totals,
    })),
);


export function reducer(state: State | undefined, action: Action) {
    return expressReducer(state, action);
}
