<div class="agency">
<app-header></app-header>
<app-ecommerce-banner-slider></app-ecommerce-banner-slider>

<app-ecommerce-product-tab></app-ecommerce-product-tab>
<app-product-categories-slider></app-product-categories-slider>

<app-ecommerce-parallax-banner></app-ecommerce-parallax-banner>
<app-ecommerce-links-tab></app-ecommerce-links-tab>
<app-ecommerce-recent-story></app-ecommerce-recent-story>
<app-footer></app-footer>
</div>