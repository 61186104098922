<section class="parallax-banner">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 p-0">
                <div class="full-banner" [ngStyle]="{'background-image': 'url(https://swc-website.s3-eu-west-1.amazonaws.com/static_images/home_more_than.png)'}">
                </div>
            </div>
            <div class="container">
                <div class="banner-contain">
                    <div>
                        <h2>More than just carpets</h2>
                        <p class="whitetext"> We also stock a number of sheet vinyl ranges, as well as LVT. In addition to floor coverings, we have every flooring
                            accessory you may need for the job, including (but not limited to): Underlay, adhesive,
                            screed, tools, blades, fixings and clothing. Solent is your one stop shop for your flooring business</p>
                        <a [routerLink]="['/products/categories']" class="btn btn-default primary-btn  radius-0 mt-3" >
                            All product categories</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>