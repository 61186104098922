import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { PageService } from '../../_services/index';
import { takeUntil } from 'rxjs/operators';
import { Subject} from 'rxjs';
import { faMapMarker, faEnvelope, faPhone, faCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-ecom-register',
    templateUrl: './ecom-register.component.html',
    styleUrls: ['./ecom-register.component.scss']
})

export class EcomRegisterComponent implements OnInit, OnDestroy {
    formError;
    faMarker = faMapMarker;
    faEnvelope = faEnvelope;
    faPhone = faPhone;
    faCircle = faCircle;
    contactForm = new UntypedFormGroup({
        tname: new UntypedFormControl(null, [Validators.required, Validators.maxLength(200)]),
        email: new UntypedFormControl(null, [Validators.required]),
        accNumber: new UntypedFormControl(null, [Validators.required, Validators.maxLength(100)]),
    });
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    constructor(
        private title: Title,
        private pageService: PageService,
        private router: Router) { }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
    
    ngOnInit(): void {
        this.title.setTitle('Solent Wholesale | Portal Registration');
    }

    evalAPIResponse (response) {
        if (response.status === true) {
            this.contactForm.reset();
            this.router.navigate(['/']);
        } else {
            this.formError = response.message;
        }

    }

    submitContact() {

        const newMessage = {
            tname: this.contactForm.get('tname').value,
            email: this.contactForm.get('email').value,
            accNumber: this.contactForm.get('accNumber').value,
        };

        this.pageService.submitRegisterForm(newMessage).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(response => this.evalAPIResponse(response));
    }

}
