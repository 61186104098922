<!-- about section Start-->
    
    <section class="gym format">
        <div class="container">
    
            <div class="row">
                <div class="col-md-8 col-sm-12 offset-md-2">
                
                </div>
            </div>
            <div class="row">
                <div class="col-md-8 offset-md-2">
                    <div class="center-text">
                        <div class="text-center">
                            <div class="format-head-text">
                                <h3 class="about-font-header gradient-text">Solent Selection</h3>
                            </div>           
    
                            <div class="format-sub-text mb-0">
    
                                <p class="p-light about-para">
                                    In 2023 we launched our own brand "Solent Selection". These ranges represent amazing value for money, where we
                                    have our own ranges commissioned (made for us) in a selection of colours and widths to form a unique selection. 
                                    Carpet ranges in our Solent Selection offer unmatched value for money and quality at their price points that cannot be beaten.
                                    <br /><br />
                                     We now offer 14 carpet ranges under our own branding, across multiple widths and backings this offers over 130 unique colour 
                                    options, if you include multiple widths and backings and pile weight this offering expands to over 200 unique options, truly 
                                    something for all customers.
                                    <br /><br  />
                                    Another advantage to the "Solent Selection" is that we hold very deep stocks of these carpets, we buy in bulk to secure the best 
                                    pricing, which means we can offer the keenest of prices to our customers and due to their popularity we never want to disappoint 
                                    on stock levels, so we also maintain these high stocks to ensure ultimate satisfaction with these ranges.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- about section end-->
    
    <section class="event testimonial set-relative bg about-page">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="title title2 title-inner">
                        <div class="main-title">
                            <h2 class="font-primary borders text-center text-uppercase m-b-0">
                                <span>Solent Selection Ranges</span>
                            </h2>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
    
    <section class="event speaker set-relative" id="speaker">

        <div class="container-fluid">
            <div class="row">
              <div [class.col-xl-3]="true" 
                   [class.col-md-6]="true" 
                   class="col-grid-box" 
                   *ngFor="let product of products">
                <div class="product-box">
                  
                    <div class="img-wrapper">
                        <div class="front">
                          <a [routerLink]="['/products/view', product.slug]">
                            <img alt="" class="img-fluid img-product-results" 
                                 routerLinkActive="active" [src]="imageCheck(product.mainImageAWSURL)">
                          </a>
                        </div>

                      </div>
                      <div class="product-detail">
                        <a [routerLink]="['/products/view', product.slug]" class="notext">
                          <h6 class="product-title">{{product.name}}</h6>
                        </a>
                        <span *ngIf="product.clearance === true;" class="discon"> * Limited Stock * <br /></span>
                        <span>                                                                         {{product.briefDescription}} </span> <br />
                        
                      </div>


                </div>


              </div>

            </div>
          </div>

    </section>
    
    
    
    
    
    