 <div class="blog-side">
        <div *ngIf="deliveryAddress">
            <h5 class="blog-title">Default Delivery Address</h5>
            {{deliveryAddress.orderdelivL1}}<br />
            {{deliveryAddress.orderdelivL2}}<br />
            {{deliveryAddress.orderdelivL3}}<br />
            {{deliveryAddress.orderdelivL4}}<br />
            {{deliveryAddress.orderdelivL5}}<br />
            {{deliveryAddress.orderdelivL6}}<br /><br />

        </div>
        <div *ngIf="invoiceAddress">
            <h5 class="blog-title">Default Invoice Address</h5>
            {{invoiceAddress.orderdelivL1}}<br />
            {{invoiceAddress.orderdelivL2}}<br />
            {{invoiceAddress.orderdelivL3}}<br />
            {{invoiceAddress.orderdelivL4}}<br />
            {{invoiceAddress.orderdelivL5}}<br />
            {{invoiceAddress.orderdelivL6}}<br /><br />
        </div>

        <div *ngIf="invoiceAddress">
            <h5 class="blog-title">Other Contact Details</h5>
            Mobile: {{invoiceAddress.siteContactNo}} <br/>
            Email: {{invoiceAddress.customerEmail}} <br/>
        </div>
</div>